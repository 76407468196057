import { withCheckAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import { Form } from 'ui/FormContainer/FormContainer';

import Button from '../../ui/Button/Button';
import { makeCreateFormFunc } from 'utils/api';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import type { SetPasswordProps } from '../../api/users/users.types';
import FormField from 'ui/FormField/FormField';
import { setPassword } from 'api/users/users';

const handleFormSubmit = makeCreateFormFunc(setPassword, 'Пароль изменен!');

const Field = FormField<SetPasswordProps>;

const SetPasswordForm = () => {
    return (
        <>
            <Headline>Сменить пароль</Headline>
            <Form onSubmit={handleFormSubmit}>
                {({ submitting }) => (
                    <>
                        <Field
                            name="oldPassword"
                            type="password"
                            label="Старый пароль"
                            required
                            togglePasswordVisibility={true}
                        />
                        <Field
                            name="password"
                            type="password"
                            label="Новый пароль"
                            placeholder="Новый пароль"
                            required
                            min="0"
                            togglePasswordVisibility={true}
                        />
                        <Field
                            name="confirmPassword"
                            type="password"
                            label="Повторите новый пароль"
                            placeholder="Повторите новый пароль"
                            required
                            min="0"
                            togglePasswordVisibility={true}
                        />
                        <ButtonsContainer>
                            <Button type="submit" disabled={submitting}>
                                Создать
                            </Button>
                        </ButtonsContainer>
                    </>
                )}
            </Form>
        </>
    );
};

export default withCheckAuth(withContainer(SetPasswordForm));
