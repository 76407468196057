import { withAuth } from '../../context/Auth';
import * as S from '../profile/Profile.styled';
import Button from 'ui/Button/Button';
import Avatar from 'ui/Avatar/Avatar';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {
    getAssignCourse,
    getMoodle,
    getStudentById
} from 'api/students/students';
import withContainer from 'components/Container/withContainer';
import { AssignedCourse, MoodleData } from 'api/students/students.types';
import { formatDate } from 'utils/date';
import Skeleton from 'components/Skeleton/Skeleton';
import { MainDataCoreProps } from '../profile/Profile.types';
import { MainDataRow, HeaderDataRow } from '../profile/DataRow';
import { TextL, GrayTextM } from 'ui/Styled/Styled';
import Tabs from 'ui/Tabs/Tabs';
import { ColumnProps, Table, TableProps } from 'ui/Table';
import { RouterPaths } from 'helpers/router-paths';
import { useId } from 'hooks/useId';
import { STUDENT_EDIT_PROPS } from '../profile/constants';
import { StudentModel } from 'api/api';

const COURSES_COLUMNS: ColumnProps<AssignedCourse> = {
    'course.title': 'Название программы',
    dateStartLearning: 'Начало обучения',
    dateEndLearning: 'Окончание обучения'
};

const parseColumns: TableProps<AssignedCourse>['parseColumns'] = {
    dateEndLearning: formatDate
};

const ProfileStudent = () => {
    const id = useId();
    const [student, setStudent] = useState<StudentModel>();
    const [assignedCourses, setAssignedCourses] = useState<AssignedCourse[]>();
    const [dataMoodle, setMoodle] = useState<MoodleData>();

    useEffect(() => {
        getStudentById(id).then(setStudent);
        getMoodle(id).then(setMoodle);
        getAssignCourse(id).then(setAssignedCourses);
    }, [id]);

    const { user } = student || {};

    const fullName = user
        ? `${user.surname} ${user.name} ${user.lastname}`
        : 'Без имени';

    const DocumentDataRow = useCallback(
        (props: MainDataCoreProps) => (
            <MainDataRow
                {...props}
                icon="doc"
                downloadFileTitle={`${props.label}_${fullName}`}
            />
        ),
        [fullName]
    );

    if (!student || !user) {
        return <Skeleton />;
    }

    const course_data = assignedCourses?.[0];

    return (
        <>
            <S.ContainerTopContent>
                <S.ContainerLeftContent>
                    {student.avatar?.url && (
                        <Avatar size={'m'} url={student.avatar.url} />
                    )}
                    <S.ContainerMainInformation>
                        <TextL>{fullName}</TextL>
                        <GrayTextM>
                            Дата рождения: {formatDate(student.birthday)}
                        </GrayTextM>

                        <HeaderDataRow
                            label="Организация"
                            value={student.organization?.title ?? 'Не указана'}
                        />
                        <HeaderDataRow
                            label="Обучается на курсе"
                            value={course_data?.course?.title ?? 'Не назначен'}
                        />

                        <HeaderDataRow
                            label="Логин Moodle"
                            value={dataMoodle?.login ?? 'Не указан'}
                        />
                        <HeaderDataRow
                            label="Пароль Moodle"
                            value={dataMoodle?.password ?? 'Не указан'}
                        />
                        <S.ButtonsRow>
                            <Button
                                size={'s'}
                                href="https://moodle.org/login/index.php?loginredirect=1"
                                target="_blank"
                            >
                                Вход в Moodle
                            </Button>
                        </S.ButtonsRow>
                    </S.ContainerMainInformation>
                </S.ContainerLeftContent>
                <S.ContainerRightContent>
                    <Link to={RouterPaths.StudentEdit(id)}>
                        <Button size={'s'}>Редактировать</Button>
                    </Link>

                    <GrayTextM>
                        Окончание обучения:{' '}
                        {formatDate(course_data?.dateEndLearning)}
                    </GrayTextM>
                </S.ContainerRightContent>
            </S.ContainerTopContent>

            <Tabs
                tabs={[
                    {
                        label: 'Информация',
                        content: (
                            <S.ContainerField>
                                <MainDataRow
                                    label="Телефон"
                                    value={user.phone}
                                    icon="phone"
                                />
                                <MainDataRow
                                    label="E-mail"
                                    value={user.email}
                                    icon="email"
                                />
                                <DocumentDataRow
                                    label="Паспорт"
                                    value={`${student.passportSeries} ${student.passportNumber}`}
                                    downloadUrl={student.passport?.url}
                                />
                                <DocumentDataRow
                                    label="СНИЛС"
                                    value={student.snilsNumber}
                                    downloadUrl={student.snils?.url}
                                />
                                <DocumentDataRow
                                    label="Диплом"
                                    value={student.diplomNumber}
                                    downloadUrl={
                                        student.educationalDocument?.url
                                    }
                                />
                                <DocumentDataRow
                                    label="Квалификация"
                                    value={student.qualification}
                                    downloadUrl={
                                        student.qualificationDocument?.url
                                    }
                                />
                            </S.ContainerField>
                        )
                    },
                    {
                        label: 'Курсы',
                        content: (
                            <Table
                                paddingTop
                                columns={COURSES_COLUMNS}
                                rows={assignedCourses}
                                parseColumns={parseColumns}
                                linkGetter={RouterPaths.CourseAssignment}
                            />
                        )
                    }
                ]}
            />
        </>
    );
};

export default withAuth(withContainer(ProfileStudent, STUDENT_EDIT_PROPS));
