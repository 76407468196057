import styled from 'styled-components';
import { inputLabelMargin } from 'tokens';

export const FormRow = styled.div`
    display: flex;
    flex-flow: row;
    gap: 10px;

    & > * {
        flex: 1;
    }
`;

export const FileFieldContainer = styled.div`
    padding-top: ${inputLabelMargin};
`;
