import { ButtonTheme } from './Button.types';

export const buttonBackground = (theme?: ButtonTheme) => {
    switch (theme) {
        case 'pdf':
            return 'rgba(218, 55, 50, 0.7)';
        case 'word':
            return 'rgba(30, 60, 137, 0.82)';
        case 'delete':
            return 'rgba(254, 92, 115, 0.5)';
        default:
            return '#718ebf';
    }
};
