import styled, { css } from 'styled-components';
import { headlineSizeL, inputLabelMargin } from '../../tokens';
import { themeable } from 'themes/utils';
import Button from 'ui/Button/Button';

export const Headline = styled.h1<{ $disableMargin?: boolean }>`
    font-size: ${headlineSizeL};
    color: ${themeable('textColor')};
    font-weight: 400;
    ${({ $disableMargin }) =>
        !$disableMargin &&
        css`
            margin-bottom: 24px;
        `}
    text-align: center;
`;

export const BigHeadline = styled.h1`
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 12px;
`;

export const Title = styled.p`
    color: rgb(255, 255, 255);
    //font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
`;
//обычный белый по больше текст
export const TextL = styled.p`
    color: rgb(255, 255, 255);
    //font-family: Barlow;
    font-size: 24px;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0%;
`;

export const TextLNormal = styled(TextL)`
    font-weight: 400;
`;
//обычный белый по меньше текст
export const TextM = styled.span`
    color: rgb(255, 255, 255);
    //font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0%;
`;
//обычный серый текст
export const GrayTextM = styled(TextM)`
    color: #abbbc2;
`;
//обычный синий цвет
export const BlueTextM = styled(TextM)`
    color: #718ebf;
`;

export const absoluteMixin = css`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
`;

export const RelativeContainer = styled.div`
    position: relative;
`;

export const AutoFlexButton = styled(Button)`
    flex: none !important;
    margin-top: ${inputLabelMargin};
`;

export const CenterContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: auto;

    width: 100%;
    min-height: 100%;
    text-align: center;
`;
