import { FC, useContext } from 'react';
import * as S from './Container.styled';
import { MAIN_BLOCK_ID } from '../../helpers/constants';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { SidebarProps } from 'components/Sidebar/Sidebar.types';
import { PopupProvider } from 'components/Popup';
import { UserContext } from 'context/User';
import { BigHeadline, CenterContainer, TextLNormal } from 'ui/Styled/Styled';

export type ContainerProps = ChildrenProps & {
    disablePaddingTop?: boolean;
} & SidebarProps;

const Container: FC<ContainerProps> = ({
    children,
    disablePaddingTop,
    ...sidebarProps
}) => {
    const { user } = useContext(UserContext);

    return (
        <S.Container>
            <Sidebar {...sidebarProps} />

            <S.MainContainer id={MAIN_BLOCK_ID}>
                <PopupProvider>
                    <S.Main $disablePaddingTop={disablePaddingTop}>
                        {user?.isEmailConfirmed === false ? (
                            <CenterContainer>
                                <BigHeadline>Почта не подтверждена</BigHeadline>
                                <TextLNormal>
                                    На вашу почту <b>{user.email}</b> было
                                    отправлено письмо с подтверждением.
                                    <br />
                                    Пройдите по ссылке из письма для продолжения
                                    работы с сервисом.
                                </TextLNormal>
                            </CenterContainer>
                        ) : (
                            children
                        )}
                    </S.Main>
                </PopupProvider>
            </S.MainContainer>
        </S.Container>
    );
};

export default Container;
