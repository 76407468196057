import React from 'react';
import { RouterPaths } from 'helpers/router-paths';
import { MenuProps } from './Menu.types';
import {
    ComissonIcon,
    DocumentsIcon,
    LoginIcon,
    RegisterIcon,
    SettingsIcon,
    CoursesMenuIcon
} from './assets/ItemsIcons';
import { OrganizationType } from 'api/api';

export const anonymousMenu: MenuProps = {
    items: [
        {
            text: 'Войти',
            path: RouterPaths.Auth,
            icon: <LoginIcon />,
            whiteIcon: <LoginIcon />
        },
        {
            text: 'Регистрация',
            icon: <RegisterIcon />,
            path: RouterPaths.RegisterUser,
            subItems: [
                {
                    path: RouterPaths.RegisterUser,
                    text: 'Создать пользователя'
                },
                {
                    path: RouterPaths.RegisterOrg,
                    text: 'Создать организацию'
                }
            ]
        }
    ]
};

export const operatorMenu: MenuProps = {
    items: [
        {
            text: 'Студенты',
            icon: <LoginIcon />,
            path: RouterPaths.FindStudents,
            subItems: [
                {
                    path: RouterPaths.FindStudents,
                    text: 'Найти студента'
                },
                {
                    path: RouterPaths.CreateStudents,
                    text: 'Создать студента'
                }
            ]
        },
        {
            text: 'Организации',
            icon: <RegisterIcon />,
            path: RouterPaths.FindOrganization,
            subItems: [
                {
                    path: RouterPaths.FindOrganization,
                    text: 'Найти организацию'
                },
                {
                    path: RouterPaths.CreateOrganization,
                    text: 'Создать организацию'
                }
            ]
        },
        {
            text: 'Курсы',
            icon: <CoursesMenuIcon />,
            path: RouterPaths.FindCourse,
            organizationType: OrganizationType.EducationCenter,
            subItems: [
                {
                    path: RouterPaths.FindCourse,
                    text: 'Найти курс'
                },
                {
                    path: RouterPaths.CreateCourse,
                    text: 'Создать курс'
                },
                {
                    path: RouterPaths.AssignCourse,
                    text: 'Назначить курс'
                }
            ]
        },
        {
            text: 'Комиссии',
            icon: <ComissonIcon />,
            path: RouterPaths.FindComission,
            subItems: [
                {
                    path: RouterPaths.FindComission,
                    text: 'Найти комиссию'
                },
                {
                    path: RouterPaths.CreateComission,
                    text: 'Создать комиссию'
                }
            ]
        },
        {
            text: 'Документы',
            icon: <DocumentsIcon />,
            path: RouterPaths.CoursesAssignments,
            subItems: [
                {
                    path: RouterPaths.CoursesAssignments,
                    text: 'Документы по курсам'
                },
                {
                    path: RouterPaths.Documents,
                    text: 'Документы'
                }
            ]
        },
        {
            text: 'Профиль',
            icon: <SettingsIcon />,
            path: RouterPaths.Profile,
            subItems: [
                {
                    path: RouterPaths.Profile,
                    text: 'Профиль'
                },
                { path: RouterPaths.CreateOperator, text: 'Создать оператора' },
                {
                    path: RouterPaths.SetPassword,
                    text: 'Сменить пароль'
                }
            ]
        }
    ]
};

export const studentMenu: MenuProps = {
    items: [
        {
            text: 'Профиль',
            icon: <SettingsIcon />,
            path: RouterPaths.Home,
            subItems: [
                {
                    path: RouterPaths.Home,
                    text: 'Профиль'
                },
                {
                    path: RouterPaths.SetPassword,
                    text: 'Сменить пароль'
                }
            ]
        }
    ]
};
