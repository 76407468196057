import { useContext, useMemo } from 'react';
import {
    getCurrentOrganization,
    getCurrentOrganizationIdNumber,
    UserContext
} from '../User.context';
import { OrganizationInfo } from 'api/organizations/organizations.types';
import { OptionProps } from 'ui/Select/Select.types';

export const useCurrentOrganizationId = () =>
    useContext(UserContext).user?.operator?.organization?.id ||
    getCurrentOrganizationIdNumber();

export const useCurrentOrganization = () =>
    (useContext(UserContext).user?.operator?.organization as
        | OrganizationInfo
        | undefined) || getCurrentOrganization();

export const useUser = () => {
    const { user } = useContext(UserContext);

    if (!user) {
        throw new Error(`User was not loaded`);
    }

    return user;
};

export const useCurrentOrganizationOption = () => {
    const organization = useCurrentOrganization();

    const organizationDefaultOption = useMemo<OptionProps<number> | undefined>(
        () =>
            organization
                ? {
                      label: organization.title,
                      value: organization.id
                  }
                : undefined,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organization?.id]
    );

    return organizationDefaultOption;
};

export const useCurrentOrganizationTitleOption = () => {
    const organization = useCurrentOrganization();

    const option = useMemo<OptionProps<string> | undefined>(
        () =>
            organization
                ? {
                      label: organization.title,
                      value: organization.title
                  }
                : undefined,
        [organization]
    );

    return option;
};

export const useOrganizationType = () => {
    const { user } = useContext(UserContext);

    if (window.organization?.organizationType) {
        return window.organization.organizationType;
    }

    return user?.operator?.organization?.organizationType;
};
