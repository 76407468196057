export function makeArray<T>(items: T | T[]): T[] {
    return Array.isArray(items) ? items : [items];
}

export function toggleInArray<T>(
    value: T,
    array: T[],
    compareOptions: (a: T, b: T) => boolean = (a, b) => a === b
): T[] {
    const newArray = [...array];
    const index = newArray.findIndex((val) => compareOptions(val, value));
    if (index > -1) {
        newArray.splice(index, 1);
    } else {
        newArray.push(value);
    }

    return newArray;
}
