import { FC, useCallback, useContext, useState } from 'react';
import * as S from './Sidebar.styled';
import { RouterPaths } from 'helpers/router-paths';
import Menu from 'ui/Menu/Menu';
import { Theme, ThemeContext } from 'context/Theme/Theme.context';
import { ReactComponent as Sun } from './assets/sun.svg';
import { ReactComponent as Moon } from './assets/moon.svg';
import { useSetFalse, useSetTrue } from 'hooks/booleans';
import { ReactComponent as Logo } from './assets/logo.svg';
import { RelativeContainer } from 'ui/Styled/Styled';
import { useClickOutside } from 'hooks/useClickOutside';
import { SidebarProps } from './Sidebar.types';
import { CalendarButton as SCalendarButton } from 'components/Calendar/Calendar.styled';
import { Calendar } from 'components/Calendar/Calendar';
import { RawSelect } from 'ui/Select';
import { useCurrentOrganizationOption, UserContext } from 'context/User';
import { useOrganizationsOptions } from 'context/Organizations';
import { OptionProps } from 'ui/Select/Select.types';
import { OrganizationInfo } from 'api/organizations/organizations.types';

const THEME_NAMES: Record<Theme, { icon: React.ReactNode; label: string }> = {
    light: { icon: <Sun />, label: 'Светлая' },
    dark: { icon: <Moon />, label: 'Темная' }
};

const CalendarButton = () => {
    const [isOpen, setIsOpen] = useState(false);

    const open = useSetTrue(setIsOpen, !isOpen);
    const close = useSetFalse(setIsOpen);

    const ref = useClickOutside(close, [], isOpen);

    return (
        <RelativeContainer>
            <SCalendarButton onClick={open} />
            {isOpen && (
                <S.CalendarContainer ref={ref}>
                    <Calendar
                        value={null}
                        onChange={() => {
                            return;
                        }}
                    />
                </S.CalendarContainer>
            )}
        </RelativeContainer>
    );
};

export const Sidebar: FC<SidebarProps> = ({ ...menuProps }) => {
    const { setTheme, theme } = useContext(ThemeContext);
    const { changeOrganization } = useContext(UserContext);

    const organizationsOptions = useOrganizationsOptions();
    const organizationOption = useCurrentOrganizationOption();

    const handleChange = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (_: any, option?: OptionProps<number, OrganizationInfo>) => {
            if (!option?.data) {
                throw new Error(`Empty option.data in onChange action`);
            }

            changeOrganization(option.data);
        },
        [changeOrganization]
    );

    return (
        <S.Sidebar>
            <S.BrandContainer>
                <S.MainLink to={RouterPaths.Home}>
                    <Logo />
                </S.MainLink>
                <CalendarButton />
            </S.BrandContainer>
            <S.MenuContainer>
                <S.MenuLabel>Меню</S.MenuLabel>
                <Menu {...menuProps} />
            </S.MenuContainer>
            {organizationOption && (
                <S.Wrapper>
                    <RawSelect
                        options={organizationsOptions}
                        onChange={handleChange}
                        value={organizationOption.value}
                        defaultOption={organizationOption}
                    />
                </S.Wrapper>
            )}
            <S.ThemeChoiceContainer>
                <S.ThemeChoicesRow>
                    {Object.entries(THEME_NAMES).map(
                        ([key, { icon, label }]) => (
                            <S.ThemeChoice
                                $active={theme === key}
                                onClick={() => {
                                    if (theme === key) {
                                        return;
                                    }

                                    setTheme(key as Theme);
                                }}
                                key={key}
                            >
                                <S.ThemeIcon>{icon}</S.ThemeIcon>
                                {label}
                            </S.ThemeChoice>
                        )
                    )}
                </S.ThemeChoicesRow>
            </S.ThemeChoiceContainer>
        </S.Sidebar>
    );
};
