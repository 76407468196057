import { OrganizationInfo } from 'api/organizations/organizations.types';
import { PaginationResponse } from 'typings/pagination';
import { OptionsGenerator } from 'ui/Select/Select.types';
import { get } from 'utils/fetch';

export const getEducationCentersList = (search?: string) =>
    get<PaginationResponse<OrganizationInfo>>('Operator/GetEducationCenters', {
        search
    });

export const getEducationCenterOptions: OptionsGenerator = (...args) =>
    getEducationCentersList(...args).then(({ items }) =>
        items.map((item) => ({ label: item.title, value: item.id }))
    );
