import { StudentModel } from 'api/api';
import { UserInfo } from 'api/users/users.types';

export const getStudentFullname = (student: StudentModel | undefined) => {
    if (!student?.user) {
        throw new Error(`No user object in student object`);
    }

    const { surname, lastname, name } = student.user;

    return `${surname} ${name} ${lastname}`;
};

export const getUserFullname = (
    user: Pick<UserInfo, 'surname' | 'lastname' | 'name'>
) => {
    const { surname, lastname, name } = user;

    return `${surname} ${name} ${lastname}`;
};
