import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {
    isPaginationResponse,
    PaginationResponse
} from '../../typings/pagination';
import { Table } from '../Table';
import * as S from './PowerTable.styled';
import { useCurrentOrganizationId } from 'context/User';
import useUpdateEffect from 'hooks/useUpdateEffect';
import { PowerTableProps } from './PowerTable.types';
import { Headline } from 'ui/Styled/Styled';
import { IdProps, RowProps } from 'ui/Table/Table.types';
import { PopupChildren, PopupContext } from 'components/Popup';
import Button from 'ui/Button/Button';
import { useToggle } from 'hooks/booleans';
import { pluralize } from 'utils/strings';

export function PowerTable<
    RowData extends IdProps<IdValue>,
    IdColumn extends keyof RowData = 'id',
    IdValue extends RowData[IdColumn] = RowData[IdColumn]
>({
    columns,
    getter,
    title,
    search = true,
    onDelete,
    deleteWords,
    linkGetter,
    ...tableProps
}: PowerTableProps<RowData, IdColumn>): JSX.Element {
    const searchRef = useRef('');
    const lastSearchedRef = useRef('');
    const [response, setResponse] = useState<
        PaginationResponse<RowData> | RowData[]
    >();
    const [loading, setLoading] = useState(true);
    const timer = useRef<NodeJS.Timeout>();
    const organizationId = useCurrentOrganizationId();

    const loadRows = useCallback(async () => {
        const rows = await getter(...(search ? [searchRef.current] : []));
        setResponse(rows);
        lastSearchedRef.current = searchRef.current;
        setLoading(false);
    }, [getter, search]);

    const refreshResults = useCallback(
        async (force = false) => {
            if (
                force ||
                (!loading && lastSearchedRef.current !== searchRef.current)
            ) {
                setLoading(true);
                loadRows();
            }
        },
        [loadRows, loading]
    );

    useEffect(() => {
        loadRows();
    }, [loadRows]);

    useUpdateEffect(() => {
        refreshResults(true);
    }, [organizationId]);

    const changeSearchValue = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            searchRef.current = e.target.value;

            if (timer.current) {
                clearTimeout(timer.current);
            }

            timer.current = setTimeout(refreshResults, 1000);

            return () => {
                clearTimeout(timer.current);
            };
        },
        [refreshResults]
    );

    const onSearchKeyDown = useCallback(
        ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
            if (key === 'Enter') {
                refreshResults();
            }
        },
        [refreshResults]
    );

    const items = useMemo(
        () =>
            response
                ? isPaginationResponse(response)
                    ? response.items
                    : response
                : [],
        [response]
    );

    const [editMode, setEditMode] = useState(false);

    const [selectedRows, setSelectedRows] = useState<IdValue[]>([]);

    const toggleEditMode = useToggle(setEditMode);

    const { open } = useContext(PopupContext);

    const afterRowContent = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (id: IdValue, _row: RowProps<RowData>) => {
            const deleteHandler = () => {
                if (onDelete) {
                    const onClick = () => onDelete([id]);

                    const entityString = deleteWords?.[1] ?? deleteWords?.[0];

                    const popupContent: PopupChildren = ({ close }) => (
                        <>
                            <S.DeletePopupTitle>
                                {entityString ? (
                                    <>
                                        Вы действительно хотите удалить{' '}
                                        {entityString}?
                                    </>
                                ) : (
                                    <>Вы подтверждате удаление?</>
                                )}
                            </S.DeletePopupTitle>
                            <Button
                                view="delete"
                                onClick={() => {
                                    onClick().then(() => {
                                        close();
                                        refreshResults(true);
                                    });
                                }}
                            >
                                Удалить
                            </Button>
                        </>
                    );

                    open(popupContent);
                }
            };

            return (
                <>
                    {onDelete && !editMode && (
                        <S.DeleteButtonContainer>
                            <S.DeleteHoverArea />
                            <S.DeleteButton onClick={deleteHandler} />
                        </S.DeleteButtonContainer>
                    )}
                </>
            );
        },
        [deleteWords, editMode, onDelete, open, refreshResults]
    );

    const deleteHandler = useCallback(() => {
        if (!onDelete || !selectedRows?.length) {
            return;
        }

        const onClick = () => onDelete(selectedRows);

        const entityString =
            deleteWords && deleteWords.length > 1
                ? pluralize(selectedRows.length, [
                      deleteWords[1] ?? deleteWords[0],
                      deleteWords[2] ?? deleteWords[0],
                      deleteWords[0]
                  ])
                : deleteWords?.[0];

        const popupContent: PopupChildren = ({ close }) => (
            <>
                <S.DeletePopupTitle>
                    {entityString ? (
                        <>Вы действительно хотите удалить {entityString}?</>
                    ) : (
                        <>Вы подтверждате удаление?</>
                    )}
                </S.DeletePopupTitle>
                <Button
                    view="delete"
                    onClick={() => {
                        onClick()
                            .then(() => {
                                close();
                                refreshResults(true);
                                setEditMode(false);
                            })
                            .catch((e) => {
                                console.error(
                                    `Error while deleting rows in PowerTable`
                                );
                                console.error(e);

                                close();
                            });
                    }}
                >
                    Удалить
                </Button>
            </>
        );

        open(popupContent);
    }, [deleteWords, onDelete, open, refreshResults, selectedRows]);

    const showButtons = items.length > 0 && !!onDelete;

    return (
        <>
            <S.TableHeader $hasMargin={showButtons}>
                <S.TableBefore />
                {title && <Headline $disableMargin>{title}</Headline>}

                <S.TableButtons>
                    {editMode && (
                        <>
                            <S.DeleteActionButton
                                view="delete"
                                disabled={!selectedRows?.length}
                                onClick={deleteHandler}
                            />
                        </>
                    )}
                    {showButtons && (
                        <Button onClick={toggleEditMode}>
                            {editMode ? 'Назад' : 'Редактировать'}
                        </Button>
                    )}
                </S.TableButtons>
            </S.TableHeader>
            <S.Container>
                {search && (
                    <S.SearchField
                        type="search"
                        placeholder="Поиск"
                        onChange={changeSearchValue}
                        onKeyDown={onSearchKeyDown}
                        readOnly={loading}
                    />
                )}
                <Table
                    loading={loading}
                    columns={columns}
                    paddingTop={search}
                    afterRowContent={afterRowContent}
                    editMode={editMode}
                    selectedRows={selectedRows}
                    onRowSelect={setSelectedRows}
                    rows={items}
                    linkGetter={editMode ? undefined : linkGetter}
                    {...tableProps}
                />
            </S.Container>
        </>
    );
}
