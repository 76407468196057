import styled from 'styled-components';
import { StyledButton } from 'ui/Button/Button';
import { TextM } from 'ui/Styled/Styled';
import { S as TableS } from 'ui/Table';

export const DatesRow = styled.div`
    display: flex;
    flex-flow: row;
    gap: 30px;
    margin: 0 auto;
`;

export const DocumentRowLabel = styled(TableS.Cell)`
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    padding-left: 30px;
`;

export const DocumentRowButton = styled(TableS.Cell)`
    & ${StyledButton} {
        margin-left: auto;
    }
`;

export const DocumentObjectTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
`;

export const DocumentObjectHeader = styled.header`
    padding: 0 30px;
`;

export const DocumentObjectDate = styled(TextM)`
    color: #abbbc2;
`;

export const DocumentSectionTitle = styled.div`
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    border-bottom: 2px solid #ffffff;
    padding-bottom: 4px;
    padding-left: 10px;
    margin-top: 46px;
    margin-bottom: 30px;
`;

export const DisciplinesContainer = styled.div`
    padding: 0 40px 30px;
    display: grid;
    gap: 4px;
`;

export const DisciplineRow = styled.div`
    display: grid;
    gap: 8px;
    grid-template-columns: 1.5fr 3fr 1.5fr 2fr;
`;

export const DisciplineLabelsRow = styled(DisciplineRow)`
    text-align: center;
`;
