import {
    createContext,
    FC,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState
} from 'react';
import * as S from './Popup.styled';
import React from 'react';
import { PopupChildren } from './Popup.types';
import { useSetFalse } from 'hooks/booleans';

type PopupProps = ChildrenProps &
    OpenProps & {
        onClose: () => void;
    };

export const Popup: FC<PopupProps> = ({ children, isOpen, onClose }) => {
    return (
        <>
            {isOpen && <S.MainBlurStyle />}

            <S.Wrapper $isOpen={isOpen}>
                <S.Overlay onClick={onClose} />
                <S.Container>{children}</S.Container>
            </S.Wrapper>
        </>
    );
};

type OpenFunc = (content: PopupChildren, onClose?: () => void) => void;

type PopupContextProps = {
    open: OpenFunc;
    close(): void;
};

export const PopupContext = createContext<PopupContextProps>({
    open() {
        return;
    },
    close() {
        return;
    }
});

export const PopupProvider: FC<ChildrenProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const contentRef = useRef<React.ReactNode>();
    const onCloseFunc = useRef<() => void>();

    const close = useSetFalse(setIsOpen);

    const closeHandler = useCallback(() => {
        close();
        onCloseFunc.current?.();
    }, [close]);

    const open = useCallback<OpenFunc>(
        (content, onClose) => {
            contentRef.current =
                typeof content === 'function' ? content({ close }) : content;
            onCloseFunc.current = onClose ?? undefined;

            setIsOpen(true);
        },
        [close]
    );

    const value = useMemo(() => ({ open, close }), [close, open]);

    return (
        <PopupContext.Provider value={value}>
            {children}
            {contentRef.current && (
                <Popup isOpen={isOpen} onClose={closeHandler}>
                    {contentRef.current}
                </Popup>
            )}
        </PopupContext.Provider>
    );
};

export const usePopup = (content: PopupChildren, onClose?: () => void) => {
    const { open } = useContext(PopupContext);

    const openHandler = useCallback(() => {
        open(content, onClose);
    }, [content, onClose, open]);

    return openHandler;
};

export const usePopupClose = () => {
    const { close } = useContext(PopupContext);

    return close;
};
