import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { getOrganizations } from '../../api/organizations/organizations';
import { OrganizationInfo } from '../../api/organizations/organizations.types';
import { ColumnProps } from '../../ui/Table';
import { PowerTable } from 'ui/PowerTable';
import { RouterPaths } from 'helpers/router-paths';

const ORGANIZATIONS_COLUMNS: ColumnProps<OrganizationInfo> = {
    title: 'Название организации',
    inn: 'ИНН'
};

const OrganizatonList = () => (
    <PowerTable
        title="Организации"
        columns={ORGANIZATIONS_COLUMNS}
        getter={getOrganizations}
        noRowsMsg="Организации не найдены"
        search={true}
        showHead={true}
        linkGetter={RouterPaths.OrganizationProfile}
    />
);

export default withAuth(withContainer(OrganizatonList));
