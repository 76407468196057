import { get, postWithToast } from 'utils/fetch';
import * as Types from './documents.types';
import {
    CertificateDocModel,
    CertificateEducationDocModel,
    CreateDiplomDocModel,
    DiplomDocModel,
    TicketBModel
} from 'api/api';
import { useNumberId } from 'hooks/useId';

export const getCertificateInfo = (id: number) =>
    get<CertificateDocModel>(`Document/GetCertificateInfo/${id}`);

export const generateCertificate = (props: Types.CertificateGenerateData) =>
    postWithToast('Document/Certificate', props, { download: true });

export const getDiplomInfo = (id: number) =>
    get<DiplomDocModel>(`Document/GetDiplomInfo//${id}`);

export const generateDiplom = (props: CreateDiplomDocModel) =>
    postWithToast('Document/Diplom', props, { download: true });

export function useGenerateDocument<T extends object = object, Result = any>(
    func: (props: T & { assignId: number }) => Result
) {
    const assignId = useNumberId();

    return (props: T) => func({ ...props, assignId });
}

export const generateCertificateOfStudy = (
    props: CertificateEducationDocModel
) =>
    postWithToast<CertificateEducationDocModel>(
        `Document/CertificateEducation`,
        props,
        {
            download: true
        }
    );

// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
export const generateInvoice = (props: any) =>
    postWithToast(`Document/PaymentInvoice`, props, {
        download: true
    });

export const getTicketBInfo = (id: number) =>
    get<TicketBModel>(`Document/GetTicketBInfo/${id}`);

export const generateTicketB = (props: Types.CertificateGenerateData) =>
    postWithToast('Document/TicketB', props, { download: true });

export const getTicketMInfo = () =>
    get<Types.TicketM>(`Document/GetTicketMInfo`);

export const generateTicketM = (props: Types.TicketM) =>
    postWithToast('Document/TicketM', props, { download: true });
