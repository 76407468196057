import { EditForm } from 'ui/EditForm';
import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import { StyledForm } from 'ui/FormContainer/FormContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { DiplomDocModel } from 'api/api';
import {
    generateDiplom,
    getDiplomInfo,
    useGenerateDocument
} from 'api/documents/documents';
import { useWithNumberId } from 'hooks/useId';
import { FormRow } from 'ui/Form/Form.styled';
import * as S from '../Documents.styled';
import { formatBirthdayDate } from 'utils/date';
import { SelectField } from 'ui/Select';
import { gradeTypesOptions } from 'messages/options';
import { Label } from 'ui/Input/Input.styled';
import { FieldArray } from 'ui/FieldArray/FieldArray';
import { DOCUMENTS_PROPS, GenerateDocumentField } from '../constants';
import { getUserFullname } from 'utils/fullname';
import { EducationCenterField } from 'components/fields/EducationCenterField';

const Field = FormField<DiplomDocModel>;

const disciplinesLabels = (
    <S.DisciplineLabelsRow>
        {['Номер', 'Название', 'Количество часов', 'Оценка'].map((label) => (
            <Label key={label}>{label}</Label>
        ))}
    </S.DisciplineLabelsRow>
);

const DiplomForm = () => {
    const getter = useWithNumberId(getDiplomInfo);
    const saver = useGenerateDocument(generateDiplom);

    return (
        <EditForm
            getter={getter}
            saver={saver}
            successMessage="Диплом сформирован"
            wrap={false}
        >
            {({ values: { appendixOne, birthday, ...values } }) => (
                <>
                    <Headline>Диплом</Headline>
                    {appendixOne && (
                        <S.DocumentObjectTitle>
                            {getUserFullname(values)}
                        </S.DocumentObjectTitle>
                    )}
                    <S.DocumentObjectDate>
                        {formatBirthdayDate(birthday)}
                    </S.DocumentObjectDate>
                    <S.DocumentSectionTitle>Диплом</S.DocumentSectionTitle>
                    <StyledForm>
                        <FormRow>
                            <Field name="series" required label="Серия" />
                            <Field name="number" required label="Номер" />
                        </FormRow>
                        <Field
                            name="dateStart"
                            required
                            label="Дата начала прохождения курса"
                            type="date"
                        />
                        <Field
                            name="dateEnd"
                            required
                            label="Дата окончания прохождения курса"
                            type="date"
                        />

                        <Field name="course" required label="Название курса" />

                        <Field
                            name="hoursCount"
                            required
                            type="number"
                            label="Количество часов"
                        />

                        <Field
                            name="licenseDate"
                            required
                            type="date"
                            label="Дата удостоверения права (дата окончания курса)"
                        />
                        <Field
                            name="regNumber"
                            label="Регистрационный номер (без серии)"
                        />

                        <EducationCenterField />
                    </StyledForm>

                    <S.DocumentSectionTitle>
                        Приложение 1
                    </S.DocumentSectionTitle>

                    <StyledForm>
                        <FormRow>
                            <Field
                                name="appendixOne.series"
                                required
                                label="Серия"
                            />
                            <Field
                                name="appendixOne.number"
                                required
                                label="Номер"
                            />
                        </FormRow>
                        <Field
                            name="appendixOne.number"
                            required
                            label="Номер (из диплома)"
                        />
                        <Field
                            name="appendixOne.themeTitle"
                            required
                            label="Название темы из аттестационной работы"
                        />
                    </StyledForm>

                    <S.DocumentSectionTitle>
                        Приложение 2
                    </S.DocumentSectionTitle>

                    <FieldArray<DiplomDocModel>
                        name={'appendixTwo.disciplines'}
                    >
                        {({ fields }) => (
                            <S.DisciplinesContainer>
                                {disciplinesLabels}
                                {fields.map((discipline) => (
                                    <S.DisciplineRow key={discipline}>
                                        <FormField
                                            name={`${discipline}.id`}
                                            readOnly
                                            center
                                        />
                                        <FormField
                                            name={`${discipline}.title`}
                                            readOnly
                                            center
                                        />
                                        <FormField
                                            name={`${discipline}.hoursCount`}
                                            center
                                            readOnly
                                        />
                                        <SelectField
                                            name={`${discipline}.gradeType`}
                                            options={gradeTypesOptions}
                                            search={false}
                                            required
                                        />
                                    </S.DisciplineRow>
                                ))}
                            </S.DisciplinesContainer>
                        )}
                    </FieldArray>

                    <GenerateDocumentField />
                </>
            )}
        </EditForm>
    );
};

export default withAuth(withContainer(DiplomForm, DOCUMENTS_PROPS));
