import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import { Form } from '../../ui/FormContainer/FormContainer';
import FormField from '../../ui/FormField/FormField';
import { CreateStudentFormData } from '../../api/students/students.types';
import Button from '../../ui/Button/Button';

import ButtonsContainer from 'ui/Button/ButtonContainer';

import { FileFieldContainer, FormRow } from 'ui/Form/Form.styled';
import { makeCreateFormFunc } from 'utils/api';
import { createStudent } from 'api/students/students';
import { AddressField } from 'components/fields/AddressField';
import { OrganizationField } from 'components/fields/OrganizationField';
import { SelectField } from 'ui/Select';
import { educationTypeOptions } from 'messages/options';

const Field = FormField<CreateStudentFormData>;
const Select = SelectField<CreateStudentFormData>;

const handleSubmit = makeCreateFormFunc(createStudent, 'Студент создан!');

const CreateStudents = () => {
    return (
        <>
            <Headline>Создать студента</Headline>
            <Form onSubmit={handleSubmit}>
                {({ submitting }) => (
                    <>
                        <FormRow>
                            <Field
                                name="surname"
                                label="Фамилия"
                                placeholder="Иванов"
                                required
                            />
                            <Field
                                name="name"
                                label="Имя"
                                placeholder="Иван"
                                required
                            />
                            <Field
                                name="lastName"
                                label="Отчество"
                                placeholder="Иванович"
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <Field
                                name="birthday"
                                label="Дата рождения"
                                placeholder="23.08.1999"
                                type="date"
                                required
                            />
                            <Field
                                name="phone"
                                type="tel"
                                label="Номер телефона"
                                required
                            />
                        </FormRow>
                        <Field
                            name="email"
                            type="email"
                            label="E-mail"
                            required
                        />
                        <OrganizationField />
                        <Field
                            name="placeWork"
                            label="Текущее место работы"
                            required
                        />
                        <AddressField />
                        <Field
                            name="rank"
                            type="number"
                            required
                            label="Разряд"
                        />
                        <Field
                            name="password"
                            required
                            label="Пароль"
                            type="password"
                        />
                        <Field
                            name="confirmPassword"
                            required
                            type="password"
                            label="Повторите пароль"
                        />
                        <FormRow>
                            <Field type="file" label="Аватар" name="avatar" />
                        </FormRow>

                        <FormRow>
                            <FormRow>
                                <Field
                                    name="passportSeries"
                                    required
                                    label="Паспорт"
                                    placeholder="Серия паспорта"
                                />
                                <Field
                                    name="passportNumber"
                                    required
                                    label="&nbsp;"
                                    placeholder="Номер паспорта"
                                />
                            </FormRow>
                            <FileFieldContainer>
                                <Field type="file" name="passport" />
                            </FileFieldContainer>
                        </FormRow>

                        <FormRow>
                            <Field
                                name="diplomNumber"
                                required
                                label="Документ об образовании"
                                placeholder="Номер диплома"
                            />

                            <Select
                                name="educationType"
                                required
                                label="Вид образования"
                                options={educationTypeOptions}
                            />
                            <FileFieldContainer>
                                <Field type="file" name="educationDocument" />
                            </FileFieldContainer>
                        </FormRow>

                        <FormRow>
                            <Field
                                name="snilsNumber"
                                required
                                label="СНИЛС"
                                placeholder="Номер снилс"
                                type="snils"
                            />
                            <FileFieldContainer>
                                <Field type="file" name="snils" />
                            </FileFieldContainer>
                        </FormRow>

                        <FormRow>
                            <Field
                                name="qualification"
                                required
                                label="Документ о повышении квалификации"
                                placeholder="Номер документа"
                            />
                            <FileFieldContainer>
                                <Field
                                    type="file"
                                    name="qualificationDocument"
                                />
                            </FileFieldContainer>
                        </FormRow>
                        <ButtonsContainer>
                            <Button type="submit" disabled={submitting}>
                                Создать
                            </Button>
                        </ButtonsContainer>
                    </>
                )}
            </Form>
        </>
    );
};

export default withAuth(withContainer(CreateStudents));
