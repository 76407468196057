import { RouterPaths } from 'helpers/router-paths';
import { ContainerProps } from 'components/Container/Container';
import { ButtonOptionProps, ButtonsField } from 'ui/ButtonsField/ButtonsField';
import { FileExtensionType } from 'api/api';

export const DOCUMENTS_PROPS: Partial<ContainerProps> = {
    activeItem: RouterPaths.CoursesAssignments
};

export const STATIC_DOCUMENTS_PROPS: Partial<ContainerProps> = {
    activeItem: RouterPaths.Documents
};

export const WORD_EXTENSION_OPTIONS: ButtonOptionProps<FileExtensionType>[] = [
    {
        value: FileExtensionType.Docx,
        view: 'word',
        children: 'Скачать в Word'
    }
];

export const EXTENSIONS_OPTIONS: ButtonOptionProps<FileExtensionType>[] = [
    ...WORD_EXTENSION_OPTIONS,
    {
        value: FileExtensionType.Pdf,
        view: 'pdf',
        children: 'Скачать в PDF'
    }
];

const FIELD_NAME = 'extensionType';

export const GenerateDocumentField = () => (
    <ButtonsField name={FIELD_NAME} options={EXTENSIONS_OPTIONS} />
);

export const GenerateWordField = () => (
    <ButtonsField name={FIELD_NAME} options={WORD_EXTENSION_OPTIONS} />
);
