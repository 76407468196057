import { OptionProps } from 'ui/Select/Select.types';
import { OrganizationInfo } from './organizations.types';
import { OrganizationType } from 'api/api';

export const organizationTitle = (item: OrganizationInfo) =>
    (item.organizationType === OrganizationType.EducationCenter ? '🏫  ' : '') +
    item.title;

export const mapOrganizationOption = (
    item: OrganizationInfo
): OptionProps<number> => ({
    label: organizationTitle(item),
    value: item.id,
    data: item
});

export const mapOrganizationTitleOption = (
    item: OrganizationInfo
): OptionProps<string> => ({
    label: organizationTitle(item),
    value: item.title
});
