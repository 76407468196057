import { EditForm } from 'ui/EditForm';
import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { CertificateDocModel } from 'api/api';
import {
    generateCertificate,
    getCertificateInfo
} from 'api/documents/documents';
import { useWithNumberId } from 'hooks/useId';
import { DOCUMENTS_PROPS, GenerateDocumentField } from '../constants';
import { PhotoField } from 'components/fields/PhotoField/PhotoField';
import { EducationCenterField } from 'components/fields/EducationCenterField';

const Field = FormField<CertificateDocModel>;

const CertificateForm = () => {
    const getter = useWithNumberId(getCertificateInfo);

    return (
        <EditForm
            getter={getter} // Получаем данные организации
            saver={generateCertificate} // Сохраняем изменения
            successMessage="Свидетельство сформировано"
        >
            <Headline>Свидетельство</Headline>

            <PhotoField />

            <Field name="number" label="Номер документа" />
            <Field name="surname" label="Фамилия" />
            <Field name="name" label="Имя" />
            <Field name="lastname" label="Отчество" />

            <Field
                name="dateStart"
                label="Дата начала прохождения курса"
                type="date"
            />
            <Field
                name="dateEnd"
                label="Дата окончания прохождения курса"
                type="date"
            />

            <Field name="hoursCount" label="Количество часов" />
            <Field name="course" label="Квалификация" />

            <EducationCenterField />

            <GenerateDocumentField />
        </EditForm>
    );
};

export default withAuth(withContainer(CertificateForm, DOCUMENTS_PROPS));
