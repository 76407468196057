import React, {
    createContext,
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useUserType } from '../Auth';
import { UserInfo } from '../../api/users/users.types';
import { getCurrentUser } from '../../api/users/users';
import {
    OrganizationId,
    OrganizationInfo
} from 'api/organizations/organizations.types';
import { changeOrganization } from 'api/organizations/organizations';

type UserContextProps = {
    user?: UserInfo;
    changeOrganization(organization: OrganizationInfo): Promise<void>;
    refresh(): Promise<boolean>;
};

type AuthProviderProps = {
    children: React.ReactNode;
};

export const UserContext = createContext<UserContextProps>({
    refresh: () => Promise.resolve(false),
    changeOrganization: () => Promise.resolve()
});

declare global {
    interface Window {
        organization?: OrganizationInfo;
    }
}

export function getCurrentOrganizationId(): OrganizationId {
    return window.organization?.id || 'current';
}

export function getCurrentOrganizationIdNumber(): number | undefined {
    return window.organization?.id;
}

export function getCurrentOrganization(): OrganizationInfo | undefined {
    return window.organization;
}

export const UserProvider: FC<AuthProviderProps> = ({ children }) => {
    const userType = useUserType();
    const [user, setUser] = useState<UserInfo>();
    const checked = useRef(false);

    const refresh = useCallback(async () => {
        try {
            const user = await getCurrentUser();
            window.organization = user.operator
                ?.organization as OrganizationInfo;
            setUser(user);
            checked.current = true;
            return true;
        } catch {
            checked.current = false;
            return false;
        }
    }, []);

    const changeOrgWithContext = useCallback(
        async (organization: OrganizationInfo) => {
            await changeOrganization(organization.id);

            window.organization = organization;

            setUser(
                (user) =>
                    user && {
                        ...user,
                        operator: user.operator && {
                            ...user.operator,
                            organization
                        }
                    }
            );
        },
        []
    );

    useEffect(() => {
        if (userType && !checked.current) {
            refresh();
        }
    }, [checked, refresh, userType]);

    const providerProps = useMemo(() => {
        return { user, refresh, changeOrganization: changeOrgWithContext };
    }, [changeOrgWithContext, refresh, user]);

    return (
        <UserContext.Provider value={providerProps}>
            {children}
        </UserContext.Provider>
    );
};
