import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import { Form } from 'ui/FormContainer/FormContainer';

import Button from '../../ui/Button/Button';
import { makeCreateFormFunc } from 'utils/api';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import FormField from 'ui/FormField/FormField';
import { RegisterOperatorModel } from 'api/api';
import { createOperator } from 'api/organizations/operators';
import { OrganizationField } from 'components/fields/OrganizationField';
import { FormRow } from 'ui/Form/Form.styled';

type Data = RegisterOperatorModel;

const Field = FormField<Data>;
const handleFormSubmit = makeCreateFormFunc(createOperator, 'Оператор создан!');

const CreateCourse = () => {
    return (
        <>
            <Headline>Создание оператора</Headline>
            <Form onSubmit={handleFormSubmit}>
                {({ submitting }) => (
                    <>
                        <FormRow>
                            <Field
                                name="name"
                                label="Имя"
                                placeholder="Имя"
                                required
                            />
                            <Field
                                name="surname"
                                label="Фамилия"
                                placeholder="Фамилия"
                                required
                            />
                            <Field
                                name="lastName"
                                label="Отчество"
                                placeholder="Отчество"
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <Field
                                name="phone"
                                type="tel"
                                label="Номер телефона"
                                required
                            />
                            <Field
                                name="email"
                                type="email"
                                required
                                label="E-mail"
                            />
                        </FormRow>

                        <OrganizationField />

                        <Field
                            name="password"
                            type="password"
                            required
                            label="Пароль"
                        />
                        <Field
                            name="confirmPassword"
                            type="password"
                            required
                            label="Повторите пароль"
                        />
                        <ButtonsContainer>
                            <Button type="submit" disabled={submitting}>
                                Создать
                            </Button>
                        </ButtonsContainer>
                    </>
                )}
            </Form>
        </>
    );
};

export default withAuth(withContainer(CreateCourse));
