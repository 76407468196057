import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { SubmissionError } from '../../typings/fetch';
import * as S from './Input.styled';
import { RawInputProps } from './Input.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InputProps = FieldRenderProps<any, HTMLElement, any> &
    Partial<HTMLInputElement> &
    RawInputProps;

const autoComplete = process.env.REACT_APP_AUTOCOMPLETE === '1';

const Input: FC<InputProps> = ({
    label,
    required,
    minLength,
    maxLength,
    readOnly,
    disabled,
    placeholder,
    meta,
    className,
    rightContent,
    mask,
    input,
    name: rawName,
    center,
    errors
}) => {
    const name = rawName || input.name;

    const extractedProps = {
        required,
        minLength,
        maxLength,
        readOnly,
        disabled,
        name
    };

    return (
        <S.Container>
            <S.Label>{label}</S.Label>
            <S.InputRow>
                <S.InputContainer className={className}>
                    <S.Input
                        $center={center}
                        mask={mask}
                        placeholder={placeholder || label?.toString()}
                        {...extractedProps}
                        {...input}
                        autoComplete={autoComplete ? undefined : 'off'}
                    />
                </S.InputContainer>
                {rightContent}
            </S.InputRow>
            <S.MetaContainer>
                {meta.touched && !meta.dirtySinceLastSubmit && (
                    <>
                        {meta.error && <S.Meta>{meta.error}</S.Meta>}
                        {(meta.submitError || errors)?.map(
                            ({ code, message }: SubmissionError) => (
                                <S.Meta key={code}>{message}</S.Meta>
                            )
                        )}
                    </>
                )}
            </S.MetaContainer>
        </S.Container>
    );
};

export default Input;
