import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { generateTicketM, getTicketMInfo } from 'api/documents/documents';
import { GenerateWordField, STATIC_DOCUMENTS_PROPS } from '../constants';
import { TicketM } from 'api/documents/documents.types';
import { SelectField } from 'ui/Select';
import { getCoursesOptions } from 'api/courses/courses';
import { getStudentsOptions } from 'api/students/students';
import { EditForm } from 'ui/EditForm';

const Field = FormField<TicketM>;
const Select = SelectField<TicketM>;

const TicketMForm = () => {
    return (
        <EditForm
            getter={getTicketMInfo}
            saver={generateTicketM}
            successMessage="Удостоверение сформировано"
        >
            <Headline>Удостоверение М</Headline>

            <Field name="protocolNumber" label="Номер документа" required />
            <Select
                name="courseId"
                onSearch={getCoursesOptions}
                label="Курс"
                required
            />

            <Select
                name="students"
                multiple
                label="Студенты"
                loader={getStudentsOptions}
                required
            />
            <Field
                name="dateStart"
                label="Начало срока дейтсвия"
                type="date"
                required
            />
            <Field
                name="dateEnd"
                label="Окончание срока действия"
                type="date"
                requiired
            />
            <Field
                name="commissionConclusion"
                label="Заключение комиссии"
                required
            />

            <GenerateWordField />
        </EditForm>
    );
};

export default withAuth(withContainer(TicketMForm, STATIC_DOCUMENTS_PROPS));
