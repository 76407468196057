import { FC, useCallback, useState } from 'react';
import { Calendar } from './Calendar';
import { useSetFalse, useSetTrue } from 'hooks/booleans';
import { useClickOutside } from 'hooks/useClickOutside';
import Input from 'ui/Input/Input';
import { InputContainer } from './Calendar.styled';
import { CalendarFieldProps } from './Calendar.types';
import * as S from './Calendar.styled';
import { cutDateTime } from 'utils/date';

export const CalendarField: FC<CalendarFieldProps> = ({
    input,
    meta,
    required,
    label
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const close = useSetFalse(setIsOpen);
    const open = useSetTrue(setIsOpen);

    const ref = useClickOutside(close, [], isOpen);

    const handleChange = useCallback(
        (value: Maybe<Date>) => {
            close();
            input.onChange(value && cutDateTime(value));
        },
        [close, input]
    );

    return (
        <S.Container ref={ref}>
            {isOpen && (
                <S.CalendarContainer>
                    <Calendar onChange={handleChange} value={input.value} />
                </S.CalendarContainer>
            )}
            <InputContainer onClick={open}>
                <Input
                    input={input}
                    meta={meta}
                    required={required}
                    label={label}
                />
            </InputContainer>
        </S.Container>
    );
};
