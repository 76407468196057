import { CertificateGenerateData } from 'api/documents/documents.types';
import { getEducationCenterOptions } from 'api/education-centers/education-centers';
import { SelectField } from 'ui/Select';

export const EducationCenterField = () => {
    return (
        <SelectField<CertificateGenerateData>
            label="Учебный центр"
            name="fromOrganizationId"
            onSearch={getEducationCenterOptions}
            required
        />
    );
};
