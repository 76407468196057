import { FetchCallback, postWithToast } from 'utils/fetch';
import { FileData } from './file.types';
import { makePromisable } from 'utils/api';
import { copyToClipboard } from 'utils/copy';

export const uploadFile = (file: File | Blob): FetchCallback => {
    const data = new FormData();
    data.set('file', file);
    return (onSuccess, onError) =>
        postWithToast<unknown, FileData>('/File/Save', data)((res) => {
            const fileData = res.data;

            if ('Notification' in window) {
                const send = () => {
                    const notification = new Notification('Файл загружен', {
                        body: 'Нажмите чтобы копировать URL.',
                        icon: '/favicon.ico'
                    });

                    notification.onclick = () => {
                        notification.close();

                        copyToClipboard(fileData.url);
                    };
                };

                if (Notification.permission === 'granted') {
                    send();
                } else if (Notification.permission !== 'denied') {
                    Notification.requestPermission().then((permission) => {
                        if (permission === 'granted') {
                            send();
                        } else {
                            console.warn(
                                'Failed while trying send browser notification.'
                            );
                        }
                    });
                } else {
                    console.warn('Access to browser notifications denied.');
                }
            }

            onSuccess?.(res);
        }, onError);
};

export const uploadFileAsync = makePromisable(uploadFile, null);
