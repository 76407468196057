import { OrganizationId } from 'api/organizations/organizations.types';
import { get, postWithToast, putWithToast } from '../../utils/fetch';
import * as Types from './courses.types';
import { getCurrentOrganizationId } from 'context/User';
import { AssignedCourse } from './courses.types';
import { OptionsGenerator } from 'ui/Select/Select.types';
import { string } from 'prop-types';

export const createCourse = (props: Types.CreateCourseFormData) =>
    postWithToast('Course/CreateCourse', props);

export const getCourses = (
    search?: string,
    organizationId: OrganizationId = getCurrentOrganizationId()
) =>
    get<Types.CoursesResponse>(`Course/GetCoursesByOrgId/${organizationId}`, {
        search
    });

export const getCoursesOptions: OptionsGenerator<typeof getCourses, number> = (
    ...args
) =>
    getCourses(...args).then(({ items }) =>
        items.map((item) => ({
            label:
                item.title ??
                (item.id ? `ID: ${item.id}` : 'Неопознанный курс'),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            value: item.id!
        }))
    );

export const getCourseById = (courseId: number) =>
    get<Types.CourseInfo>(`Course/${courseId}`);

export const editCourse = (props: Types.CreateCourseFormData) =>
    putWithToast<Types.CreateCourseFormData>('Course/UpdateCourse', props);

export const assignCourse = (props: Types.AssignCourseFormData) =>
    postWithToast('AssignCourse/AddAssignCourse', {
        ...props,
        dateStartLearning: props.dateStartLearning
            ? `${props.dateStartLearning}` + 'T00:00:00.000Z'
            : undefined,
        dateEndLearning: props.dateStartLearning
            ? `${props.dateStartLearning}` + 'T00:00:00.000Z'
            : string
    });

export const getCourseDateEndLearning = (courseId: number, startDate: string) =>
    get('AssignCourse/GetDateEndLearning', { courseId, startDate });

export const getAssignedCourses = (
    _search?: string,
    organizationId: OrganizationId = getCurrentOrganizationId()
) =>
    get<AssignedCourse[]>(
        `AssignCourse/GetOrganizationAssigns/${organizationId}`
    );

export const getCourseAssignment = (id: number) =>
    get<AssignedCourse>(`AssignCourse/${id}`);
