import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { PowerTable } from 'ui/PowerTable';
import {
    deleteCommittees,
    getOrganizationCommittees
} from 'api/committees/committees';
import { ColumnProps, TableProps } from 'ui/Table';
import { CommitteesInfo } from 'api/committees/committees.types';
import { RouterPaths } from 'helpers/router-paths';
import { TitleCell, CountContainer } from './Comissions.styled';
import { DeleteWordsTuple } from 'ui/PowerTable/PowerTable.types';

const COMISSIONS_COLUMNS: ColumnProps<CommitteesInfo> = {
    title: 'Название комиссии'
};

const parseColumns: TableProps<CommitteesInfo>['parseColumns'] = {
    title: (_, row) => (
        <TitleCell>
            <div>{row.title}</div>
            <CountContainer>
                Количество участников: {row.memberCount}
            </CountContainer>
        </TitleCell>
    )
};

const deleteWords: DeleteWordsTuple = ['комиссий', 'комиссию', 'комиссии'];

const ComissionsList = () => (
    <PowerTable
        title="Список комиссий"
        getter={getOrganizationCommittees}
        columns={COMISSIONS_COLUMNS}
        noRowsMsg="Комиссии не найдены"
        search={false}
        showHead={false}
        parseColumns={parseColumns}
        linkGetter={RouterPaths.ComissionPage}
        onDelete={deleteCommittees}
        deleteWords={deleteWords}
    />
);

export default withAuth(withContainer(ComissionsList));
