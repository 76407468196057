import { CurrentOrganizationData } from 'api/organizations/organizations.types';
import { get, putWithToast } from '../../utils/fetch';
import { SetPasswordProps, UserInfo } from './users.types';
import { UpdatePasswordModel } from 'api/api';

export const getCurrentUser = () => get<UserInfo>('User/Current');

export const getCurrentOrganization = () =>
    get<CurrentOrganizationData>('Organization/Current');

export const setPassword = (props: UpdatePasswordModel) =>
    putWithToast<SetPasswordProps>('User/UpdatePassword', props);
