import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import { Form } from 'ui/FormContainer/FormContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { generateCertificateOfStudy } from 'api/documents/documents';
import { makeCreateFormFunc } from 'utils/api';

import { FormRow } from 'ui/Form/Form.styled';
import { SelectField } from 'ui/Select';
import { getProfessionsTitleOptions } from 'api/professions/professions';
import * as S from '../Documents.styled';
import { CertificateEducationFormData } from 'api/documents/documents.types';
import { GenerateWordField, STATIC_DOCUMENTS_PROPS } from '../constants';
import { OrganizationTitleField } from 'components/fields/OrganizationField';
import { getStudentsOptions } from 'api/students/students';

type Data = CertificateEducationFormData;

const Field = FormField<Data>;
const Select = SelectField<Data>;

const handleFormSubmit = makeCreateFormFunc(
    generateCertificateOfStudy,
    'Файл загружен'
);

const CertificateOfStudyForm = () => {
    return (
        <Form onSubmit={handleFormSubmit}>
            <Headline>Справка об обучении</Headline>
            <S.DocumentSectionTitle>
                Кому предоставляется
            </S.DocumentSectionTitle>
            <FormRow>
                <Field name="jobTitle" label="Должность" />
                <OrganizationTitleField />
            </FormRow>
            <FormRow>
                <Field name="surnameRecipient" label="Фамилия" />
                <Field name="nameRecipient" label="Имя" />
                <Field name="lastnameRecipient" label="Отчество" />
            </FormRow>
            <S.DocumentSectionTitle>О курсе</S.DocumentSectionTitle>
            <FormRow>
                <Field
                    name="outgoingNumber"
                    label="Исходящий номер письма в СПС"
                />
                <Field name="incomingNumber" label="Номер входящего письма" />
            </FormRow>

            <Select
                name="courseTitle"
                label="Название профессии (из справочника)"
                placeholder="Выберите профессию"
                loader={getProfessionsTitleOptions}
                onSearch={getProfessionsTitleOptions}
                required
            />
            <Field name="qualification" label="Разряд" />
            <Field
                name="outgoingDate"
                label="Дата начала обучения"
                type="date"
            />
            <Field
                name="incomingDate"
                label="Дата окончания обучения"
                type="date"
            />
            <Select
                name="studentId"
                multiple
                label="Студенты"
                loader={getStudentsOptions}
                required
            />
            <S.DocumentSectionTitle>Протокол</S.DocumentSectionTitle>
            <FormRow>
                <Field name="protocol" label="Номер протокола" />
            </FormRow>

            <GenerateWordField />
        </Form>
    );
};

export default withAuth(
    withContainer(CertificateOfStudyForm, STATIC_DOCUMENTS_PROPS)
);
