/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type AddDocumentTemplateModel = {
    /** @format int64 */
    organizationId?: number | null;
    documentType?: DocumentType;
    extendedDocumentType?: ExtendedDocumentType;
    file?: DbFileModel;
}

export type Address = {
    postalCode?: string | null;
    region?: string | null;
    city?: string | null;
    street?: string | null;
    house?: string | null;
    flat?: string | null;
}

export type AddressListResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: Address[] | null;
}

export type AssignCourseModel = {
    /** @format int64 */
    id?: number;
    /** @format date-time */
    dateStartLearning?: string;
    /** @format date-time */
    dateEndLearning?: string;
    student?: StudentModel;
    course?: CourseModel;
}

export type AssignCourseModelListResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: AssignCourseModel[] | null;
}

export type AssignCourseModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: AssignCourseModel;
}

export enum AssignedQualificationType {
    MayBeAdmitted = 'MayBeAdmitted',
    PersonnelCategory = 'PersonnelCategory',
    Rank = 'Rank'
}

export type AuthModel = {
    login?: string | null;
    password?: string | null;
}

export type CertificateDocModel = {
    surname?: string | null;
    name?: string | null;
    lastname?: string | null;
    /** @format date-time */
    dateStart?: string | null;
    /** @format date-time */
    dateEnd?: string | null;
    /** @format int32 */
    hoursCount?: number | null;
    course?: string | null;
    number?: string | null;
    image?: DbFileModel;
}

export type CertificateDocModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: CertificateDocModel;
}

export type CertificateEducationDocModel = {
    extensionType?: FileExtensionType;
    jobTitle?: string | null;
    organizationTitle?: string | null;
    surnameRecipient?: string | null;
    nameRecipient?: string | null;
    lastnameRecipient?: string | null;
    outgoingNumber?: string | null;
    /** @format date-time */
    outgoingDate?: string | null;
    incomingNumber?: string | null;
    /** @format date-time */
    incomingDate?: string | null;
    /** @format uuid */
    studentId?: string | null;
    courseTitle?: string | null;
    qualification?: string | null;
    protocol?: string | null;
}

export type CertificateEducationDocModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: CertificateEducationDocModel;
}

export type CertificateThemeModel = {
    /** @format int64 */
    id?: number;
    title?: string | null;
}

export type CertificateThemeModelListResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: CertificateThemeModel[] | null;
}

export type CommitteeModel = {
    /** @format int64 */
    id?: number;
    title?: string | null;
    members?: MemberCommitteeModel[] | null;
}

export type CommitteeModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: CommitteeModel;
}

export type CommitteeShortModel = {
    /** @format int64 */
    id?: number;
    title?: string | null;
    /** @format int32 */
    memberCount?: number;
}

export type CommitteeShortModelListResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: CommitteeShortModel[] | null;
}

export type ContractDocModel = {
    extensionType?: FileExtensionType;
    number?: string | null;
    /** @format date-time */
    dateStart?: string | null;
    /** @format date-time */
    dateEnd?: string | null;
    organizationTitle?: string | null;
    jobTitle?: string | null;
    surname?: string | null;
    name?: string | null;
    lastname?: string | null;
    reason?: string | null;
}

export type ContractDocModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: ContractDocModel;
}

export type CourseModel = {
    /** @format int64 */
    id?: number;
    title?: string | null;
    subTitle?: string | null;
    trainingType?: TrainingType;
    assignedQualification?: AssignedQualificationType;
    disciplines?: DisciplineModel[] | null;
    /** @format int32 */
    hoursCount?: number;
    issuedDocuments?: DocumentType[] | null;
    /** @format int64 */
    committeeId?: number;
    /** @format int32 */
    validityPeriodDocument?: number | null;
    /** @format double */
    price?: number;
    /** @format int32 */
    rank?: number | null;
    profession?: ProfessionModel;
    /** @format int64 */
    organizationId?: number;
}

export type CourseModelPagedResult = {
    items?: CourseModel[] | null;
    /** @format int32 */
    totalCount?: number;
    /** @format int32 */
    pageNumber?: number;
    /** @format int32 */
    pageSize?: number;
}

export type CourseModelPagedResultResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: CourseModelPagedResult;
}

export type CourseModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: CourseModel;
}

export type CreateAssignCourseModel = {
    /** @format date-time */
    dateStartLearning?: string | null;
    /** @format date-time */
    dateEndLearning?: string | null;
    /** @format uuid */
    studentId?: string | null;
    /** @format int64 */
    courseId?: number | null;
}

export type CreateCertificateDocModel = {
    extensionType?: FileExtensionType;
    surname?: string | null;
    name?: string | null;
    lastname?: string | null;
    /** @format date-time */
    dateStart?: string | null;
    /** @format date-time */
    dateEnd?: string | null;
    /** @format int32 */
    hoursCount?: number | null;
    course?: string | null;
    number?: string | null;
    image?: DbFileModel;
    /** @format int64 */
    fromOrganizationId?: number | null;
}

export type CreateCommitteeModel = {
    /** @format int64 */
    organizationId?: number | null;
    title?: string | null;
    members?: MemberCommitteeModel[] | null;
}

export type CreateCourseModel = {
    title?: string | null;
    subTitle?: string | null;
    trainingType?: TrainingType;
    assignedQualification?: AssignedQualificationType;
    disciplineIds?: number[] | null;
    issuedDocuments?: DocumentType[] | null;
    /** @format int64 */
    committeeId?: number | null;
    /** @format int32 */
    validityPeriodDocument?: number | null;
    /** @format double */
    price?: number | null;
    /** @format int32 */
    rank?: number | null;
    /** @format int64 */
    organizationId?: number | null;
    /** @format int64 */
    professionId?: number | null;
}

export type CreateDiplomDocModel = {
    extensionType?: FileExtensionType;
    series?: string | null;
    number?: string | null;
    /** @format date-time */
    dateStart?: string | null;
    /** @format date-time */
    dateEnd?: string | null;
    course?: string | null;
    /** @format date-time */
    licenseDate?: string | null;
    regNumber?: string | null;
    /** @format int32 */
    hoursCount?: number | null;
    appendixOne?: DiplomAppendixOne;
    appendixTwo?: DiplomAppendixTwo;
    /** @format int64 */
    assignId?: number | null;
    /** @format int64 */
    fromOrganizationId?: number | null;
}

export type CreateDisciplineModel = {
    /** @format int64 */
    disciplineListId?: number | null;
    title?: string | null;
    /** @format int32 */
    hoursCount?: number | null;
    type?: DisciplineType;
}

export type DateTimeResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    /** @format date-time */
    data?: string;
}

export type DbFileModel = {
    /** @format uuid */
    id?: string;
    extention?: string | null;
    size?: string | null;
    url?: string | null;
    fullName?: string | null;
}

export type DbFileModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: DbFileModel;
}

export type DiplomAppendixOne = {
    educationType?: EducationType;
    series?: string | null;
    number?: string | null;
    themeTitle?: string | null;
}

export type DiplomAppendixTwo = {
    disciplines?: DisciplineWithGradeModel[] | null;
}

export type DiplomDocModel = {
    extensionType?: FileExtensionType;
    series?: string | null;
    number?: string | null;
    surname?: string | null;
    name?: string | null;
    lastname?: string | null;
    /** @format date-time */
    dateStart?: string | null;
    /** @format date-time */
    dateEnd?: string | null;
    course?: string | null;
    /** @format date-time */
    licenseDate?: string | null;
    regNumber?: string | null;
    /** @format int32 */
    hoursCount?: number | null;
    /** @format date-time */
    birthday?: string | null;
    appendixOne?: DiplomAppendixOne;
    appendixTwo?: DiplomAppendixTwo;
}

export type DiplomDocModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: DiplomDocModel;
}

export enum DisciplineGradeType {
    Excelent = 'Excelent',
    Good = 'Good',
    Accepted = 'Accepted'
}

export type DisciplineListModel = {
    /** @format int64 */
    id?: number;
    items?: DisciplineModel[] | null;
}

export type DisciplineListModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: DisciplineListModel;
}

export type DisciplineModel = {
    /** @format int64 */
    id?: number;
    title?: string | null;
    /** @format int32 */
    hoursCount?: number;
    type?: DisciplineType;
}

export type DisciplineModelListResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: DisciplineModel[] | null;
}

export type DisciplineModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: DisciplineModel;
}

export enum DisciplineType {
    PO = 'PO',
    PK = 'PK',
    PP = 'PP'
}

export type DisciplineWithGradeModel = {
    /** @format int64 */
    id?: number;
    title?: string | null;
    /** @format int32 */
    hoursCount?: number | null;
    type?: DisciplineType;
    gradeType?: DisciplineGradeType;
}

export enum DocumentType {
    Diplom = 'Diplom',
    Certificate = 'Certificate',
    TicketB = 'TicketB',
    Contract = 'Contract',
    CertificateEducation = 'CertificateEducation',
    TicketM = 'TicketM'
}

export type DocumentTypeListResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: DocumentType[] | null;
}

export enum EducationType {
    High = 'High',
    MiddleProfessional = 'MiddleProfessional',
    MiddleCommon = 'MiddleCommon'
}

export enum ExtendedDocumentType {
    DiplomMain = 'DiplomMain',
    DiplomMainForPdf = 'DiplomMainForPdf',
    AppendixOne = 'AppendixOne',
    AppendixOneForPdf = 'AppendixOneForPdf',
    AppendixTwo = 'AppendixTwo',
    AppendixTwoForPdf = 'AppendixTwoForPdf',
    Certificate = 'Certificate',
    CertificateForPdf = 'CertificateForPdf',
    TicketB = 'TicketB',
    TicketBForPdf = 'TicketBForPdf',
    Contract = 'Contract',
    ContractForPdf = 'ContractForPdf',
    CertificateEducation = 'CertificateEducation',
    CertificateEducationForPdf = 'CertificateEducationForPdf',
    TicketMOne = 'TicketMOne',
    TicketMOneForPdf = 'TicketMOneForPdf',
    TicketMTwo = 'TicketMTwo',
    TicketMTwoForPdf = 'TicketMTwoForPdf',
    TicketMThree = 'TicketMThree',
    TicketMThreeForPdf = 'TicketMThreeForPdf',
    TicketMFour = 'TicketMFour',
    TicketMFourForPdf = 'TicketMFourForPdf'
}

export enum FileExtensionType {
    Docx = 'Docx',
    Pdf = 'Pdf'
}

export type Int64ResponseCodeEnumDictionaryResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: Record<string, ResponseCodeEnum>;
}

export type InvalidFieldModel = {
    code?: ValidationCodeEnum;
    message?: string | null;
    props?: Record<string, string | null>;
}

export type InvalidResponseModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    fields?: Record<string, InvalidFieldModel[]>;
}

export enum JobTitleMemberCommitteeType {
    Chairman = 'Chairman',
    MemberCommittee = 'MemberCommittee',
    SubChairman = 'SubChairman'
}

export type LogPasModel = {
    login?: string | null;
    password?: string | null;
}

export type LogPasModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: LogPasModel;
}

export type LogPasModelResponseWithDataModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: LogPasModelResponseWithDataModel;
}

export type MemberCommitteeModel = {
    fullName?: string | null;
    jobTitleMemberCommitee?: JobTitleMemberCommitteeType;
}

export type OperatorModel = {
    /** @format uuid */
    id?: string;
    /** @format int64 */
    currentOrganizationId?: number;
    user?: UserModel;
}

export type OperatorModelOrganizationModelValueTuple = object;

export type OperatorModelOrganizationModelValueTupleResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: OperatorModelOrganizationModelValueTuple;
}

export type OperatorModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: OperatorModel;
}

export type OperatorModelWithoutUser = {
    /** @format uuid */
    id?: string;
    organization?: OrganizationModel;
}

export type OperatorShortModel = {
    /** @format uuid */
    id?: string;
    fullName?: string | null;
    email?: string | null;
}

export type OperatorShortModelPagedResult = {
    items?: OperatorShortModel[] | null;
    /** @format int32 */
    totalCount?: number;
    /** @format int32 */
    pageNumber?: number;
    /** @format int32 */
    pageSize?: number;
}

export type OperatorShortModelPagedResultResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: OperatorShortModelPagedResult;
}

export type OrganizationModel = {
    /** @format int64 */
    id?: number;
    title?: string | null;
    phone?: string | null;
    email?: string | null;
    address?: Address;
    bankAccountNumber?: string | null;
    inn?: string | null;
    organizationType?: OrganizationType;
    /** @format int64 */
    disciplineListId?: number;
}

export type OrganizationModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: OrganizationModel;
}

export type OrganizationShortModel = {
    /** @format int64 */
    id?: number;
    title?: string | null;
    inn?: string | null;
    organizationType?: OrganizationType;
}

export type OrganizationShortModelPagedResult = {
    items?: OrganizationShortModel[] | null;
    /** @format int32 */
    totalCount?: number;
    /** @format int32 */
    pageNumber?: number;
    /** @format int32 */
    pageSize?: number;
}

export type OrganizationShortModelPagedResultResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: OrganizationShortModelPagedResult;
}

export enum OrganizationType {
    Customer = 'Customer',
    EducationCenter = 'EducationCenter'
}

export type ProfessionModel = {
    /** @format int64 */
    id?: number;
    title?: string | null;
    number?: string | null;
}

export type ProfessionModelListResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: ProfessionModel[] | null;
}

export type RegisterOperatorModel = {
    name?: string | null;
    surname?: string | null;
    lastName?: string | null;
    phone?: string | null;
    email?: string | null;
    password?: string | null;
    confirmPassword?: string | null;
    /** @format int64 */
    organizationId?: number | null;
}

export type RegisterOrganizationAndOperatorModel = {
    organization?: RegisterOrganizationModel;
    operator?: RegisterOperatorModel;
}

export type RegisterOrganizationModel = {
    title?: string | null;
    phone?: string | null;
    email?: string | null;
    bankAccountNumber?: string | null;
    inn?: string | null;
    /** @format int64 */
    disciplineListId?: number | null;
    address?: Address;
}

export type RegisterStudentModelForOper = {
    name?: string | null;
    surname?: string | null;
    lastName?: string | null;
    email?: string | null;
    password?: string | null;
    confirmPassword?: string | null;
    phone?: string | null;
    /** @format date-time */
    birthday?: string | null;
    placeWork?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    snilsNumber?: string | null;
    diplomNumber?: string | null;
    qualification?: string | null;
    /** @format int64 */
    organizationId?: number | null;
    /** @format int32 */
    rank?: number | null;
    educationType?: EducationType;
    jobTitle?: string | null;
    address?: Address;
    avatar?: DbFileModel;
    passport?: DbFileModel;
    snils?: DbFileModel;
    educationDocument?: DbFileModel;
    qualificationDocument?: DbFileModel;
}

export enum ResponseCodeEnum {
    Ok = 'Ok',
    Forbidden = 'Forbidden',
    BadRequest = 'BadRequest',
    NotRelatedStudent = 'NotRelatedStudent',
    AuthException = 'AuthException',
    ExpiredEmailToken = 'ExpiredEmailToken',
    EmailAlreadyUse = 'EmailAlreadyUse',
    SendMailError = 'SendMailError',
    EmailUnconfirmed = 'EmailUnconfirmed',
    PhoneAlreadyUse = 'PhoneAlreadyUse',
    CanNotBeRegenerate = 'CanNotBeRegenerate',
    MoodleLogPassNotGenerate = 'MoodleLogPassNotGenerate',
    HasRefsEntities = 'HasRefsEntities',
    OldPasswordNotEqualCurrent = 'OldPasswordNotEqualCurrent',
    IsNotEducationCenter = 'IsNotEducationCenter',
    Invalid = 'Invalid',
    Unauthorized = 'Unauthorized',
    InvalidToken = 'InvalidToken',
    NotFound = 'NotFound',
    NotFoundStudent = 'NotFoundStudent',
    NotFoundOrganization = 'NotFoundOrganization',
    NotFoundOperator = 'NotFoundOperator',
    UserNotFound = 'UserNotFound',
    EmailConfirmNotFound = 'EmailConfirmNotFound',
    NotFoundCommittee = 'NotFoundCommittee',
    NotFoundMemberCommittee = 'NotFoundMemberCommittee',
    NotFoundDisciplineList = 'NotFoundDisciplineList',
    NotFoundDiscipline = 'NotFoundDiscipline',
    NotFoundCourse = 'NotFoundCourse',
    NotFoundDisciplines = 'NotFoundDisciplines',
    NotFoundFileEntry = 'NotFoundFileEntry',
    NotFoundFile = 'NotFoundFile',
    NotFoundDirectory = 'NotFoundDirectory',
    NotFoundAssignCourse = 'NotFoundAssignCourse',
    NotFoundDocumentTemplate = 'NotFoundDocumentTemplate',
    ServerError = 'ServerError',
    SaveFileException = 'SaveFileException',
    RemoveFileException = 'RemoveFileException',
    RemoveCommitteeException = 'RemoveCommitteeException',
    RemoveCourseException = 'RemoveCourseException',
    RemoveDisciplineException = 'RemoveDisciplineException'
}

export type ResponseModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
}

export type StudentDetailModel = {
    /** @format uuid */
    id?: string;
    fullName?: string | null;
    /** @format date-time */
    birthday?: string | null;
    phone?: string | null;
    email?: string | null;
    avatar?: DbFileModel;
}

export type StudentDetailModelListResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: StudentDetailModel[] | null;
}

export type StudentDetailModelPagedResult = {
    items?: StudentDetailModel[] | null;
    /** @format int32 */
    totalCount?: number;
    /** @format int32 */
    pageNumber?: number;
    /** @format int32 */
    pageSize?: number;
}

export type StudentDetailModelPagedResultResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: StudentDetailModelPagedResult;
}

export type StudentModel = {
    /** @format uuid */
    id?: string;
    user?: UserModel;
    /** @format date-time */
    birthday?: string;
    placeWork?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    snilsNumber?: string | null;
    diplomNumber?: string | null;
    qualification?: string | null;
    address?: Address;
    /** @format int32 */
    rank?: number | null;
    educationType?: EducationType;
    jobTitle?: string | null;
    avatar?: DbFileModel;
    passport?: DbFileModel;
    snils?: DbFileModel;
    educationalDocument?: DbFileModel;
    qualificationDocument?: DbFileModel;
    organization?: OrganizationModel;
}

export type StudentModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: StudentModel;
}

export type StudentModelWithoutUser = {
    /** @format uuid */
    id?: string;
    phoneNumber?: string | null;
    /** @format date-time */
    birthday?: string;
    placeWork?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    snilsNumber?: string | null;
    diplomNumber?: string | null;
    qualification?: string | null;
    /** @format int32 */
    rank?: number | null;
    educationType?: EducationType;
    jobTitle?: string | null;
    address?: Address;
    avatar?: DbFileModel;
    passport?: DbFileModel;
    snils?: DbFileModel;
    educationalDocument?: DbFileModel;
    qualificationDocument?: DbFileModel;
    organization?: OrganizationModel;
}

export type StudentShortModel = {
    /** @format uuid */
    id?: string;
    fullName?: string | null;
    organizationTitle?: string | null;
}

export type StudentShortModelPagedResult = {
    items?: StudentShortModel[] | null;
    /** @format int32 */
    totalCount?: number;
    /** @format int32 */
    pageNumber?: number;
    /** @format int32 */
    pageSize?: number;
}

export type StudentShortModelPagedResultResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: StudentShortModelPagedResult;
}

export type TicketBModel = {
    extensionType?: FileExtensionType;
    surname?: string | null;
    name?: string | null;
    lastname?: string | null;
    /** @format date-time */
    dateStart?: string | null;
    /** @format date-time */
    dateEnd?: string | null;
    profession?: string | null;
    /** @format int32 */
    hoursCount?: number | null;
    number?: string | null;
}

export type TicketBModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: TicketBModel;
}

export type TicketMModel = {
    extensionType?: FileExtensionType;
    protocolNumber?: string | null;
    /** @format date-time */
    dateStart?: string | null;
    /** @format date-time */
    dateEnd?: string | null;
    /** @format int64 */
    courseId?: number | null;
    students?: string[] | null;
    commissionConclusion?: string | null;
}

export type TicketMModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: TicketMModel;
}

export enum TrainingType {
    ProfessionalTraining = 'ProfessionalTraining', //ПО
    AdvancedTraining = 'AdvancedTraining', //ПК
    ProfessionalRetraining = 'ProfessionalRetraining'//ПП 
}

export type UpdateCommitteeModel = {
    /** @format int64 */
    id?: number | null;
    title?: string | null;
    members?: MemberCommitteeModel[] | null;
}

export type UpdateCourseModel = {
    /** @format int64 */
    id?: number | null;
    title?: string | null;
    subTitle?: string | null;
    trainingType?: TrainingType;
    assignedQualification?: AssignedQualificationType;
    disciplineIds?: number[] | null;
    issuedDocuments?: DocumentType[] | null;
    /** @format int64 */
    committeeId?: number | null;
    /** @format int32 */
    validityPeriodDocument?: number | null;
    /** @format double */
    price?: number | null;
    /** @format int32 */
    rank?: number | null;
    /** @format int64 */
    professionId?: number | null;
}

export type UpdateOperatorModel = {
    name?: string | null;
    surname?: string | null;
    lastname?: string | null;
    email?: string | null;
    phone?: string | null;
}

export type UpdateOrganizationModel = {
    /** @format int64 */
    id?: number | null;
    title?: string | null;
    phone?: string | null;
    email?: string | null;
    bankAccountNumber?: string | null;
    inn?: string | null;
    /** @format uuid */
    contactOperatorId?: string | null;
    /** @format int64 */
    disciplineListId?: number | null;
    address?: Address;
}

export type UpdatePasswordModel = {
    oldPassword?: string | null;
    password?: string | null;
    confirmPassword?: string | null;
}

export type UpdateStudentModel = {
    /** @format uuid */
    id?: string | null;
    phone?: string | null;
    /** @format date-time */
    birthday?: string | null;
    plaseWork?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    snilsNumber?: string | null;
    diplomNumber?: string | null;
    qualification?: string | null;
    name?: string | null;
    surname?: string | null;
    lastname?: string | null;
    email?: string | null;
    /** @format int32 */
    rank?: number | null;
    /** @format int64 */
    organizationId?: number | null;
    educationType?: EducationType;
    jobTitle?: string | null;
    address?: Address;
    avatar?: DbFileModel;
    passport?: DbFileModel;
    snils?: DbFileModel;
    educationalDocument?: DbFileModel;
    qualificationDocument?: DbFileModel;
}

export type UpdateStudentModelForOper = {
    /** @format uuid */
    id?: string | null;
    phone?: string | null;
    /** @format date-time */
    birthday?: string | null;
    plaseWork?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    snilsNumber?: string | null;
    diplomNumber?: string | null;
    qualification?: string | null;
    name?: string | null;
    surname?: string | null;
    lastname?: string | null;
    email?: string | null;
    /** @format int32 */
    rank?: number | null;
    /** @format int64 */
    organizationId?: number | null;
    educationType?: EducationType;
    jobTitle?: string | null;
    address?: Address;
    avatar?: DbFileModel;
    passport?: DbFileModel;
    snils?: DbFileModel;
    educationalDocument?: DbFileModel;
    qualificationDocument?: DbFileModel;
}

export type UserModel = {
    /** @format uuid */
    id?: string;
    userType?: UserType;
    name?: string | null;
    surname?: string | null;
    lastname?: string | null;
    fullName?: string | null;
    email?: string | null;
    phone?: string | null;
    /** @format date-time */
    lastLogin?: string | null;
    isEmailConfirmed?: boolean;
    isActive?: boolean;
    operator?: OperatorModelWithoutUser;
    student?: StudentModelWithoutUser;
}

export type UserModelResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: UserModel;
}

export enum UserType {
    Student = 'Student',
    Operator = 'Operator',
    Undefined = 'Undefined'
}

export type UserTypeResponseWithDataModel = {
    ok?: boolean;
    code?: ResponseCodeEnum;
    data?: UserType;
}

export enum ValidationCodeEnum {
    MinLength = 'MinLength',
    IncorrectFormat = 'IncorrectFormat',
    CanNotBeEmpty = 'CanNotBeEmpty',
    OnlyDigit = 'OnlyDigit',
    MaxLength = 'MaxLength',
    OneUpperCase = 'OneUpperCase',
    OneLowerCase = 'OneLowerCase',
    IncorrectPhoneFormat = 'IncorrectPhoneFormat',
    IncorrectLengthRow = 'IncorrectLengthRow',
    ConfirmNotEqualPassword = 'ConfirmNotEqualPassword',
    InvalidExtentionFile = 'InvalidExtentionFile',
    MaxSizeFile10Mb = 'MaxSizeFile10mb',
    DateStartMoreDateEnd = 'DateStartMoreDateEnd',
    OldEqualNewPassword = 'OldEqualNewPassword'
}

export namespace AssignCourse {
    /**
     * No description
     * @tags AssignCourse
     * @name AddAssignCourseCreate
     * @summary Назначение курса
     * @request POST:/AssignCourse/AddAssignCourse
     * @secure
     */
    export namespace AddAssignCourseCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = CreateAssignCourseModel;
        export type RequestHeaders = {};
        export type ResponseBody = AssignCourseModelResponseWithDataModel;
    }

    /**
     * @description Для оператора метод
     * @tags AssignCourse
     * @name GetAssignsDetail
     * @summary Получение назначенных курсов у студента
     * @request GET:/AssignCourse/GetAssigns/{studentId}
     * @secure
     */
    export namespace GetAssignsDetail {
        export type RequestParams = {
            /** @format uuid */
            studentId: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = AssignCourseModelListResponseWithDataModel;
    }

    /**
     * @description Для оператора метод
     * @tags AssignCourse
     * @name GetAssignsCurrentList
     * @summary Получение назначенных курсов у текущего студента
     * @request GET:/AssignCourse/GetAssigns/Current
     * @secure
     */
    export namespace GetAssignsCurrentList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = AssignCourseModelListResponseWithDataModel;
    }

    /**
     * No description
     * @tags AssignCourse
     * @name AssignCourseDetail
     * @summary Получение назначенного курса по Id
     * @request GET:/AssignCourse/{assignId}
     * @secure
     */
    export namespace AssignCourseDetail {
        export type RequestParams = {
            /** @format int64 */
            assignId: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = AssignCourseModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags AssignCourse
     * @name GetOrganizationAssignsDetail
     * @summary Получение списка назначенных курсов в организации
     * @request GET:/AssignCourse/GetOrganizationAssigns/{organizationId}
     * @secure
     */
    export namespace GetOrganizationAssignsDetail {
        export type RequestParams = {
            /** @format int64 */
            organizationId: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = AssignCourseModelListResponseWithDataModel;
    }

    /**
     * No description
     * @tags AssignCourse
     * @name GetOrganizationAssignsCurrentList
     * @summary Получение списка назначенных курсов в текущей организации
     * @request GET:/AssignCourse/GetOrganizationAssigns/Current
     * @secure
     */
    export namespace GetOrganizationAssignsCurrentList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = AssignCourseModelListResponseWithDataModel;
    }

    /**
     * No description
     * @tags AssignCourse
     * @name GetDateEndLearningList
     * @summary Получение даты окончания курса
     * @request GET:/AssignCourse/GetDateEndLearning
     * @secure
     */
    export namespace GetDateEndLearningList {
        export type RequestParams = {};
        export type RequestQuery = {
            /** @format int64 */
            CourseId?: number;
            /** @format date-time */
            StartDate?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DateTimeResponseWithDataModel;
    }

    /**
     * No description
     * @tags AssignCourse
     * @name GetStudentsByCourseDetail
     * @summary Получение назначенных студентов на курс
     * @request GET:/AssignCourse/GetStudentsByCourse/{courseId}
     * @secure
     */
    export namespace GetStudentsByCourseDetail {
        export type RequestParams = {
            courseId: string;
        };
        export type RequestQuery = {
            /** @format int64 */
            courseId?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = StudentDetailModelListResponseWithDataModel;
    }

    /**
     * No description
     * @tags AssignCourse
     * @name GetCourseAssignsDetail
     * @summary Получение назначений определенного курса
     * @request GET:/AssignCourse/GetCourseAssigns/{courseId}
     * @secure
     */
    export namespace GetCourseAssignsDetail {
        export type RequestParams = {
            courseId: string;
        };
        export type RequestQuery = {
            /** @format int64 */
            courseId?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = AssignCourseModelListResponseWithDataModel;
    }
}

export namespace CertificateTheme {
    /**
     * No description
     * @tags CertificateTheme
     * @name GetThemesList
     * @summary Получение списка аттестационных тем
     * @request GET:/CertificateTheme/GetThemes
     * @secure
     */
    export namespace GetThemesList {
        export type RequestParams = {};
        export type RequestQuery = {
            search?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CertificateThemeModelListResponseWithDataModel;
    }
}

export namespace Committee {
    /**
     * @description поле CommitteeId в Members заполнять не нужно
     * @tags Committee
     * @name CreateCommitteeCreate
     * @summary Создание комиссии/комитета
     * @request POST:/Committee/CreateCommittee
     * @secure
     */
    export namespace CreateCommitteeCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = CreateCommitteeModel;
        export type RequestHeaders = {};
        export type ResponseBody = CommitteeModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Committee
     * @name UpdateCommitteeUpdate
     * @summary Редактирование комиссии/комитета
     * @request PUT:/Committee/UpdateCommittee
     * @secure
     */
    export namespace UpdateCommitteeUpdate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = UpdateCommitteeModel;
        export type RequestHeaders = {};
        export type ResponseBody = CommitteeModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Committee
     * @name GetCommittiesByOrgIdDetail
     * @summary Получение списка комиссий по компании
     * @request GET:/Committee/GetCommittiesByOrgId/{organizationId}
     * @secure
     */
    export namespace GetCommittiesByOrgIdDetail {
        export type RequestParams = {
            /** @format int64 */
            organizationId: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CommitteeShortModelListResponseWithDataModel;
    }

    /**
     * No description
     * @tags Committee
     * @name GetCommittiesByOrgIdCurrentList
     * @summary Получение списка комиссий по current компании
     * @request GET:/Committee/GetCommittiesByOrgId/Current
     * @secure
     */
    export namespace GetCommittiesByOrgIdCurrentList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CommitteeShortModelListResponseWithDataModel;
    }

    /**
     * No description
     * @tags Committee
     * @name CommitteeDetail
     * @summary Получение полной модели комиссии
     * @request GET:/Committee/{committeeId}
     * @secure
     */
    export namespace CommitteeDetail {
        export type RequestParams = {
            /** @format int64 */
            committeeId: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CommitteeModelResponseWithDataModel;
    }

    /**
     * @description Удаление производится транзакционно. Если не удалось удалить одну, не удаляются все
     * @tags Committee
     * @name RemoveCommittiesDelete
     * @summary Удаление комиссий
     * @request DELETE:/Committee/RemoveCommitties
     * @secure
     */
    export namespace RemoveCommittiesDelete {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = number[];
        export type RequestHeaders = {};
        export type ResponseBody = Int64ResponseCodeEnumDictionaryResponseWithDataModel;
    }
}

export namespace Course {
    /**
     * No description
     * @tags Course
     * @name GetCoursesByOrgIdDetail
     * @summary Получение списка курсов в организации
     * @request GET:/Course/GetCoursesByOrgId/{organizationId}
     * @secure
     */
    export namespace GetCoursesByOrgIdDetail {
        export type RequestParams = {
            /** @format int64 */
            organizationId: number;
        };
        export type RequestQuery = {
            search?: string;
            /** @format int32 */
            PageNumber?: number;
            /** @format int32 */
            PageSize?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CourseModelPagedResultResponseWithDataModel;
    }

    /**
     * No description
     * @tags Course
     * @name GetCoursesByOrgIdCurrentList
     * @summary Получение спсика курсов в current организации
     * @request GET:/Course/GetCoursesByOrgId/Current
     * @secure
     */
    export namespace GetCoursesByOrgIdCurrentList {
        export type RequestParams = {};
        export type RequestQuery = {
            search?: string;
            /** @format int32 */
            PageNumber?: number;
            /** @format int32 */
            PageSize?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CourseModelPagedResultResponseWithDataModel;
    }

    /**
     * No description
     * @tags Course
     * @name CourseDetail
     * @summary Получение курса по Id
     * @request GET:/Course/{courseId}
     * @secure
     */
    export namespace CourseDetail {
        export type RequestParams = {
            /** @format int64 */
            courseId: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CourseModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Course
     * @name CreateCourseCreate
     * @summary Добавление курса
     * @request POST:/Course/CreateCourse
     * @secure
     */
    export namespace CreateCourseCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = CreateCourseModel;
        export type RequestHeaders = {};
        export type ResponseBody = CourseModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Course
     * @name UpdateCourseUpdate
     * @summary Редактирование курса
     * @request PUT:/Course/UpdateCourse
     * @secure
     */
    export namespace UpdateCourseUpdate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = UpdateCourseModel;
        export type RequestHeaders = {};
        export type ResponseBody = CourseModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Course
     * @name RemoveCoursesDelete
     * @summary Удаление курса
     * @request DELETE:/Course/RemoveCourses
     * @secure
     */
    export namespace RemoveCoursesDelete {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = number[];
        export type RequestHeaders = {};
        export type ResponseBody = Int64ResponseCodeEnumDictionaryResponseWithDataModel;
    }

    /**
     * @description Государственный список названий профессий и их номеров
     * @tags Course
     * @name GetProfessionsList
     * @summary Получение списка названий курсов
     * @request GET:/Course/GetProfessions
     * @secure
     */
    export namespace GetProfessionsList {
        export type RequestParams = {};
        export type RequestQuery = {
            search?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ProfessionModelListResponseWithDataModel;
    }

    /**
     * No description
     * @tags Course
     * @name GetDocumentListDetail
     * @summary Получение списка выдаваемых документов по итогам курса
     * @request GET:/Course/GetDocumentList/{courseId}
     * @secure
     */
    export namespace GetDocumentListDetail {
        export type RequestParams = {
            /** @format int64 */
            courseId: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DocumentTypeListResponseWithDataModel;
    }
}

export namespace Dadata {
    /**
     * No description
     * @tags Dadata
     * @name SuggestAddressList
     * @summary Получение списка адресов по строке
     * @request GET:/Dadata/SuggestAddress
     * @secure
     */
    export namespace SuggestAddressList {
        export type RequestParams = {};
        export type RequestQuery = {
            row?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = AddressListResponseWithDataModel;
    }
}

export namespace Discipline {
    /**
     * No description
     * @tags Discipline
     * @name GetDisciplineListByOrgIdDetail
     * @summary Получение списка дисциплин связанный с организацией
     * @request GET:/Discipline/GetDisciplineListByOrgId/{organizationId}
     * @secure
     */
    export namespace GetDisciplineListByOrgIdDetail {
        export type RequestParams = {
            /** @format int64 */
            organizationId: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DisciplineListModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Discipline
     * @name GetDisciplineListByOrgIdCurrentList
     * @summary Получение списка дисциплин связанный с current организацией
     * @request GET:/Discipline/GetDisciplineListByOrgId/current
     * @secure
     */
    export namespace GetDisciplineListByOrgIdCurrentList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DisciplineListModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Discipline
     * @name AddDisciplineCreate
     * @summary Добавление дисциплины
     * @request POST:/Discipline/AddDiscipline
     * @secure
     */
    export namespace AddDisciplineCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = CreateDisciplineModel;
        export type RequestHeaders = {};
        export type ResponseBody = DisciplineModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Discipline
     * @name RemoveDisciplinesDelete
     * @summary Удаление дисциплины
     * @request DELETE:/Discipline/RemoveDisciplines
     * @secure
     */
    export namespace RemoveDisciplinesDelete {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = number[];
        export type RequestHeaders = {};
        export type ResponseBody = Int64ResponseCodeEnumDictionaryResponseWithDataModel;
    }

    /**
     * @description можно создать и добавить к организации
     * @tags Discipline
     * @name CreateTestDisciplineListCreate
     * @summary Добавляет пустой список дисциплин в бд, добавил для теста
     * @request POST:/Discipline/CreateTestDisciplineList
     * @secure
     */
    export namespace CreateTestDisciplineListCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DisciplineListModelResponseWithDataModel;
    }
}

export namespace Document {
    /**
     * No description
     * @tags Document
     * @name GetContractInfoList
     * @summary Информация для генерации документа
     * @request GET:/Document/GetContractInfo
     * @secure
     */
    export namespace GetContractInfoList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ContractDocModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Document
     * @name ContractCreate
     * @summary Получение файла договора
     * @request POST:/Document/Contract
     * @secure
     */
    export namespace ContractCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = ContractDocModel;
        export type RequestHeaders = {};
        export type ResponseBody = File;
    }

    /**
     * No description
     * @tags Document
     * @name GetCertificateEducationInfoList
     * @summary Получение информации для генерации справки об обучении
     * @request GET:/Document/GetCertificateEducationInfo
     * @secure
     */
    export namespace GetCertificateEducationInfoList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CertificateEducationDocModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Document
     * @name CertificateEducationCreate
     * @summary Получение документа справки об обучении
     * @request POST:/Document/CertificateEducation
     * @secure
     */
    export namespace CertificateEducationCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = CertificateEducationDocModel;
        export type RequestHeaders = {};
        export type ResponseBody = File;
    }

    /**
     * No description
     * @tags Document
     * @name GetTicketMInfoList
     * @summary Получение информации для генерации удостоверения М
     * @request GET:/Document/GetTicketMInfo
     * @secure
     */
    export namespace GetTicketMInfoList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = TicketMModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Document
     * @name TicketMCreate
     * @summary Получение документа справки об обучении
     * @request POST:/Document/TicketM
     * @secure
     */
    export namespace TicketMCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = TicketMModel;
        export type RequestHeaders = {};
        export type ResponseBody = File;
    }

    /**
     * No description
     * @tags Document
     * @name CertificateCreate
     * @summary Генерация файла сертификата
     * @request POST:/Document/Certificate
     * @secure
     */
    export namespace CertificateCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = CreateCertificateDocModel;
        export type RequestHeaders = {};
        export type ResponseBody = File;
    }

    /**
     * @description Нужно передать в метод Certificate
     * @tags Document
     * @name GetCertificateInfoDetail
     * @summary Получение информации для заполнения формы генерации сертификата
     * @request GET:/Document/GetCertificateInfo/{assignId}
     * @secure
     */
    export namespace GetCertificateInfoDetail {
        export type RequestParams = {
            /** @format int64 */
            assignId: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CertificateDocModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Document
     * @name DiplomCreate
     * @summary Получение документа по назначенному курсу
     * @request POST:/Document/Diplom
     * @secure
     */
    export namespace DiplomCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = CreateDiplomDocModel;
        export type RequestHeaders = {};
        export type ResponseBody = File;
    }

    /**
     * No description
     * @tags Document
     * @name GetDiplomInfoDetail
     * @summary Получение информации для заполнения формы генерации диплома
     * @request GET:/Document/GetDiplomInfo/{assignId}
     * @secure
     */
    export namespace GetDiplomInfoDetail {
        export type RequestParams = {
            /** @format int64 */
            assignId: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DiplomDocModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Document
     * @name TicketBCreate
     * @summary генерация файла удостоверения Б
     * @request POST:/Document/TicketB
     * @secure
     */
    export namespace TicketBCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = TicketBModel;
        export type RequestHeaders = {};
        export type ResponseBody = File;
    }

    /**
     * No description
     * @tags Document
     * @name GetTicketBInfoDetail
     * @summary Получение информации для заполнения формы генерации удостоверения Б
     * @request GET:/Document/GetTicketBInfo/{assignId}
     * @secure
     */
    export namespace GetTicketBInfoDetail {
        export type RequestParams = {
            /** @format int64 */
            assignId: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = TicketBModelResponseWithDataModel;
    }
}

export namespace DocumentTemplate {
    /**
     * No description
     * @tags DocumentTemplate
     * @name AddTemplateCreate
     * @summary Добавление шаблона
     * @request POST:/DocumentTemplate/AddTemplate
     * @secure
     */
    export namespace AddTemplateCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = AddDocumentTemplateModel;
        export type RequestHeaders = {};
        export type ResponseBody = ResponseModel;
    }
}

export namespace File {
    /**
     * No description
     * @tags File
     * @name FileDetail
     * @summary Получение файла
     * @request GET:/File/{id}
     * @secure
     */
    export namespace FileDetail {
        export type RequestParams = {
            /** @format uuid */
            id: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = File;
    }

    /**
     * No description
     * @tags File
     * @name SaveCreate
     * @summary сохранение файла
     * @request POST:/File/Save
     * @secure
     */
    export namespace SaveCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = {
            /** @format binary */
            file?: File;
        };
        export type RequestHeaders = {};
        export type ResponseBody = DbFileModelResponseWithDataModel;
    }
}

export namespace FillOrganizationAndOperator {
    /**
     * @description По умолчанию генерируется пароль юзера "Kursoved"
     * @tags FillDb
     * @name FillOrganizationAndOperatorList
     * @summary Имитирует метод Register/OrganizationAndOperator
     * @request GET:/FillOrganizationAndOperator
     * @secure
     */
    export namespace FillOrganizationAndOperatorList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = OperatorModelOrganizationModelValueTupleResponseWithDataModel;
    }
}

export namespace FillStudent {
    /**
     * @description По умолчанию генерируется пароль юзера "Kursoved"
     * @tags FillDb
     * @name FillStudentList
     * @summary Имитирует метод Operator/RegisterStudentForOperator
     * @request GET:/FillStudent
     * @secure
     */
    export namespace FillStudentList {
        export type RequestParams = {};
        export type RequestQuery = {
            /** @format int64 */
            orgId?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = StudentModelResponseWithDataModel;
    }
}

export namespace FillCourse {
    /**
     * No description
     * @tags FillDb
     * @name FillCourseList
     * @summary Имитирует метод Course/CreateCourse
     * @request GET:/FillCourse
     * @secure
     */
    export namespace FillCourseList {
        export type RequestParams = {};
        export type RequestQuery = {
            /** @format int64 */
            orgId?: number;
            /** @format int64 */
            proffId?: number;
            /** @format int64 */
            committieId?: number;
            disciplineIds?: number[];
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CourseModelResponseWithDataModel;
    }
}

export namespace FillProfessions {
    /**
     * @description профессий будет создано столько скольки равен переданный count
     * @tags FillDb
     * @name FillProfessionsList
     * @summary Добавляет в список профессий количество элементов. Список единый для всего приложения
     * @request GET:/FillProfessions
     * @secure
     */
    export namespace FillProfessionsList {
        export type RequestParams = {};
        export type RequestQuery = {
            /** @format int32 */
            count?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ProfessionModelListResponseWithDataModel;
    }
}

export namespace FillCommittee {
    /**
     * No description
     * @tags FillDb
     * @name FillCommitteeList
     * @summary Имитирует метод Committee/CreateCommittee
     * @request GET:/FillCommittee
     * @secure
     */
    export namespace FillCommitteeList {
        export type RequestParams = {};
        export type RequestQuery = {
            /** @format int64 */
            orgId?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CommitteeModelResponseWithDataModel;
    }
}

export namespace FillDisciplines {
    /**
     * No description
     * @tags FillDb
     * @name FillDisciplinesList
     * @summary Имитирует метод Discipline/AddDiscipline. Только можно генерить сразу несколько
     * @request GET:/FillDisciplines
     * @secure
     */
    export namespace FillDisciplinesList {
        export type RequestParams = {};
        export type RequestQuery = {
            /** @format int64 */
            orgId?: number;
            /** @format int64 */
            count?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DisciplineModelListResponseWithDataModel;
    }
}

export namespace FillAssign {
    /**
     * No description
     * @tags FillDb
     * @name FillAssignList
     * @summary Имитирует метод Assign/AddAssignCourse
     * @request GET:/FillAssign
     * @secure
     */
    export namespace FillAssignList {
        export type RequestParams = {};
        export type RequestQuery = {
            /** @format int64 */
            courseId?: number;
            /** @format uuid */
            studentId?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DisciplineModelListResponseWithDataModel;
    }
}

export namespace Moodle {
    /**
     * @description Так же сохраняет в бд логин и пароль
     * @tags Moodle
     * @name GenerateLogPasDetail
     * @summary Генерация пароля и логина для системы Moodle
     * @request GET:/Moodle/GenerateLogPas/{studentId}
     * @secure
     */
    export namespace GenerateLogPasDetail {
        export type RequestParams = {
            /** @format uuid */
            studentId: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = LogPasModelResponseWithDataModelResponseWithDataModel;
    }

    /**
     * @description так же отправляет на почту студенту
     * @tags Moodle
     * @name GetLogPasDetail
     * @summary Получение пароля и логина для системы Moodle
     * @request GET:/Moodle/GetLogPas/{studentId}
     * @secure
     */
    export namespace GetLogPasDetail {
        export type RequestParams = {
            /** @format uuid */
            studentId: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = LogPasModelResponseWithDataModelResponseWithDataModel;
    }

    /**
     * @description так же отправляет на почту студенту
     * @tags Moodle
     * @name GetLogPasCurrentList
     * @summary Получение пароля и логина для системы Moodle у текущего студента
     * @request GET:/Moodle/GetLogPas/Current
     * @secure
     */
    export namespace GetLogPasCurrentList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = LogPasModelResponseWithDataModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Moodle
     * @name SendMoodleLogpasDetail
     * @summary Повторная отправка пароля и логина для системы Moodle
     * @request GET:/Moodle/SendMoodleLogpas/{studentId}
     * @secure
     */
    export namespace SendMoodleLogpasDetail {
        export type RequestParams = {
            /** @format uuid */
            studentId: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ResponseModel;
    }
}

export namespace Operator {
    /**
     * No description
     * @tags Operator
     * @name GetOrganizationsList
     * @summary Получение списка организаций к которым прикреплен оператор
     * @request GET:/Operator/GetOrganizations
     * @secure
     */
    export namespace GetOrganizationsList {
        export type RequestParams = {};
        export type RequestQuery = {
            search?: string;
            /** @format int32 */
            PageNumber?: number;
            /** @format int32 */
            PageSize?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = OrganizationShortModelPagedResultResponseWithDataModel;
    }

    /**
     * No description
     * @tags Operator
     * @name GetEducationCentersList
     * @summary Получение списка обучающих центров
     * @request GET:/Operator/GetEducationCenters
     * @secure
     */
    export namespace GetEducationCentersList {
        export type RequestParams = {};
        export type RequestQuery = {
            search?: string;
            /** @format int32 */
            PageNumber?: number;
            /** @format int32 */
            PageSize?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = OrganizationShortModelPagedResultResponseWithDataModel;
    }

    /**
     * No description
     * @tags Operator
     * @name GetStudentsByOrganizationsList
     * @summary Получение списка студентов в организациях, к которым привязан оператор
     * @request GET:/Operator/GetStudentsByOrganizations
     * @secure
     */
    export namespace GetStudentsByOrganizationsList {
        export type RequestParams = {};
        export type RequestQuery = {
            search?: string;
            /** @format int32 */
            PageNumber?: number;
            /** @format int32 */
            PageSize?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = StudentShortModelPagedResultResponseWithDataModel;
    }

    /**
     * No description
     * @tags Operator
     * @name GetStudentByIdDetail
     * @summary Получение полной информации о студенте
     * @request GET:/Operator/GetStudentById/{studentId}
     * @secure
     */
    export namespace GetStudentByIdDetail {
        export type RequestParams = {
            /** @format uuid */
            studentId: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = StudentModelResponseWithDataModel;
    }

    /**
     * @description будет использоваться оператором
     * @tags Operator
     * @name UpdateStudentUpdate
     * @summary Редактирование студента
     * @request PUT:/Operator/UpdateStudent
     * @secure
     */
    export namespace UpdateStudentUpdate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = UpdateStudentModelForOper;
        export type RequestHeaders = {};
        export type ResponseBody = StudentModelResponseWithDataModel;
    }

    /**
     * @description Метод для оператора, можно указать Id организации
     * @tags Operator
     * @name RegisterStudentForOperatorCreate
     * @summary Регистрация студента
     * @request POST:/Operator/RegisterStudentForOperator
     * @secure
     */
    export namespace RegisterStudentForOperatorCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = RegisterStudentModelForOper;
        export type RequestHeaders = {};
        export type ResponseBody = StudentModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Operator
     * @name UpdateCurrentOrganizationUpdate
     * @summary Изменение выбранной организации
     * @request PUT:/Operator/UpdateCurrentOrganization/{organizationId}
     * @secure
     */
    export namespace UpdateCurrentOrganizationUpdate {
        export type RequestParams = {
            /** @format int64 */
            organizationId: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ResponseModel;
    }

    /**
     * @description В логике заложено, что оператор может редактировать только себя
     * @tags Operator
     * @name UpdateUpdate
     * @summary Редактирование оператора
     * @request PUT:/Operator/Update
     * @secure
     */
    export namespace UpdateUpdate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = UpdateOperatorModel;
        export type RequestHeaders = {};
        export type ResponseBody = OperatorModelResponseWithDataModel;
    }
}

export namespace Organization {
    /**
     * No description
     * @tags Organization
     * @name GetStudentsDetail
     * @summary Получение списка студенов, связанных с организацией
     * @request GET:/Organization/{organizationId}/GetStudents
     * @secure
     */
    export namespace GetStudentsDetail {
        export type RequestParams = {
            /** @format int64 */
            organizationId: number;
        };
        export type RequestQuery = {
            search?: string;
            /** @format int32 */
            PageNumber?: number;
            /** @format int32 */
            PageSize?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = StudentDetailModelPagedResultResponseWithDataModel;
    }

    /**
     * No description
     * @tags Organization
     * @name CurrentGetStudentsList
     * @summary Получение списка студенов, связанных с current организацией
     * @request GET:/Organization/Current/GetStudents
     * @secure
     */
    export namespace CurrentGetStudentsList {
        export type RequestParams = {};
        export type RequestQuery = {
            search?: string;
            /** @format int32 */
            PageNumber?: number;
            /** @format int32 */
            PageSize?: number;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = StudentDetailModelPagedResultResponseWithDataModel;
    }

    /**
     * No description
     * @tags Organization
     * @name GetOperatorsDetail
     * @summary Получение списка операторов, связанных с организацией
     * @request GET:/Organization/{organizationId}/GetOperators
     * @secure
     */
    export namespace GetOperatorsDetail {
        export type RequestParams = {
            /** @format int64 */
            organizationId: number;
        };
        export type RequestQuery = {
            /** @format int32 */
            PageNumber?: number;
            /** @format int32 */
            PageSize?: number;
            search?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = OperatorShortModelPagedResultResponseWithDataModel;
    }

    /**
     * No description
     * @tags Organization
     * @name CurrentGetOperatorsList
     * @summary Получение списка операторов, связанных с current организацией
     * @request GET:/Organization/Current/GetOperators
     * @secure
     */
    export namespace CurrentGetOperatorsList {
        export type RequestParams = {};
        export type RequestQuery = {
            /** @format int32 */
            PageNumber?: number;
            /** @format int32 */
            PageSize?: number;
            search?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = OperatorShortModelPagedResultResponseWithDataModel;
    }

    /**
     * No description
     * @tags Organization
     * @name OrganizationDetail
     * @summary Получение организации по Id
     * @request GET:/Organization/{organizationId}
     * @secure
     */
    export namespace OrganizationDetail {
        export type RequestParams = {
            /** @format int64 */
            organizationId: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = OrganizationModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Organization
     * @name CurrentList
     * @summary Получение current организации
     * @request GET:/Organization/Current
     * @secure
     */
    export namespace CurrentList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = OrganizationModelResponseWithDataModel;
    }

    /**
     * @description контактым лицом становаится текущий юзер
     * @tags Organization
     * @name AddOrganizationCreate
     * @summary Создание организации
     * @request POST:/Organization/AddOrganization
     * @secure
     */
    export namespace AddOrganizationCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = RegisterOrganizationModel;
        export type RequestHeaders = {};
        export type ResponseBody = OrganizationModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags Organization
     * @name UpdateOrganizationUpdate
     * @summary Редактирование организации
     * @request PUT:/Organization/UpdateOrganization
     * @secure
     */
    export namespace UpdateOrganizationUpdate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = UpdateOrganizationModel;
        export type RequestHeaders = {};
        export type ResponseBody = OrganizationModelResponseWithDataModel;
    }
}

export namespace Register {
    /**
     * No description
     * @tags Register
     * @name OrganizationAndOperatorCreate
     * @summary Регистрация организации и оператора к ней
     * @request POST:/Register/OrganizationAndOperator
     * @secure
     */
    export namespace OrganizationAndOperatorCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = RegisterOrganizationAndOperatorModel;
        export type RequestHeaders = {};
        export type ResponseBody = ResponseModel;
    }

    /**
     * No description
     * @tags Register
     * @name OperatorCreate
     * @summary Регистрация оператора
     * @request POST:/Register/Operator
     * @secure
     */
    export namespace OperatorCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = RegisterOperatorModel;
        export type RequestHeaders = {};
        export type ResponseBody = OperatorModelResponseWithDataModel;
    }
}

export namespace Student {
    /**
     * @description Будет использоваться студентом. Id класть не нужно
     * @tags Student
     * @name UpdateUpdate
     * @summary Редактирование студента
     * @request PUT:/Student/Update
     * @secure
     */
    export namespace UpdateUpdate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = UpdateStudentModel;
        export type RequestHeaders = {};
        export type ResponseBody = StudentModelResponseWithDataModel;
    }
}

export namespace Telegram {
    /**
     * No description
     * @tags Telegram
     * @name WebhookCreate
     * @summary Получение сообщений от телеги
     * @request POST:/Telegram/Webhook
     * @secure
     */
    export namespace WebhookCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = void;
    }

    /**
     * No description
     * @tags Telegram
     * @name TestExList
     * @summary Искусственно генерирует Exception
     * @request GET:/Telegram/TestEx
     * @secure
     */
    export namespace TestExList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = void;
    }
}

export namespace User {
    /**
     * No description
     * @tags User
     * @name AuthorizeCreate
     * @summary Авторизация по логину и паролю
     * @request POST:/User/Authorize
     * @secure
     */
    export namespace AuthorizeCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = AuthModel;
        export type RequestHeaders = {};
        export type ResponseBody = ResponseModel;
    }

    /**
     * No description
     * @tags User
     * @name LogoutCreate
     * @summary Удаление куков
     * @request POST:/User/Logout
     * @secure
     */
    export namespace LogoutCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ResponseModel;
    }

    /**
     * @description Может потребоваться когда например подтвердили почту, а в токене неактуальная инфа
     * @tags User
     * @name UpdateTokenList
     * @summary Обмен токена
     * @request GET:/User/UpdateToken
     * @secure
     */
    export namespace UpdateTokenList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ResponseModel;
    }

    /**
     * No description
     * @tags User
     * @name ConfirmEmailUpdate
     * @summary Подтвержения почты
     * @request PUT:/User/ConfirmEmail
     * @secure
     */
    export namespace ConfirmEmailUpdate {
        export type RequestParams = {};
        export type RequestQuery = {
            token?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ResponseModel;
    }

    /**
     * No description
     * @tags User
     * @name CheckAuthList
     * @summary Проверка авторизации
     * @request GET:/User/CheckAuth
     * @secure
     */
    export namespace CheckAuthList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = UserTypeResponseWithDataModel;
    }

    /**
     * No description
     * @tags User
     * @name CurrentList
     * @summary Получение информации текущего юзера
     * @request GET:/User/Current
     * @secure
     */
    export namespace CurrentList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = UserModelResponseWithDataModel;
    }

    /**
     * No description
     * @tags User
     * @name UpdatePasswordUpdate
     * @summary Получение информации текущего юзера
     * @request PUT:/User/UpdatePassword
     * @secure
     */
    export namespace UpdatePasswordUpdate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = UpdatePasswordModel;
        export type RequestHeaders = {};
        export type ResponseBody = ResponseModel;
    }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = 'https://kursoved.pro/api';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
        return keys
            .map((key) =>
                Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string' ? JSON.stringify(input) : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                          ? JSON.stringify(property)
                          : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
    };

    protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter = this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
            },
            signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
            body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body)
        }).then(async (response) => {
            const r = response.clone() as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch((e) => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title Kursoved API
 * @version v1
 * @license Лицензия API (https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqmd3MtZpZE6jZ2umFKZUP402ajveLbD1PiA&s)
 * @baseUrl https://kursoved.pro/api
 * @contact Коломеец Вадим <info@kursoved.pro> (https://t.me/easyvvv)
 *
 * Это API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    assignCourse = {
        /**
         * No description
         *
         * @tags AssignCourse
         * @name AddAssignCourseCreate
         * @summary Назначение курса
         * @request POST:/AssignCourse/AddAssignCourse
         * @secure
         */
        addAssignCourseCreate: (data: CreateAssignCourseModel, params: RequestParams = {}) =>
            this.request<AssignCourseModelResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/AssignCourse/AddAssignCourse`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * @description Для оператора метод
         *
         * @tags AssignCourse
         * @name GetAssignsDetail
         * @summary Получение назначенных курсов у студента
         * @request GET:/AssignCourse/GetAssigns/{studentId}
         * @secure
         */
        getAssignsDetail: (studentId: string, params: RequestParams = {}) =>
            this.request<AssignCourseModelListResponseWithDataModel, ResponseModel>({
                path: `/AssignCourse/GetAssigns/${studentId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * @description Для оператора метод
         *
         * @tags AssignCourse
         * @name GetAssignsCurrentList
         * @summary Получение назначенных курсов у текущего студента
         * @request GET:/AssignCourse/GetAssigns/Current
         * @secure
         */
        getAssignsCurrentList: (params: RequestParams = {}) =>
            this.request<AssignCourseModelListResponseWithDataModel, ResponseModel>({
                path: `/AssignCourse/GetAssigns/Current`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags AssignCourse
         * @name AssignCourseDetail
         * @summary Получение назначенного курса по Id
         * @request GET:/AssignCourse/{assignId}
         * @secure
         */
        assignCourseDetail: (assignId: number, params: RequestParams = {}) =>
            this.request<AssignCourseModelResponseWithDataModel, ResponseModel>({
                path: `/AssignCourse/${assignId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags AssignCourse
         * @name GetOrganizationAssignsDetail
         * @summary Получение списка назначенных курсов в организации
         * @request GET:/AssignCourse/GetOrganizationAssigns/{organizationId}
         * @secure
         */
        getOrganizationAssignsDetail: (organizationId: number, params: RequestParams = {}) =>
            this.request<AssignCourseModelListResponseWithDataModel, ResponseModel>({
                path: `/AssignCourse/GetOrganizationAssigns/${organizationId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags AssignCourse
         * @name GetOrganizationAssignsCurrentList
         * @summary Получение списка назначенных курсов в текущей организации
         * @request GET:/AssignCourse/GetOrganizationAssigns/Current
         * @secure
         */
        getOrganizationAssignsCurrentList: (params: RequestParams = {}) =>
            this.request<AssignCourseModelListResponseWithDataModel, ResponseModel>({
                path: `/AssignCourse/GetOrganizationAssigns/Current`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags AssignCourse
         * @name GetDateEndLearningList
         * @summary Получение даты окончания курса
         * @request GET:/AssignCourse/GetDateEndLearning
         * @secure
         */
        getDateEndLearningList: (
            query?: {
                /** @format int64 */
                CourseId?: number;
                /** @format date-time */
                StartDate?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<DateTimeResponseWithDataModel, ResponseModel>({
                path: `/AssignCourse/GetDateEndLearning`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags AssignCourse
         * @name GetStudentsByCourseDetail
         * @summary Получение назначенных студентов на курс
         * @request GET:/AssignCourse/GetStudentsByCourse/{courseId}
         * @secure
         */
        getStudentsByCourseDetail: (
            courseId: string,
            query?: {
                /** @format int64 */
                courseId?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<StudentDetailModelListResponseWithDataModel, ResponseModel>({
                path: `/AssignCourse/GetStudentsByCourse/${courseId}`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags AssignCourse
         * @name GetCourseAssignsDetail
         * @summary Получение назначений определенного курса
         * @request GET:/AssignCourse/GetCourseAssigns/{courseId}
         * @secure
         */
        getCourseAssignsDetail: (
            courseId: string,
            query?: {
                /** @format int64 */
                courseId?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<AssignCourseModelListResponseWithDataModel, ResponseModel>({
                path: `/AssignCourse/GetCourseAssigns/${courseId}`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            })
    };
    certificateTheme = {
        /**
         * No description
         *
         * @tags CertificateTheme
         * @name GetThemesList
         * @summary Получение списка аттестационных тем
         * @request GET:/CertificateTheme/GetThemes
         * @secure
         */
        getThemesList: (
            query?: {
                search?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<CertificateThemeModelListResponseWithDataModel, any>({
                path: `/CertificateTheme/GetThemes`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            })
    };
    committee = {
        /**
         * @description поле CommitteeId в Members заполнять не нужно
         *
         * @tags Committee
         * @name CreateCommitteeCreate
         * @summary Создание комиссии/комитета
         * @request POST:/Committee/CreateCommittee
         * @secure
         */
        createCommitteeCreate: (data: CreateCommitteeModel, params: RequestParams = {}) =>
            this.request<CommitteeModelResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/Committee/CreateCommittee`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Committee
         * @name UpdateCommitteeUpdate
         * @summary Редактирование комиссии/комитета
         * @request PUT:/Committee/UpdateCommittee
         * @secure
         */
        updateCommitteeUpdate: (data: UpdateCommitteeModel, params: RequestParams = {}) =>
            this.request<CommitteeModelResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/Committee/UpdateCommittee`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Committee
         * @name GetCommittiesByOrgIdDetail
         * @summary Получение списка комиссий по компании
         * @request GET:/Committee/GetCommittiesByOrgId/{organizationId}
         * @secure
         */
        getCommittiesByOrgIdDetail: (organizationId: number, params: RequestParams = {}) =>
            this.request<CommitteeShortModelListResponseWithDataModel, ResponseModel>({
                path: `/Committee/GetCommittiesByOrgId/${organizationId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Committee
         * @name GetCommittiesByOrgIdCurrentList
         * @summary Получение списка комиссий по current компании
         * @request GET:/Committee/GetCommittiesByOrgId/Current
         * @secure
         */
        getCommittiesByOrgIdCurrentList: (params: RequestParams = {}) =>
            this.request<CommitteeShortModelListResponseWithDataModel, ResponseModel>({
                path: `/Committee/GetCommittiesByOrgId/Current`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Committee
         * @name CommitteeDetail
         * @summary Получение полной модели комиссии
         * @request GET:/Committee/{committeeId}
         * @secure
         */
        committeeDetail: (committeeId: number, params: RequestParams = {}) =>
            this.request<CommitteeModelResponseWithDataModel, ResponseModel>({
                path: `/Committee/${committeeId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * @description Удаление производится транзакционно. Если не удалось удалить одну, не удаляются все
         *
         * @tags Committee
         * @name RemoveCommittiesDelete
         * @summary Удаление комиссий
         * @request DELETE:/Committee/RemoveCommitties
         * @secure
         */
        removeCommittiesDelete: (data: number[], params: RequestParams = {}) =>
            this.request<Int64ResponseCodeEnumDictionaryResponseWithDataModel, ResponseModel>({
                path: `/Committee/RemoveCommitties`,
                method: 'DELETE',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            })
    };
    course = {
        /**
         * No description
         *
         * @tags Course
         * @name GetCoursesByOrgIdDetail
         * @summary Получение списка курсов в организации
         * @request GET:/Course/GetCoursesByOrgId/{organizationId}
         * @secure
         */
        getCoursesByOrgIdDetail: (
            organizationId: number,
            query?: {
                search?: string;
                /** @format int32 */
                PageNumber?: number;
                /** @format int32 */
                PageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<CourseModelPagedResultResponseWithDataModel, ResponseModel>({
                path: `/Course/GetCoursesByOrgId/${organizationId}`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Course
         * @name GetCoursesByOrgIdCurrentList
         * @summary Получение спсика курсов в current организации
         * @request GET:/Course/GetCoursesByOrgId/Current
         * @secure
         */
        getCoursesByOrgIdCurrentList: (
            query?: {
                search?: string;
                /** @format int32 */
                PageNumber?: number;
                /** @format int32 */
                PageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<CourseModelPagedResultResponseWithDataModel, ResponseModel>({
                path: `/Course/GetCoursesByOrgId/Current`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Course
         * @name CourseDetail
         * @summary Получение курса по Id
         * @request GET:/Course/{courseId}
         * @secure
         */
        courseDetail: (courseId: number, params: RequestParams = {}) =>
            this.request<CourseModelResponseWithDataModel, ResponseModel>({
                path: `/Course/${courseId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Course
         * @name CreateCourseCreate
         * @summary Добавление курса
         * @request POST:/Course/CreateCourse
         * @secure
         */
        createCourseCreate: (data: CreateCourseModel, params: RequestParams = {}) =>
            this.request<CourseModelResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/Course/CreateCourse`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Course
         * @name UpdateCourseUpdate
         * @summary Редактирование курса
         * @request PUT:/Course/UpdateCourse
         * @secure
         */
        updateCourseUpdate: (data: UpdateCourseModel, params: RequestParams = {}) =>
            this.request<CourseModelResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/Course/UpdateCourse`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Course
         * @name RemoveCoursesDelete
         * @summary Удаление курса
         * @request DELETE:/Course/RemoveCourses
         * @secure
         */
        removeCoursesDelete: (data: number[], params: RequestParams = {}) =>
            this.request<Int64ResponseCodeEnumDictionaryResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/Course/RemoveCourses`,
                method: 'DELETE',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * @description Государственный список названий профессий и их номеров
         *
         * @tags Course
         * @name GetProfessionsList
         * @summary Получение списка названий курсов
         * @request GET:/Course/GetProfessions
         * @secure
         */
        getProfessionsList: (
            query?: {
                search?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<ProfessionModelListResponseWithDataModel, any>({
                path: `/Course/GetProfessions`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Course
         * @name GetDocumentListDetail
         * @summary Получение списка выдаваемых документов по итогам курса
         * @request GET:/Course/GetDocumentList/{courseId}
         * @secure
         */
        getDocumentListDetail: (courseId: number, params: RequestParams = {}) =>
            this.request<DocumentTypeListResponseWithDataModel, ResponseModel>({
                path: `/Course/GetDocumentList/${courseId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            })
    };
    dadata = {
        /**
         * No description
         *
         * @tags Dadata
         * @name SuggestAddressList
         * @summary Получение списка адресов по строке
         * @request GET:/Dadata/SuggestAddress
         * @secure
         */
        suggestAddressList: (
            query?: {
                row?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<AddressListResponseWithDataModel, any>({
                path: `/Dadata/SuggestAddress`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            })
    };
    discipline = {
        /**
         * No description
         *
         * @tags Discipline
         * @name GetDisciplineListByOrgIdDetail
         * @summary Получение списка дисциплин связанный с организацией
         * @request GET:/Discipline/GetDisciplineListByOrgId/{organizationId}
         * @secure
         */
        getDisciplineListByOrgIdDetail: (organizationId: number, params: RequestParams = {}) =>
            this.request<DisciplineListModelResponseWithDataModel, ResponseModel>({
                path: `/Discipline/GetDisciplineListByOrgId/${organizationId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Discipline
         * @name GetDisciplineListByOrgIdCurrentList
         * @summary Получение списка дисциплин связанный с current организацией
         * @request GET:/Discipline/GetDisciplineListByOrgId/current
         * @secure
         */
        getDisciplineListByOrgIdCurrentList: (params: RequestParams = {}) =>
            this.request<DisciplineListModelResponseWithDataModel, ResponseModel>({
                path: `/Discipline/GetDisciplineListByOrgId/current`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Discipline
         * @name AddDisciplineCreate
         * @summary Добавление дисциплины
         * @request POST:/Discipline/AddDiscipline
         * @secure
         */
        addDisciplineCreate: (data: CreateDisciplineModel, params: RequestParams = {}) =>
            this.request<DisciplineModelResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/Discipline/AddDiscipline`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Discipline
         * @name RemoveDisciplinesDelete
         * @summary Удаление дисциплины
         * @request DELETE:/Discipline/RemoveDisciplines
         * @secure
         */
        removeDisciplinesDelete: (data: number[], params: RequestParams = {}) =>
            this.request<Int64ResponseCodeEnumDictionaryResponseWithDataModel, ResponseModel>({
                path: `/Discipline/RemoveDisciplines`,
                method: 'DELETE',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * @description можно создать и добавить к организации
         *
         * @tags Discipline
         * @name CreateTestDisciplineListCreate
         * @summary Добавляет пустой список дисциплин в бд, добавил для теста
         * @request POST:/Discipline/CreateTestDisciplineList
         * @secure
         */
        createTestDisciplineListCreate: (params: RequestParams = {}) =>
            this.request<DisciplineListModelResponseWithDataModel, ResponseModel>({
                path: `/Discipline/CreateTestDisciplineList`,
                method: 'POST',
                secure: true,
                format: 'json',
                ...params
            })
    };
    document = {
        /**
         * No description
         *
         * @tags Document
         * @name GetContractInfoList
         * @summary Информация для генерации документа
         * @request GET:/Document/GetContractInfo
         * @secure
         */
        getContractInfoList: (params: RequestParams = {}) =>
            this.request<ContractDocModelResponseWithDataModel, ResponseModel>({
                path: `/Document/GetContractInfo`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Document
         * @name ContractCreate
         * @summary Получение файла договора
         * @request POST:/Document/Contract
         * @secure
         */
        contractCreate: (data: ContractDocModel, params: RequestParams = {}) =>
            this.request<File, ResponseModel | InvalidResponseModel>({
                path: `/Document/Contract`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Document
         * @name GetCertificateEducationInfoList
         * @summary Получение информации для генерации справки об обучении
         * @request GET:/Document/GetCertificateEducationInfo
         * @secure
         */
        getCertificateEducationInfoList: (params: RequestParams = {}) =>
            this.request<CertificateEducationDocModelResponseWithDataModel, ResponseModel>({
                path: `/Document/GetCertificateEducationInfo`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Document
         * @name CertificateEducationCreate
         * @summary Получение документа справки об обучении
         * @request POST:/Document/CertificateEducation
         * @secure
         */
        certificateEducationCreate: (data: CertificateEducationDocModel, params: RequestParams = {}) =>
            this.request<File, ResponseModel | InvalidResponseModel>({
                path: `/Document/CertificateEducation`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Document
         * @name GetTicketMInfoList
         * @summary Получение информации для генерации удостоверения М
         * @request GET:/Document/GetTicketMInfo
         * @secure
         */
        getTicketMInfoList: (params: RequestParams = {}) =>
            this.request<TicketMModelResponseWithDataModel, ResponseModel>({
                path: `/Document/GetTicketMInfo`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Document
         * @name TicketMCreate
         * @summary Получение документа справки об обучении
         * @request POST:/Document/TicketM
         * @secure
         */
        ticketMCreate: (data: TicketMModel, params: RequestParams = {}) =>
            this.request<File, ResponseModel | InvalidResponseModel>({
                path: `/Document/TicketM`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Document
         * @name CertificateCreate
         * @summary Генерация файла сертификата
         * @request POST:/Document/Certificate
         * @secure
         */
        certificateCreate: (data: CreateCertificateDocModel, params: RequestParams = {}) =>
            this.request<File, ResponseModel | InvalidResponseModel>({
                path: `/Document/Certificate`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * @description Нужно передать в метод Certificate
         *
         * @tags Document
         * @name GetCertificateInfoDetail
         * @summary Получение информации для заполнения формы генерации сертификата
         * @request GET:/Document/GetCertificateInfo/{assignId}
         * @secure
         */
        getCertificateInfoDetail: (assignId: number, params: RequestParams = {}) =>
            this.request<CertificateDocModelResponseWithDataModel, ResponseModel>({
                path: `/Document/GetCertificateInfo/${assignId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Document
         * @name DiplomCreate
         * @summary Получение документа по назначенному курсу
         * @request POST:/Document/Diplom
         * @secure
         */
        diplomCreate: (data: CreateDiplomDocModel, params: RequestParams = {}) =>
            this.request<File, ResponseModel | InvalidResponseModel>({
                path: `/Document/Diplom`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Document
         * @name GetDiplomInfoDetail
         * @summary Получение информации для заполнения формы генерации диплома
         * @request GET:/Document/GetDiplomInfo/{assignId}
         * @secure
         */
        getDiplomInfoDetail: (assignId: number, params: RequestParams = {}) =>
            this.request<DiplomDocModelResponseWithDataModel, ResponseModel>({
                path: `/Document/GetDiplomInfo/${assignId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Document
         * @name TicketBCreate
         * @summary генерация файла удостоверения Б
         * @request POST:/Document/TicketB
         * @secure
         */
        ticketBCreate: (data: TicketBModel, params: RequestParams = {}) =>
            this.request<File, ResponseModel | InvalidResponseModel>({
                path: `/Document/TicketB`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Document
         * @name GetTicketBInfoDetail
         * @summary Получение информации для заполнения формы генерации удостоверения Б
         * @request GET:/Document/GetTicketBInfo/{assignId}
         * @secure
         */
        getTicketBInfoDetail: (assignId: number, params: RequestParams = {}) =>
            this.request<TicketBModelResponseWithDataModel, ResponseModel>({
                path: `/Document/GetTicketBInfo/${assignId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            })
    };
    documentTemplate = {
        /**
         * No description
         *
         * @tags DocumentTemplate
         * @name AddTemplateCreate
         * @summary Добавление шаблона
         * @request POST:/DocumentTemplate/AddTemplate
         * @secure
         */
        addTemplateCreate: (data: AddDocumentTemplateModel, params: RequestParams = {}) =>
            this.request<ResponseModel, ResponseModel>({
                path: `/DocumentTemplate/AddTemplate`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            })
    };
    file = {
        /**
         * No description
         *
         * @tags File
         * @name FileDetail
         * @summary Получение файла
         * @request GET:/File/{id}
         * @secure
         */
        fileDetail: (id: string, params: RequestParams = {}) =>
            this.request<File, ResponseModel>({
                path: `/File/${id}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags File
         * @name SaveCreate
         * @summary сохранение файла
         * @request POST:/File/Save
         * @secure
         */
        saveCreate: (
            data: {
                /** @format binary */
                file?: File;
            },
            params: RequestParams = {}
        ) =>
            this.request<DbFileModelResponseWithDataModel, InvalidResponseModel>({
                path: `/File/Save`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.FormData,
                format: 'json',
                ...params
            })
    };
    fillOrganizationAndOperator = {
        /**
         * @description По умолчанию генерируется пароль юзера "Kursoved"
         *
         * @tags FillDb
         * @name FillOrganizationAndOperatorList
         * @summary Имитирует метод Register/OrganizationAndOperator
         * @request GET:/FillOrganizationAndOperator
         * @secure
         */
        fillOrganizationAndOperatorList: (params: RequestParams = {}) =>
            this.request<OperatorModelOrganizationModelValueTupleResponseWithDataModel, any>({
                path: `/FillOrganizationAndOperator`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            })
    };
    fillStudent = {
        /**
         * @description По умолчанию генерируется пароль юзера "Kursoved"
         *
         * @tags FillDb
         * @name FillStudentList
         * @summary Имитирует метод Operator/RegisterStudentForOperator
         * @request GET:/FillStudent
         * @secure
         */
        fillStudentList: (
            query?: {
                /** @format int64 */
                orgId?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<StudentModelResponseWithDataModel, any>({
                path: `/FillStudent`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            })
    };
    fillCourse = {
        /**
         * No description
         *
         * @tags FillDb
         * @name FillCourseList
         * @summary Имитирует метод Course/CreateCourse
         * @request GET:/FillCourse
         * @secure
         */
        fillCourseList: (
            query?: {
                /** @format int64 */
                orgId?: number;
                /** @format int64 */
                proffId?: number;
                /** @format int64 */
                committieId?: number;
                disciplineIds?: number[];
            },
            params: RequestParams = {}
        ) =>
            this.request<CourseModelResponseWithDataModel, any>({
                path: `/FillCourse`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            })
    };
    fillProfessions = {
        /**
         * @description профессий будет создано столько скольки равен переданный count
         *
         * @tags FillDb
         * @name FillProfessionsList
         * @summary Добавляет в список профессий количество элементов. Список единый для всего приложения
         * @request GET:/FillProfessions
         * @secure
         */
        fillProfessionsList: (
            query?: {
                /** @format int32 */
                count?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<ProfessionModelListResponseWithDataModel, any>({
                path: `/FillProfessions`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            })
    };
    fillCommittee = {
        /**
         * No description
         *
         * @tags FillDb
         * @name FillCommitteeList
         * @summary Имитирует метод Committee/CreateCommittee
         * @request GET:/FillCommittee
         * @secure
         */
        fillCommitteeList: (
            query?: {
                /** @format int64 */
                orgId?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<CommitteeModelResponseWithDataModel, any>({
                path: `/FillCommittee`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            })
    };
    fillDisciplines = {
        /**
         * No description
         *
         * @tags FillDb
         * @name FillDisciplinesList
         * @summary Имитирует метод Discipline/AddDiscipline. Только можно генерить сразу несколько
         * @request GET:/FillDisciplines
         * @secure
         */
        fillDisciplinesList: (
            query?: {
                /** @format int64 */
                orgId?: number;
                /** @format int64 */
                count?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<DisciplineModelListResponseWithDataModel, any>({
                path: `/FillDisciplines`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            })
    };
    fillAssign = {
        /**
         * No description
         *
         * @tags FillDb
         * @name FillAssignList
         * @summary Имитирует метод Assign/AddAssignCourse
         * @request GET:/FillAssign
         * @secure
         */
        fillAssignList: (
            query?: {
                /** @format int64 */
                courseId?: number;
                /** @format uuid */
                studentId?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<DisciplineModelListResponseWithDataModel, any>({
                path: `/FillAssign`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            })
    };
    moodle = {
        /**
         * @description Так же сохраняет в бд логин и пароль
         *
         * @tags Moodle
         * @name GenerateLogPasDetail
         * @summary Генерация пароля и логина для системы Moodle
         * @request GET:/Moodle/GenerateLogPas/{studentId}
         * @secure
         */
        generateLogPasDetail: (studentId: string, params: RequestParams = {}) =>
            this.request<LogPasModelResponseWithDataModelResponseWithDataModel, ResponseModel>({
                path: `/Moodle/GenerateLogPas/${studentId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * @description так же отправляет на почту студенту
         *
         * @tags Moodle
         * @name GetLogPasDetail
         * @summary Получение пароля и логина для системы Moodle
         * @request GET:/Moodle/GetLogPas/{studentId}
         * @secure
         */
        getLogPasDetail: (studentId: string, params: RequestParams = {}) =>
            this.request<LogPasModelResponseWithDataModelResponseWithDataModel, ResponseModel>({
                path: `/Moodle/GetLogPas/${studentId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * @description так же отправляет на почту студенту
         *
         * @tags Moodle
         * @name GetLogPasCurrentList
         * @summary Получение пароля и логина для системы Moodle у текущего студента
         * @request GET:/Moodle/GetLogPas/Current
         * @secure
         */
        getLogPasCurrentList: (params: RequestParams = {}) =>
            this.request<LogPasModelResponseWithDataModelResponseWithDataModel, ResponseModel>({
                path: `/Moodle/GetLogPas/Current`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Moodle
         * @name SendMoodleLogpasDetail
         * @summary Повторная отправка пароля и логина для системы Moodle
         * @request GET:/Moodle/SendMoodleLogpas/{studentId}
         * @secure
         */
        sendMoodleLogpasDetail: (studentId: string, params: RequestParams = {}) =>
            this.request<ResponseModel, ResponseModel>({
                path: `/Moodle/SendMoodleLogpas/${studentId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            })
    };
    operator = {
        /**
         * No description
         *
         * @tags Operator
         * @name GetOrganizationsList
         * @summary Получение списка организаций к которым прикреплен оператор
         * @request GET:/Operator/GetOrganizations
         * @secure
         */
        getOrganizationsList: (
            query?: {
                search?: string;
                /** @format int32 */
                PageNumber?: number;
                /** @format int32 */
                PageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<OrganizationShortModelPagedResultResponseWithDataModel, ResponseModel>({
                path: `/Operator/GetOrganizations`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Operator
         * @name GetEducationCentersList
         * @summary Получение списка обучающих центров
         * @request GET:/Operator/GetEducationCenters
         * @secure
         */
        getEducationCentersList: (
            query?: {
                search?: string;
                /** @format int32 */
                PageNumber?: number;
                /** @format int32 */
                PageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<OrganizationShortModelPagedResultResponseWithDataModel, ResponseModel>({
                path: `/Operator/GetEducationCenters`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Operator
         * @name GetStudentsByOrganizationsList
         * @summary Получение списка студентов в организациях, к которым привязан оператор
         * @request GET:/Operator/GetStudentsByOrganizations
         * @secure
         */
        getStudentsByOrganizationsList: (
            query?: {
                search?: string;
                /** @format int32 */
                PageNumber?: number;
                /** @format int32 */
                PageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<StudentShortModelPagedResultResponseWithDataModel, ResponseModel>({
                path: `/Operator/GetStudentsByOrganizations`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Operator
         * @name GetStudentByIdDetail
         * @summary Получение полной информации о студенте
         * @request GET:/Operator/GetStudentById/{studentId}
         * @secure
         */
        getStudentByIdDetail: (studentId: string, params: RequestParams = {}) =>
            this.request<StudentModelResponseWithDataModel, ResponseModel>({
                path: `/Operator/GetStudentById/${studentId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * @description будет использоваться оператором
         *
         * @tags Operator
         * @name UpdateStudentUpdate
         * @summary Редактирование студента
         * @request PUT:/Operator/UpdateStudent
         * @secure
         */
        updateStudentUpdate: (data: UpdateStudentModelForOper, params: RequestParams = {}) =>
            this.request<StudentModelResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/Operator/UpdateStudent`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * @description Метод для оператора, можно указать Id организации
         *
         * @tags Operator
         * @name RegisterStudentForOperatorCreate
         * @summary Регистрация студента
         * @request POST:/Operator/RegisterStudentForOperator
         * @secure
         */
        registerStudentForOperatorCreate: (data: RegisterStudentModelForOper, params: RequestParams = {}) =>
            this.request<StudentModelResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/Operator/RegisterStudentForOperator`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Operator
         * @name UpdateCurrentOrganizationUpdate
         * @summary Изменение выбранной организации
         * @request PUT:/Operator/UpdateCurrentOrganization/{organizationId}
         * @secure
         */
        updateCurrentOrganizationUpdate: (organizationId: number, params: RequestParams = {}) =>
            this.request<ResponseModel, ResponseModel>({
                path: `/Operator/UpdateCurrentOrganization/${organizationId}`,
                method: 'PUT',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * @description В логике заложено, что оператор может редактировать только себя
         *
         * @tags Operator
         * @name UpdateUpdate
         * @summary Редактирование оператора
         * @request PUT:/Operator/Update
         * @secure
         */
        updateUpdate: (data: UpdateOperatorModel, params: RequestParams = {}) =>
            this.request<OperatorModelResponseWithDataModel, ResponseModel>({
                path: `/Operator/Update`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            })
    };
    organization = {
        /**
         * No description
         *
         * @tags Organization
         * @name GetStudentsDetail
         * @summary Получение списка студенов, связанных с организацией
         * @request GET:/Organization/{organizationId}/GetStudents
         * @secure
         */
        getStudentsDetail: (
            organizationId: number,
            query?: {
                search?: string;
                /** @format int32 */
                PageNumber?: number;
                /** @format int32 */
                PageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<StudentDetailModelPagedResultResponseWithDataModel, ResponseModel>({
                path: `/Organization/${organizationId}/GetStudents`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Organization
         * @name CurrentGetStudentsList
         * @summary Получение списка студенов, связанных с current организацией
         * @request GET:/Organization/Current/GetStudents
         * @secure
         */
        currentGetStudentsList: (
            query?: {
                search?: string;
                /** @format int32 */
                PageNumber?: number;
                /** @format int32 */
                PageSize?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<StudentDetailModelPagedResultResponseWithDataModel, ResponseModel>({
                path: `/Organization/Current/GetStudents`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Organization
         * @name GetOperatorsDetail
         * @summary Получение списка операторов, связанных с организацией
         * @request GET:/Organization/{organizationId}/GetOperators
         * @secure
         */
        getOperatorsDetail: (
            organizationId: number,
            query?: {
                /** @format int32 */
                PageNumber?: number;
                /** @format int32 */
                PageSize?: number;
                search?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<OperatorShortModelPagedResultResponseWithDataModel, ResponseModel>({
                path: `/Organization/${organizationId}/GetOperators`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Organization
         * @name CurrentGetOperatorsList
         * @summary Получение списка операторов, связанных с current организацией
         * @request GET:/Organization/Current/GetOperators
         * @secure
         */
        currentGetOperatorsList: (
            query?: {
                /** @format int32 */
                PageNumber?: number;
                /** @format int32 */
                PageSize?: number;
                search?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<OperatorShortModelPagedResultResponseWithDataModel, ResponseModel>({
                path: `/Organization/Current/GetOperators`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Organization
         * @name OrganizationDetail
         * @summary Получение организации по Id
         * @request GET:/Organization/{organizationId}
         * @secure
         */
        organizationDetail: (organizationId: number, params: RequestParams = {}) =>
            this.request<OrganizationModelResponseWithDataModel, ResponseModel>({
                path: `/Organization/${organizationId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Organization
         * @name CurrentList
         * @summary Получение current организации
         * @request GET:/Organization/Current
         * @secure
         */
        currentList: (params: RequestParams = {}) =>
            this.request<OrganizationModelResponseWithDataModel, ResponseModel>({
                path: `/Organization/Current`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * @description контактым лицом становаится текущий юзер
         *
         * @tags Organization
         * @name AddOrganizationCreate
         * @summary Создание организации
         * @request POST:/Organization/AddOrganization
         * @secure
         */
        addOrganizationCreate: (data: RegisterOrganizationModel, params: RequestParams = {}) =>
            this.request<OrganizationModelResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/Organization/AddOrganization`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Organization
         * @name UpdateOrganizationUpdate
         * @summary Редактирование организации
         * @request PUT:/Organization/UpdateOrganization
         * @secure
         */
        updateOrganizationUpdate: (data: UpdateOrganizationModel, params: RequestParams = {}) =>
            this.request<OrganizationModelResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/Organization/UpdateOrganization`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            })
    };
    register = {
        /**
         * No description
         *
         * @tags Register
         * @name OrganizationAndOperatorCreate
         * @summary Регистрация организации и оператора к ней
         * @request POST:/Register/OrganizationAndOperator
         * @secure
         */
        organizationAndOperatorCreate: (data: RegisterOrganizationAndOperatorModel, params: RequestParams = {}) =>
            this.request<ResponseModel, InvalidResponseModel>({
                path: `/Register/OrganizationAndOperator`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Register
         * @name OperatorCreate
         * @summary Регистрация оператора
         * @request POST:/Register/Operator
         * @secure
         */
        operatorCreate: (data: RegisterOperatorModel, params: RequestParams = {}) =>
            this.request<OperatorModelResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/Register/Operator`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            })
    };
    student = {
        /**
         * @description Будет использоваться студентом. Id класть не нужно
         *
         * @tags Student
         * @name UpdateUpdate
         * @summary Редактирование студента
         * @request PUT:/Student/Update
         * @secure
         */
        updateUpdate: (data: UpdateStudentModel, params: RequestParams = {}) =>
            this.request<StudentModelResponseWithDataModel, ResponseModel | InvalidResponseModel>({
                path: `/Student/Update`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            })
    };
    telegram = {
        /**
         * No description
         *
         * @tags Telegram
         * @name WebhookCreate
         * @summary Получение сообщений от телеги
         * @request POST:/Telegram/Webhook
         * @secure
         */
        webhookCreate: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/Telegram/Webhook`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags Telegram
         * @name TestExList
         * @summary Искусственно генерирует Exception
         * @request GET:/Telegram/TestEx
         * @secure
         */
        testExList: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/Telegram/TestEx`,
                method: 'GET',
                secure: true,
                ...params
            })
    };
    user = {
        /**
         * No description
         *
         * @tags User
         * @name AuthorizeCreate
         * @summary Авторизация по логину и паролю
         * @request POST:/User/Authorize
         * @secure
         */
        authorizeCreate: (data: AuthModel, params: RequestParams = {}) =>
            this.request<ResponseModel, ResponseModel>({
                path: `/User/Authorize`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags User
         * @name LogoutCreate
         * @summary Удаление куков
         * @request POST:/User/Logout
         * @secure
         */
        logoutCreate: (params: RequestParams = {}) =>
            this.request<ResponseModel, ResponseModel>({
                path: `/User/Logout`,
                method: 'POST',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * @description Может потребоваться когда например подтвердили почту, а в токене неактуальная инфа
         *
         * @tags User
         * @name UpdateTokenList
         * @summary Обмен токена
         * @request GET:/User/UpdateToken
         * @secure
         */
        updateTokenList: (params: RequestParams = {}) =>
            this.request<ResponseModel, ResponseModel>({
                path: `/User/UpdateToken`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags User
         * @name ConfirmEmailUpdate
         * @summary Подтвержения почты
         * @request PUT:/User/ConfirmEmail
         * @secure
         */
        confirmEmailUpdate: (
            query?: {
                token?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<ResponseModel, ResponseModel>({
                path: `/User/ConfirmEmail`,
                method: 'PUT',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags User
         * @name CheckAuthList
         * @summary Проверка авторизации
         * @request GET:/User/CheckAuth
         * @secure
         */
        checkAuthList: (params: RequestParams = {}) =>
            this.request<UserTypeResponseWithDataModel, ResponseModel>({
                path: `/User/CheckAuth`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags User
         * @name CurrentList
         * @summary Получение информации текущего юзера
         * @request GET:/User/Current
         * @secure
         */
        currentList: (params: RequestParams = {}) =>
            this.request<UserModelResponseWithDataModel, ResponseModel>({
                path: `/User/Current`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags User
         * @name UpdatePasswordUpdate
         * @summary Получение информации текущего юзера
         * @request PUT:/User/UpdatePassword
         * @secure
         */
        updatePasswordUpdate: (data: UpdatePasswordModel, params: RequestParams = {}) =>
            this.request<ResponseModel, ResponseModel>({
                path: `/User/UpdatePassword`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params
            })
    };
}
