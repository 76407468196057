import { CommitteeMemberType } from 'api/committees/committees.types';
import { AssignedQualificationType } from '../api/courses/courses.types';
import { ResponseCode } from '../helpers/constants';
import {
    DisciplineGradeType,
    EducationType,
    DocumentType,
    TrainingType,
    DisciplineType
} from 'api/api';
import { StaticDocument } from 'api/documents/documents.types';

export const messages: Partial<Record<ResponseCode, React.ReactNode>> = {
    Error: 'Произошла ошибка, повторите попытку позже',
    Ok: 'Успешно!',
    AuthException: 'Неверный логин или пароль',
    Unauthorized: 'Вы не авторизованы',
    Invalid: 'Проверьте правильность заполнения полей',
    EmailAlreadyUse: 'Этот E-mail уже используется другим пользователем',
    NotFoundDocumentTemplate: 'Шаблон документа не добавлен для организации',
    ServerError: (
        <>
            Произошла ошибка сервера.
            <br />
            Повторите попытку позже.
        </>
    ),
    RemoveCommitteeException: 'Эта комиссия используется в добавленных курсах'
};

const trainingTypes: Record<TrainingType, string> = {
    ProfessionalTraining: 'Профессиональное обучение (ПО)',
    AdvancedTraining: 'Повышение квалификации (ПК)',
    ProfessionalRetraining: 'Профессиональная переподготовка (ПП)'
};

const qualificationTypes: Partial<Record<AssignedQualificationType, string>> = {
    MayBeAdmitted: 'Может быть допущен',
    Rank: 'Разряд'
};

const issuedDocuments: Partial<Record<DocumentType, string>> = {
    Diplom: 'Диплом',
    Certificate: 'Свидетельство',
    TicketB: 'Удостоверение Б',
    Contract: 'Договор на обучение'
};

const staticDocuments: Record<StaticDocument, string> = {
    [StaticDocument.PaymentInvoice]: 'Счет на оплату',
    [StaticDocument.CertificateOfStudy]: 'Справка об обучении',
    [StaticDocument.TicketM]: 'Удостоверение М'
};

const committeeMemberType: Record<CommitteeMemberType, string> = {
    Chairman: 'Председатель',
    SubChairman: 'Заместитель председателя',
    MemberCommittee: 'Член комиссии'
};

const educationTypes: Record<EducationType, string> = {
    High: 'Высшее',
    MiddleCommon: 'Среднее общее',
    MiddleProfessional: 'Среднее профессиональное'
};

export const gradeTypes: Record<DisciplineGradeType, string> = {
    Excelent: 'Отлично',
    Good: 'Хорошо',
    Accepted: 'Зачтено'
};

export const disciplineTypes: Record<DisciplineType, string> = {
    PO: 'ПО',
    PK: 'ПК',
    PP: 'ПП'
};

export default {
    trainingTypes,
    qualificationTypes,
    issuedDocuments,
    committeeMemberType,
    educationTypes,
    gradeTypes,
    staticDocuments,
    disciplineTypes
};
