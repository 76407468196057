/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { API_URL, ResponseCodes } from '../helpers/constants';
import { getMessage } from '../messages';
import { FetchResult, FetchError, FormErrors } from '../typings/fetch';
import { lowerCaseAll } from './strings';
import { checkErrorAndScroll } from './forms';

type RequestProps = RequestInit & {
    download?: boolean;
};

export type FetchCallback<
    ReturnModel = any,
    RequestModel = any,
    ErrorReturn = any
> = (
    onSuccess?: (response: FetchResult<ReturnModel>) => any,
    onError?: (response: FetchError<RequestModel>) => ErrorReturn
) => Promise<ErrorReturn>;

export function mapToFormErrors<RequestModel = any>(
    res: FetchError<RequestModel>
): undefined | FormErrors<RequestModel> {
    const result =
        res.fields &&
        (Object.entries(res.fields).reduce(
            (current, [field, value]) => ({
                ...current,
                [lowerCaseAll(field)]: value
            }),
            {}
        ) as FormErrors<RequestModel>);

    checkErrorAndScroll(result);

    return result;
}

export function fetchAPI<
    ReturnModel = unknown,
    RequestModel = unknown,
    ErrorReturn = any
>(
    path: string,
    init?: RequestProps,
    showMessage = true
): FetchCallback<ReturnModel, RequestModel, ErrorReturn> {
    const isFormData = init?.body instanceof FormData;

    return (onSuccess, onError) => {
        const throwError = (res: FetchError<RequestModel>) => {
            if (res.msg) {
                console.error(res.msg);
            }

            if (showMessage) {
                toast.error(res.msg || getMessage(res.code));
            }

            return onError?.(res) ?? mapToFormErrors(res);
        };

        return fetch(`${API_URL}/${path}`, {
            credentials: 'include',
            ...(init || {}),
            headers: {
                ...(isFormData ? {} : { 'Content-type': 'application/json' }),
                ...(init?.download ? {} : { Accept: 'application/json' }),
                ...(init?.headers ?? {})
            }
        })
            .then(async (res) => {
                const contentType = res.headers.get('Content-type');

                const response: Maybe<FetchResult<ReturnModel>> =
                    contentType?.includes('application/json')
                        ? await res.json()
                        : null;

                if (!res.ok || (response && !response.ok)) {
                    return throwError(
                        (response as FetchError<RequestModel>) ?? {
                            code: ResponseCodes.Error,
                            ok: false
                        }
                    );
                }

                if (init?.download) {
                    const blob = await res.blob();

                    if (!blob) {
                        throw new Error(`No blob in fetch result`);
                    }

                    const url = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.target = '_blank';

                    const header = res.headers.get('Content-Disposition');

                    if (!header) {
                        throw new Error(`No Content-Disposition header`);
                    }

                    const parts = header.split(';');
                    const filename = parts[1].split('=')[1];

                    if (filename) {
                        link.download = filename;
                    }

                    link.click();
                    link.remove();

                    URL.revokeObjectURL(url);
                }

                return (
                    onSuccess?.(
                        response ??
                            ({
                                ok: true,
                                code: ResponseCodes.Ok
                            } as FetchResult<ReturnModel>)
                    ) || undefined
                );
            })
            .catch((e) => {
                return throwError({
                    code: ResponseCodes.Error,
                    ok: false,
                    msg: e
                });
            });
    };
}

export function fetchAPIAsync<ReturnModel = unknown>(
    path: string,
    init?: RequestProps,
    showMessage = true
): Promise<ReturnModel> {
    return new Promise<ReturnModel>((resolve, reject) => {
        fetchAPI<ReturnModel>(
            path,
            init,
            showMessage
        )(
            (response) => {
                resolve(response.data as ReturnModel);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

export function get<ReturnModel = string>(
    path: string,
    params?: Record<string, string | number | undefined>,
    init?: RequestProps
) {
    const filteredParams =
        params &&
        Object.entries(params).reduce<Record<string, string>>(
            (prev, [key, value]) => ({
                ...prev,
                ...(value ? { [key]: value.toString() } : {})
            }),
            {}
        );

    const paramsString =
        filteredParams && new URLSearchParams(filteredParams).toString();

    return fetchAPIAsync<ReturnModel>(
        `${path}${paramsString ? `?${paramsString}` : ''}`,
        init,
        false
    );
}

function apiDataFunction<
    ErrorReturn = any,
    ReturnModel = any,
    RequestModel = any
>(
    method: string
): (
    path: string,
    body?: RequestModel | FormData,
    init?: Omit<RequestProps, 'method'>,
    showMessage?: boolean
) => FetchCallback<ReturnModel, RequestModel, ErrorReturn> {
    return (path, body, init, showMessage) => {
        const isFormData = body instanceof FormData;

        return fetchAPI<ReturnModel, RequestModel, ErrorReturn>(
            path,
            {
                body: isFormData ? body : JSON.stringify(body),
                method,
                ...(init || {})
            },
            showMessage
        );
    };
}

function apiDataAsyncFunction<ReturnModel = any, RequestModel = any>(
    method: string
): (
    path: string,
    body?: RequestModel | FormData,
    init?: Omit<RequestProps, 'method'>,
    showMessage?: boolean
) => Promise<ReturnModel> {
    return (path, body, init, showMessage) => {
        const isFormData = body instanceof FormData;

        return fetchAPIAsync<ReturnModel>(
            path,
            {
                body: isFormData ? body : JSON.stringify(body),
                method,
                ...(init || {})
            },
            showMessage
        );
    };
}

const rawPost = apiDataFunction('POST');

export function post<ErrorReturn = any, ReturnModel = any, RequestModel = any>(
    path: string,
    body: RequestModel,
    init?: Omit<RequestProps, 'method'>,
    showMessage?: boolean
): FetchCallback<ReturnModel, RequestModel, ErrorReturn> {
    return rawPost(path, body, init, showMessage);
}

export function postWithToast<
    ErrorReturn = any,
    ReturnModel = any,
    RequestModel = any
>(path: string, body: RequestModel, init?: Omit<RequestProps, 'method'>) {
    return post<ErrorReturn, ReturnModel, RequestModel>(path, body, init, true);
}

const rawPut = apiDataFunction('PUT');

const rawDelete = apiDataFunction('DELETE');

const rawDeleteAsync = apiDataAsyncFunction('DELETE');

export function put<ErrorReturn = any, ReturnModel = any, RequestModel = any>(
    path: string,
    body?: RequestModel,
    init?: Omit<RequestProps, 'method'>,
    showMessage?: boolean
): FetchCallback<ReturnModel, RequestModel, ErrorReturn> {
    return rawPut(path, body, init, showMessage);
}

export function putWithToast<
    ErrorReturn = any,
    ReturnModel = any,
    RequestModel = any
>(path: string, body?: RequestModel, init?: Omit<RequestProps, 'method'>) {
    return put<ErrorReturn, ReturnModel, RequestModel>(path, body, init, true);
}

export function fetchDelete<
    ErrorReturn = any,
    ReturnModel = any,
    RequestModel = any
>(
    path: string,
    body?: RequestModel,
    init?: Omit<RequestProps, 'method'>,
    showMessage?: boolean
): FetchCallback<ReturnModel, RequestModel, ErrorReturn> {
    return rawDelete(path, body, init, showMessage);
}

export function fetchDeleteAsync<ReturnModel = any, RequestModel = any>(
    path: string,
    body?: RequestModel,
    init?: Omit<RequestProps, 'method'>,
    showMessage?: boolean
): Promise<ReturnModel> {
    return rawDeleteAsync(path, body, init, showMessage);
}

export function deleteWithToast<
    ErrorReturn = any,
    ReturnModel = any,
    RequestModel = any
>(
    path: string,
    body?: RequestModel,
    init?: Omit<RequestProps, 'method'>
): FetchCallback<ReturnModel, RequestModel, ErrorReturn> {
    return rawDelete(
        path,
        body,
        init,
        true // Показывать уведомления
    );
}

export function deleteWithToastAsync<ReturnModel = any, RequestModel = any>(
    path: string,
    body?: RequestModel,
    init?: Omit<RequestProps, 'method'>
): Promise<ReturnModel> {
    return rawDeleteAsync(path, body, init, true);
}
