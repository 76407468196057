import { PopupChildrenRenderProps } from 'components/Popup';
import { FormApi } from 'final-form';
import { FC, useCallback } from 'react';

import Button from 'ui/Button/Button';
import { Form } from 'ui/FormContainer/FormContainer';
import FormField from 'ui/FormField/FormField';
import {
    CreateDisciplineFormData,
    DisciplineInfo
} from 'api/disciplines/disciplines.types';
import { createDiscipline } from 'api/disciplines/disciplines';
import { SelectField } from 'ui/Select';
import { disciplineTypeOptions } from 'messages/options';

type FormType = CreateDisciplineFormData;

const PopupField = FormField<FormType>;
const PopupSelect = SelectField<FormType>;

export type OnCreateFunc = (value: DisciplineInfo) => void;

type CreateDisciplinePopupProps = PopupChildrenRenderProps & {
    onCreate: OnCreateFunc;
};

export const CreateDisciplinePopupContent: FC<CreateDisciplinePopupProps> = ({
    close,
    onCreate
}) => {
    const onSubmit = useCallback(
        (data: FormType, form: FormApi<FormType>) => {
            createDiscipline(data)((discipline) => {
                if (!discipline.data) {
                    throw new Error(
                        `Undefined discipline's data in API's return`
                    );
                }

                onCreate(discipline.data);
                close();
                form.restart();
            });
        },
        [close, onCreate]
    );

    return (
        <Form onSubmit={onSubmit}>
            {({ submitting }) => (
                <>
                    <PopupField
                        name="title"
                        label="Название"
                        placeholder="Название дисциплины"
                        required
                    />

                    <PopupSelect
                        label="Вид обучения"
                        name="type"
                        options={disciplineTypeOptions}
                        required
                    />

                    <PopupField
                        name="hoursCount"
                        label="Количество часов"
                        type="number"
                        placeholder="Количество часов"
                        required
                    />

                    <Button type="submit" disabled={submitting}>
                        Добавить
                    </Button>
                </>
            )}
        </Form>
    );
};
