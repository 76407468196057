import withContainer from 'components/Container/withContainer';
import { BigHeadline, CenterContainer, TextLNormal } from 'ui/Styled/Styled';

export const NotFoundPageContent = () => {
    return (
        <CenterContainer>
            <BigHeadline>Страница не найдена</BigHeadline>
            <TextLNormal>
                Проверьте URL-адрес или повторите попытку позже.
            </TextLNormal>
        </CenterContainer>
    );
};

export default withContainer(NotFoundPageContent);
