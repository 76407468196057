import { EditForm } from 'ui/EditForm';
import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import Button from 'ui/Button/Button';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { editStudent, getStudentByIdForUpdate } from 'api/students/students';
import { FileFieldContainer, FormRow } from 'ui/Form/Form.styled';
import { STUDENT_EDIT_PROPS } from '../constants';
import { AddressField } from 'components/fields/AddressField';
import { useWithId } from 'hooks/useId';
import { UpdateStudentFormData } from 'api/students/students.types';
import { OrganizationField } from 'components/fields/OrganizationField';
import { SelectField } from 'ui/Select';
import { educationTypeOptions } from 'messages/options';

type Data = UpdateStudentFormData;

const Field = FormField<Data>;
const Select = SelectField<Data>;

const StudentEditForm = () => {
    const getter = useWithId(getStudentByIdForUpdate);

    return (
        <EditForm
            getter={getter}
            saver={editStudent}
            successMessage="Профиль успешно обновлен"
        >
            {({ submitting }) => (
                <>
                    <Headline>Редактировать студента</Headline>
                    <FormRow>
                        <Field
                            name="surname"
                            label="Фамилия"
                            placeholder="Иванов"
                            required
                        />
                        <Field
                            name="name"
                            label="Имя"
                            placeholder="Иван"
                            required
                        />
                        <Field
                            name="lastname"
                            label="Отчество"
                            placeholder="Иванович"
                            required
                        />
                    </FormRow>
                    <FormRow>
                        <Field
                            name="birthday"
                            type="date"
                            label="Дата рождения"
                            placeholder="23.08.1999"
                            required
                        />
                        <Field
                            name="phone"
                            type="tel"
                            label="Номер телефона"
                            placeholder="+79999999999"
                            required
                        />
                    </FormRow>

                    <Field name="email" type="email" label="E-mail" required />
                    <OrganizationField setCurrent={false} />

                    <AddressField />
                    <Field name="rank" required type="number" label="Разряд" />
                    <Field
                        type="file"
                        name="avatar"
                        label="Аватар"
                        fileType="image"
                    />

                    <FormRow>
                        <FormRow>
                            <Field
                                name="passportSeries"
                                required
                                label="Паспорт"
                                placeholder="Серия паспорта"
                            />
                            <Field
                                name="passportNumber"
                                required
                                label="&nbsp;"
                                placeholder="Номер паспорта"
                            />
                        </FormRow>
                        <FileFieldContainer>
                            <Field type="file" name="passport" />
                        </FileFieldContainer>
                    </FormRow>

                    <FormRow>
                        <Field
                            name="diplomNumber"
                            required
                            label="Документ об образовании"
                            placeholder="Номер диплома"
                        />

                        <Select
                            name="educationType"
                            // required
                            label="Вид образования"
                            options={educationTypeOptions}
                        />
                        <FileFieldContainer>
                            <Field type="file" name="educationalDocument" />
                        </FileFieldContainer>
                    </FormRow>

                    <FormRow>
                        <Field
                            name="snilsNumber"
                            required
                            label="СНИЛС"
                            placeholder="Номер снилс"
                            type="snils"
                        />
                        <FileFieldContainer>
                            <Field type="file" name="snils" />
                        </FileFieldContainer>
                    </FormRow>

                    <FormRow>
                        <Field
                            name="qualification"
                            required
                            label="Документ о повышении квалификации"
                            placeholder="Номер документа"
                        />
                        <FileFieldContainer>
                            <Field type="file" name="qualificationDocument" />
                        </FileFieldContainer>
                    </FormRow>
                    <ButtonsContainer>
                        <Button type="submit" disabled={submitting}>
                            Сохранить
                        </Button>
                    </ButtonsContainer>
                </>
            )}
        </EditForm>
    );
};

export default withAuth(withContainer(StudentEditForm, STUDENT_EDIT_PROPS));
