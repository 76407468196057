import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { BlueTextM, GrayTextM, Headline } from '../../ui/Styled/Styled';
import { AssignedCourse } from 'api/courses/courses.types';
import { getCourseAssignment } from 'api/courses/courses';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Skeleton from 'components/Skeleton/Skeleton';
import { getStudentFullname } from 'utils/fullname';
import * as S from './Documents.styled';
import { S as TableS } from 'ui/Table';
import { formatDate } from 'utils/date';
import { getTranslated } from 'messages';
import Button from 'ui/Button/Button';
import { RouterPaths } from 'helpers/router-paths';
import { useNumberId } from 'hooks/useId';
import { DOCUMENTS_PROPS } from './constants';

const DocumentsList = () => {
    const id = useNumberId();
    const [assignment, setAssignment] = useState<AssignedCourse>();

    useEffect(() => {
        getCourseAssignment(id).then(setAssignment);
    }, [id]);

    if (!assignment) {
        return <Skeleton />;
    }

    return (
        <>
            <Headline>{assignment.course?.title}</Headline>
            <Headline>{getStudentFullname(assignment.student)}</Headline>
            <S.DatesRow>
                <GrayTextM>
                    Дата начала обучения:{' '}
                    <BlueTextM as="span">
                        {formatDate(assignment.dateStartLearning)}
                    </BlueTextM>
                </GrayTextM>
                <GrayTextM>
                    Дата окончания обучения:{' '}
                    <BlueTextM as="span">
                        {formatDate(assignment.dateEndLearning)}
                    </BlueTextM>
                </GrayTextM>
            </S.DatesRow>
            <TableS.Contaner>
                <TableS.Body>
                    {assignment.course?.issuedDocuments?.map((document) => (
                        <TableS.RowContainer key={document}>
                            <Link to={RouterPaths.Document(document, id)}>
                                <TableS.Row>
                                    <S.DocumentRowLabel>
                                        {getTranslated(
                                            'issuedDocuments',
                                            document
                                        )}
                                    </S.DocumentRowLabel>
                                    <S.DocumentRowButton>
                                        <Button>Подробнее</Button>
                                    </S.DocumentRowButton>
                                </TableS.Row>
                            </Link>
                        </TableS.RowContainer>
                    ))}
                </TableS.Body>
            </TableS.Contaner>
        </>
    );
};

export default withAuth(withContainer(DocumentsList, DOCUMENTS_PROPS));
