import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import { Form } from 'ui/FormContainer/FormContainer';

import FormField from '../../ui/FormField/FormField';
import { CreateOrganizationFormData } from '../../api/organizations/organizations.types';

import { createOrganization } from '../../api/organizations/organizations';
import Button from '../../ui/Button/Button';
import { makeCreateFormFunc } from 'utils/api';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import { AddressField } from 'components/fields/AddressField';

const Field = FormField<CreateOrganizationFormData>;

const handleFormSubmit = makeCreateFormFunc(
    createOrganization,
    'Организация создана!'
);

const CreateOrganization = () => {
    return (
        <>
            <Headline>Создать организацию</Headline>
            <Form onSubmit={handleFormSubmit}>
                {({ submitting }) => (
                    <>
                        <Field
                            name="title"
                            label="Название организации"
                            placeholder='ООО "Курсовед"'
                            required
                        />
                        <Field
                            name="phone"
                            type="tel"
                            label="Номер телефона"
                            required
                        />
                        <Field
                            name="email"
                            type="email"
                            label="E-mail"
                            required
                        />
                        <Field
                            name="inn"
                            type="inn"
                            required
                            label="ИНН"
                            placeholder="ИНН"
                        />
                        <AddressField />
                        <Field
                            name="bankAccountNumber"
                            type="number"
                            maxLength={20}
                            minLength={20}
                            required
                            label="Номер банковского счета (для выставления счетов)"
                            placeholder="40702810200210000237"
                        />
                        <ButtonsContainer>
                            <Button type="submit" disabled={submitting}>
                                Создать
                            </Button>
                        </ButtonsContainer>
                    </>
                )}
            </Form>
        </>
    );
};

export default withAuth(withContainer(CreateOrganization));
