import React, { FC, useCallback, useContext } from 'react';
import { Headline } from '../../ui/Styled/Styled';

import Button from '../../ui/Button/Button';
import { Form } from 'ui/FormContainer/FormContainer';

import { RouterPaths } from 'helpers/router-paths';
import { AuthContext } from '../../context/Auth';
import { login } from '../../api/auth/auth';
import { useNavigate } from 'react-router-dom';
import withContainer from '../../components/Container/withContainer';
import FormField from '../../ui/FormField/FormField';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../messages';
import { AuthFormData } from '../../api/auth/auth.types';

const Field = FormField<AuthFormData>;

const AuthPage: FC = () => {
    const { refresh } = useContext(AuthContext);
    const navigate = useNavigate();

    const onSubmit = useCallback(
        (props: AuthFormData) =>
            login(props)(async () => {
                const authorized = await refresh();
                if (authorized) {
                    navigate(RouterPaths.Home);
                } else {
                    toast.error(getErrorMessage());
                }
            }),
        [refresh, navigate]
    );

    return (
        <>
            <Headline>Вход в систему</Headline>
            <Form onSubmit={onSubmit}>
                {({ submitting }) => (
                    <>
                        <Field
                            name="login"
                            type="email"
                            required
                            label="E-mail"
                        />
                        <Field
                            name="password"
                            type="password"
                            required
                            label="Пароль"
                        />
                        <Button type="submit" disabled={submitting}>
                            Войти
                        </Button>
                    </>
                )}
            </Form>
        </>
    );
};

export default withContainer(AuthPage);
