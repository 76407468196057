import { FC, useCallback, useContext } from 'react';
import { Headline } from '../../ui/Styled/Styled';

import Button from '../../ui/Button/Button';
import { Form } from 'ui/FormContainer/FormContainer';

import { RouterPaths } from 'helpers/router-paths';
import { AuthContext } from '../../context/Auth';
import { signupOrg } from '../../api/auth/auth';
import { useNavigate } from 'react-router-dom';
import withContainer from '../../components/Container/withContainer';
import FormField from '../../ui/FormField/FormField';
import { RegisterOrgFormData } from '../../api/auth/auth.types';
import { AddressField } from 'components/fields/AddressField';

const Field = FormField<RegisterOrgFormData>;

const RegisterOrgPage: FC = () => {
    const { refresh } = useContext(AuthContext);
    const navigate = useNavigate();

    const onSubmit = useCallback(
        (props: RegisterOrgFormData) =>
            signupOrg(props)(async () => {
                const authorized = await refresh();
                if (authorized) {
                    navigate(RouterPaths.Home);
                }
            }),
        [refresh, navigate]
    );

    return (
        <>
            <Headline>Регистрация организации</Headline>
            <Form onSubmit={onSubmit}>
                {({ submitting }) => (
                    <>
                        <Field
                            name="organization.title"
                            required
                            label="Название организации"
                            placeholder='ООО "Курсовед"'
                        />
                        <Field
                            name="organization.phone"
                            type="tel"
                            required
                            label="Контактный телефон организации"
                            placeholder="+7 (999) 999-99-99"
                        />
                        <Field
                            name="organization.email"
                            type="email"
                            required
                            label="E-mail организации"
                        />
                        <Field
                            name="organization.inn"
                            type="inn"
                            required
                            label="ИНН"
                            placeholder="ИНН"
                        />

                        <AddressField<RegisterOrgFormData> name="organization.address" />

                        <Field
                            name="organization.bankAccountNumber"
                            type="number"
                            maxLength={20}
                            minLength={20}
                            required
                            label="Номер банковского счета (для выставления счетов)"
                            placeholder="40702810200210000237"
                        />
                        <Headline>Контактное лицо организации:</Headline>
                        <Field
                            name="operator.surname"
                            required
                            label="Фамилия"
                            placeholder="Иванов"
                        />
                        <Field
                            name="operator.name"
                            required
                            label="Имя"
                            placeholder="Иван"
                        />
                        <Field
                            name="operator.lastName"
                            type="text"
                            required
                            label="Отчество"
                            placeholder="Иванов"
                        />
                        <Field
                            name="operator.phone"
                            type="tel"
                            required
                            label="Телефон"
                            placeholder="+7 (999) 999-99-99"
                        />
                        <Field
                            name="operator.email"
                            type="email"
                            required
                            label="Email"
                        />
                        <Headline>Данные для авторизации</Headline>
                        <Field
                            name="operator.password"
                            type="password"
                            required
                            label="Пароль"
                        />
                        <Field
                            name="operator.confirmPassword"
                            type="password"
                            required
                            label="Повторите пароль"
                        />
                        <Button type="submit" disabled={submitting}>
                            Создать
                        </Button>
                    </>
                )}
            </Form>
        </>
    );
};

export default withContainer(RegisterOrgPage);
