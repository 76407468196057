import { OrganizationId } from 'api/organizations/organizations.types';
import {
    postWithToast,
    get,
    put,
    deleteWithToastAsync
} from '../../utils/fetch';
import * as Types from './committees.types';
import { getCurrentOrganizationId } from 'context/User';
import { OptionProps } from 'ui/Select/Select.types';

export const createCommittees = (props: Types.CreateCommitteeFormData) =>
    postWithToast('Committee/CreateCommittee', props);

export const getOrganizationCommittees = (
    organizationId: OrganizationId = getCurrentOrganizationId()
) =>
    get<Types.CommitteesInfo[]>(
        `Committee/GetCommittiesByOrgId/${organizationId}`
    );

export const getCommitteesOptions = (organizationId?: OrganizationId) =>
    getOrganizationCommittees(organizationId).then((items) =>
        items.map<OptionProps<number>>((item) => ({
            label: item.title,
            value: item.id
        }))
    );

export const getCommitteesTitleOptions = (organizationId?: OrganizationId) =>
    getOrganizationCommittees(organizationId).then((items) =>
        items.map<OptionProps>((item) => ({
            value: item.title
        }))
    );

export const getCommitteeById = (id: number) =>
    get<Types.CommitteeFullInfo>(`Committee/${id}`);

export const updateCommittee = (props: Types.CreateCommitteeFormData) =>
    put('Committee/UpdateCommittee', props);

export const deleteCommittees = (ids: number[]) =>
    deleteWithToastAsync('Committee/RemoveCommitties', ids);
