import {
    createBrowserRouter,
    Route,
    createRoutesFromElements
} from 'react-router-dom';
import { RouterPaths } from 'helpers/router-paths';

import AuthPage from './pages/auth/Auth';
import HomePage from './pages/Home/Home';
import RegisterUser from './pages/register/User';
import RegisterOrg from './pages/register/Org';
import FindStudents from './pages/students/List';
import CreateStudent from './pages/students/Create';
import CreateOrganization from './pages/organizations/Create';
import OrganizationsList from './pages/organizations/List';
import ComissionsList from './pages/comissions/List';
import CreateComission from './pages/comissions/Create';
import CoursesAssignments from './pages/assigned-courses/List';
import CreateCourse from './pages/courses/Create';
import CourseList from './pages/courses/List';
import CreateOperatorPage from './pages/operators/Create';
import SetPassword from './pages/setPassword/SetPassword';
import ComissionPage from './pages/comissions/Single';
import AssignCoursePage from './pages/courses/Assign';
import ProfileStudent from 'pages/StudentInfo/StudentInfo';
import StudentEditForm from 'pages/profile/editFoms/StudentProfileEdit';
import OperatorEditForm from 'pages/profile/editFoms/OperatorProfileEdit';
import ProfileOrganization from 'pages/profile/ProfileOrganization';
import OrganizationEdit from 'pages/profile/editFoms/OrganizationProfileEdit';
import CourseEditForm from 'pages/courses/Edit';
import Certificate from 'pages/documents/forms/Certificate';
import TicketBForm from 'pages/documents/forms/TicketB';
import DocumentsList from 'pages/documents/List';
import DocumentsListStatic from 'pages/documents/ListStatic';
import {
    AssignmentDocumentType,
    StaticDocument
} from 'api/documents/documents.types';
import Diplom from 'pages/documents/forms/Diplom';
import CertificateOfStudyForm from 'pages/documents/forms/CertificateOfStudy';
import PaymentInvoice from 'pages/documents/forms/PaymentInvoice';
import ProfileOperator from 'pages/profile/ProfileOperator';
import TicketM from 'pages/documents/forms/TicketM';
import NotFoundPage from 'pages/404/404';

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="*" Component={NotFoundPage} />
            <Route path={RouterPaths.Auth} Component={AuthPage} />
            <Route path={RouterPaths.RegisterUser} Component={RegisterUser} />
            <Route path={RouterPaths.RegisterOrg} Component={RegisterOrg} />

            <Route path={RouterPaths.Home} Component={HomePage} />
            <Route path={RouterPaths.FindStudents} Component={FindStudents} />
            <Route
                path={RouterPaths.CreateStudents}
                Component={CreateStudent}
            />
            <Route
                path={RouterPaths.CreateOrganization}
                Component={CreateOrganization}
            />
            <Route
                path={RouterPaths.FindOrganization}
                Component={OrganizationsList}
            />
            <Route path={RouterPaths.CreateCourse} Component={CreateCourse} />
            <Route path={RouterPaths.FindCourse} Component={CourseList} />
            <Route
                path={RouterPaths.AssignCourse}
                Component={AssignCoursePage}
            />
            <Route
                path={RouterPaths.FindComission}
                Component={ComissionsList}
            />
            <Route
                path={RouterPaths.CreateComission}
                Component={CreateComission}
            />
            <Route
                path={RouterPaths.ComissionPage()}
                Component={ComissionPage}
            />
            <Route
                path={RouterPaths.CoursesAssignments}
                Component={CoursesAssignments}
            />

            <Route
                path={RouterPaths.CourseAssignment()}
                Component={DocumentsList}
            />

            {/* <Route path={RouterPaths.Settings} Component={Settings } /> */}
            <Route path={RouterPaths.Profile} Component={ProfileOperator} />
            <Route
                path={RouterPaths.CreateOperator}
                Component={CreateOperatorPage}
            />
            <Route path={RouterPaths.SetPassword} Component={SetPassword} />
            <Route
                path={RouterPaths.StudentProfile()}
                Component={ProfileStudent}
            />
            <Route
                path={RouterPaths.OrganizationProfile()}
                Component={ProfileOrganization}
            />
            <Route
                path={RouterPaths.OrganizationEdit()}
                Component={OrganizationEdit}
            />
            <Route path={RouterPaths.CourseEdit()} Component={CourseEditForm} />
            <Route
                path={RouterPaths.Document(AssignmentDocumentType.Certificate)}
                Component={Certificate}
            />
            <Route
                path={RouterPaths.Document(AssignmentDocumentType.TicketB)}
                Component={TicketBForm}
            />
            <Route
                path={RouterPaths.Document(AssignmentDocumentType.Diplom)}
                Component={Diplom}
            />

            <Route
                path={RouterPaths.StudentEdit()}
                Component={StudentEditForm}
            />
            <Route
                path={RouterPaths.OperatorEdit()}
                Component={OperatorEditForm}
            />

            <Route
                path={RouterPaths.Documents}
                Component={DocumentsListStatic}
            />

            {/* Статические документы */}
            <Route
                path={RouterPaths.StaticDocument(
                    StaticDocument.CertificateOfStudy
                )}
                Component={CertificateOfStudyForm}
            />
            <Route
                path={RouterPaths.StaticDocument(StaticDocument.TicketM)}
                Component={TicketM}
            />
            <Route
                path={RouterPaths.StaticDocument(StaticDocument.PaymentInvoice)}
                Component={PaymentInvoice}
            />
        </>
    )
);

export default router;
