import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import { Form } from 'ui/FormContainer/FormContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { generateInvoice } from 'api/documents/documents';
import { makeCreateFormFunc } from 'utils/api';
import { FormRow } from 'ui/Form/Form.styled';
import { GenerateWordField, STATIC_DOCUMENTS_PROPS } from '../constants';
import { PaymentInvoiceFormData } from 'api/documents/documents.types';
import { OrganizationTitleField } from 'components/fields/OrganizationField';

type Data = PaymentInvoiceFormData;

const Field = FormField<Data>;

const handleFormSubmit = makeCreateFormFunc(generateInvoice, 'Файл загружен');

const PaymentInvoiceForm = () => {
    return (
        <Form onSubmit={handleFormSubmit}>
            {() => (
                <>
                    <Headline>Счет на оплату</Headline>

                    <FormRow>
                        <Field
                            name="dateStart"
                            label="Дата начала обучения"
                            type="date"
                        />
                        <Field
                            name="dateEnd"
                            label="Дата окончания обучения"
                            type="date"
                        />
                    </FormRow>
                    <Field name="number" label="Номер" />
                    <OrganizationTitleField />

                    <GenerateWordField />
                </>
            )}
        </Form>
    );
};

export default withAuth(
    withContainer(PaymentInvoiceForm, STATIC_DOCUMENTS_PROPS)
);
