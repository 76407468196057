/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormApi } from 'final-form';
import { FetchCallback } from './fetch';
import { toast } from 'react-toastify';

export function makePromisable<RequestModel, ReturnModel>(
    func: (data: RequestModel) => FetchCallback<ReturnModel, RequestModel>,
    successMessage: string | null = 'Успех!'
) {
    return (data: RequestModel) =>
        new Promise<ReturnModel>((resolve, reject) => {
            func(data)(
                (res) => {
                    if (successMessage) {
                        toast.success(successMessage);
                    }
                    resolve(res.data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
}
export function makeCreateFormFunc<RequestModel>(
    func: (data: RequestModel) => FetchCallback<any, RequestModel>,
    successMessage = 'Успех!'
) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (data: RequestModel, form: FormApi<RequestModel>) =>
        func(data)(() => {
            toast.success(successMessage);
        });
}

export function makeEditFormFunc<RequestModel>(
    func: (data: RequestModel) => FetchCallback<any, RequestModel>,
    successMessage = 'Успех!'
) {
    return (data: RequestModel) =>
        func(data)(() => {
            toast.success(successMessage);
        });
}
