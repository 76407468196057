import { useCallback } from 'react';
import { EditForm } from 'ui/EditForm';
import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import Button from 'ui/Button/Button';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { CreateCourseFormData } from '../../api/courses/courses.types';
import { editCourse, getCourseById } from '../../api/courses/courses';
import {
    assignedQualificationOptions,
    trainingTypeOptions,
    issuedDocumentsOptions
} from 'messages/options';
import { SelectField } from '../../ui/Select';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import { getCommitteesOptions } from 'api/committees/committees';
import { getProfessionsOptions } from 'api/professions/professions';
import { useNumberId } from 'hooks/useId';
import { RouterPaths } from 'helpers/router-paths';
import { formatCourseInfoForForm } from 'api/courses/courses.mapper';
import { DisciplineField } from './components/DisciplineField';
import {
    AssignedQualificationType,
    OrganizationType,
    TrainingType
} from 'api/api';

const Select = SelectField<CreateCourseFormData>;
const Field = FormField<CreateCourseFormData>;

const CourseEditForm = () => {
    const id = useNumberId();

    const getter = useCallback(
        () => getCourseById(id).then(formatCourseInfoForForm),
        [id]
    );

    return (
        <EditForm
            getter={getter} // Получаем данные организации
            saver={editCourse} // Сохраняем изменения
            successMessage="Курс успешно обновлен"
        >
            {({
                submitting,
                values: { trainingType, assignedQualification }
            }) => (
                <>
                    <Headline>Редактировать курс</Headline>
                    <Select
                        name="trainingType"
                        label="Вид обучения"
                        options={trainingTypeOptions}
                        required
                    />
                    <Field
                        name="title"
                        label="Название курса"
                        placeholder="Название курса"
                        required
                    />

                    {(trainingType === TrainingType.ProfessionalTraining ||
                        trainingType === TrainingType.AdvancedTraining) && (
                        <>
                            <Select
                                name="professionId"
                                label="Название профессии (из справочника)"
                                placeholder="Выберите профессию"
                                loader={getProfessionsOptions}
                                onSearch={getProfessionsOptions}
                            />
                            <Field
                                name="rank"
                                label="Разраяд"
                                placeholder="Введите число"
                                type="number"
                            />
                        </>
                    )}

                    {trainingType === TrainingType.ProfessionalRetraining && (
                        <Select
                            name="assignedQualification"
                            label="Присваемая квалификация"
                            options={assignedQualificationOptions}
                        />
                    )}
                    {assignedQualification ===
                        AssignedQualificationType.MayBeAdmitted && (
                        <Field
                            name="subTitle"
                            label="Содержание курса"
                            placeholder="Содержание курса"
                            required
                        />
                    )}
                    <DisciplineField />
                    {trainingType === TrainingType.AdvancedTraining && (
                        <Field
                            name="validityPeriodDocument"
                            label="Срок действия документа (кол-во лет)"
                            placeholder="Введите число"
                            type="number"
                        />
                    )}
                    <Select
                        name="issuedDocuments"
                        required
                        multiple
                        label="Выдаваемый документ"
                        options={issuedDocumentsOptions}
                    />
                    <Select // выпадашка
                        name="committeeId"
                        required
                        label="Комиссия"
                        loader={getCommitteesOptions}
                    />

                    <Field
                        name="price"
                        label="Стоимость обучения"
                        placeholder="Введите стоимость"
                        required
                        min="0"
                        type="number"
                    />
                    <ButtonsContainer>
                        <Button type="submit" disabled={submitting}>
                            Создать
                        </Button>
                    </ButtonsContainer>
                </>
            )}
        </EditForm>
    );
};

export default withAuth(
    withContainer(CourseEditForm, {
        parentPath: RouterPaths.FindCourse,
        additionalItem: { text: 'Редактировать курс', path: '' }
    }),
    undefined,
    undefined,
    OrganizationType.EducationCenter
);
