import { OrganizationId } from 'api/organizations/organizations.types';
import { get, postWithToast } from '../../utils/fetch';
import * as Types from './disciplines.types';
import { ItemsEntity } from 'typings/pagination';
import { getCurrentOrganizationId } from 'context/User';
import { OptionsGenerator } from 'ui/Select/Select.types';
import { disciplineTypes } from 'messages/ru';

export const getOrganizationDisciplines = (
    organizationId: OrganizationId = getCurrentOrganizationId()
) =>
    get<ItemsEntity<Types.DisciplineInfo>>(
        `Discipline/GetDisciplineListByOrgId/${organizationId}`
    ).then(({ items }) => items);

export const getOrganizationDisciplinesOptions: OptionsGenerator<
    typeof getOrganizationDisciplines,
    number
> = (organizationId?: OrganizationId) =>
    getOrganizationDisciplines(organizationId).then((items) =>
        items.map((item) => ({
            value: item.id,
            data: item,
            label:
                item.title + (item.type && ` (${disciplineTypes[item.type]})`)
        }))
    );

export const createDiscipline = (
    props: Types.CreateDisciplineFormData,
    organizationId: OrganizationId = getCurrentOrganizationId()
) =>
    postWithToast<
        unknown,
        Types.DisciplineInfo,
        Types.CreateDisciplineFormData
    >('Discipline/AddDiscipline', {
        ...props,
        disciplineListId:
            typeof organizationId === 'number' ? organizationId : null
    });
