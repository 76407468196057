/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import * as S from './Table.styled';
import { getTranslated, TranslateKey, Translates } from 'messages';
import { makeArray, toggleInArray } from 'utils/arrays';
import Skeleton from 'components/Skeleton/Skeleton';
import {
    ColumnProps,
    IdProps,
    RowFormattedProps,
    TableProps
} from './Table.types';
import { isColumnOptions } from './utils';
import { getIn } from 'utils/getIn';

export function Table<
    RowData extends IdProps<IdValue>,
    Columns extends ColumnProps<RowData> = ColumnProps<RowData>,
    IdColumn extends keyof RowData = 'id',
    IdValue extends RowData[IdColumn] = RowData[IdColumn]
>({
    columns,
    rows: rawRows,
    showHead = true,
    parseColumns,
    translateColumns,
    noRowsMsg = 'Нет данных',
    loading,
    idColumn = 'id' as any,
    linkGetter,
    paddingTop,
    afterRowContent,
    beforeRowContent,
    onRowSelect,
    selectedRows,
    editMode
}: TableProps<RowData, Columns, IdColumn, IdValue>): JSX.Element {
    const rows = useMemo(
        () =>
            rawRows?.map((row, index) => {
                const { link, ...formattedRow } = Object.keys(columns).reduce<
                    RowFormattedProps<RowData>
                >((prev, rawKey) => {
                    const key = rawKey as keyof Columns;
                    const value = getIn(row, rawKey as Paths<RowData>);

                    const formattedValue = translateColumns?.[key]
                        ? makeArray(value).map((val) => (
                              <>
                                  <S.CellListValue key={rawKey}>
                                      {getTranslated(
                                          translateColumns[key] as TranslateKey,
                                          val as keyof Translates[TranslateKey]
                                      )}
                                  </S.CellListValue>
                              </>
                          ))
                        : value;

                    return {
                        ...prev,
                        [key]:
                            parseColumns?.[key]?.(
                                formattedValue as DeepIdx<RowData, any>,
                                row,
                                value as DeepIdx<RowData, any>
                            ) || formattedValue
                    };
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                }, {} as any);

                const id = row[idColumn] as IdValue;

                const { onClick } = row;

                const handleClick = () => {
                    onClick?.();
                    if (editMode) {
                        onRowSelect?.(
                            toggleInArray(id, selectedRows ?? []),
                            id
                        );
                    }
                };

                const finalLink = link || linkGetter?.(id, row);

                const contentWithLink = (() => {
                    const content = (
                        <S.Row onClick={handleClick}>
                            {beforeRowContent?.(id, row)}

                            {editMode && (
                                <S.CheckboxContainer>
                                    <S.Checkbox
                                        $active={
                                            selectedRows?.includes(id) ?? false
                                        }
                                    />
                                </S.CheckboxContainer>
                            )}

                            {Object.keys(columns).map((key: any) => (
                                <S.Cell
                                    className={`td-${key}`}
                                    data-key={key}
                                    key={key}
                                >
                                    {(formattedRow as any)[key]}
                                </S.Cell>
                            ))}
                        </S.Row>
                    );

                    return finalLink ? (
                        <S.RowLink to={finalLink}>{content}</S.RowLink>
                    ) : (
                        content
                    );
                })();

                return (
                    <S.RowContainer key={index}>
                        {contentWithLink}
                        {afterRowContent?.(row[idColumn] as IdValue, row)}
                    </S.RowContainer>
                );
            }),
        [
            rawRows,
            columns,
            idColumn,
            linkGetter,
            afterRowContent,
            translateColumns,
            parseColumns,
            editMode,
            onRowSelect,
            selectedRows,
            beforeRowContent
        ]
    );

    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <S.Contaner $columns={columns} $paddingTop={paddingTop}>
                    {showHead && (
                        <S.Head>
                            <S.HeadRow>
                                {Object.entries(columns).map(([key, value]) => (
                                    <S.HeadingCell
                                        className={`th-${key}`}
                                        key={key}
                                    >
                                        {isColumnOptions(value)
                                            ? value.title
                                            : String(value)}
                                    </S.HeadingCell>
                                ))}
                            </S.HeadRow>
                        </S.Head>
                    )}
                    {rows?.length ? (
                        <S.Body>{rows}</S.Body>
                    ) : (
                        <S.NoRowsContainer>{noRowsMsg}</S.NoRowsContainer>
                    )}
                </S.Contaner>
            )}
        </>
    );
}
