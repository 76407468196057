import {
    CertificateEducationDocModel,
    ContractDocModel,
    CreateCertificateDocModel,
    DocumentType,
    TicketBModel,
    TicketMModel
} from 'api/api';

export type CertificateGenerateData = CreateCertificateDocModel;
export type CertificateEducationFormData = CertificateEducationDocModel;

export { DocumentType as AssignmentDocumentType };

export enum StaticDocument {
    CertificateOfStudy = 'certificate-of-study',
    PaymentInvoice = 'payment-invoice',
    TicketM = 'ticket-m'
}

export type PaymentInvoiceFormData = ContractDocModel;

export type TicketB = TicketBModel;
export type TicketM = TicketMModel;
