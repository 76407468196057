import { EditForm } from 'ui/EditForm';
import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import Button from 'ui/Button/Button';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { UpdateOperator } from 'api/organizations/operators.types';
import { useUser } from 'context/User';
import { OPERATOR_EDIT_PROPS } from '../constants';
import { updateOperator } from 'api/organizations/operators';

const Field = FormField<UpdateOperator>;

const OperatorEditForm = () => {
    const user = useUser();

    return (
        <EditForm
            initialValues={user}
            saver={updateOperator} // Сохраняем изменения
            successMessage="Профиль успешно обновлен"
        >
            {({ submitting }) => (
                <>
                    <Headline>Редактировать оператора</Headline>
                    <Field name="name" label="Имя" placeholder="Имя" required />
                    <Field
                        name="surname"
                        label="Фамилия"
                        placeholder="Фамилия"
                        required
                    />
                    <Field
                        name="lastname"
                        label="Отчество"
                        placeholder="Отчество"
                        required
                    />
                    <Field
                        name="phone"
                        type="tel"
                        label="Номер телефона"
                        placeholder="+79999999999"
                        required
                    />
                    <Field name="email" type="email" label="E-mail" required />
                    <ButtonsContainer>
                        <Button type="submit" disabled={submitting}>
                            Сохранить
                        </Button>
                    </ButtonsContainer>
                </>
            )}
        </EditForm>
    );
};

export default withAuth(withContainer(OperatorEditForm, OPERATOR_EDIT_PROPS));
