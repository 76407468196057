import {
    AssignmentDocumentType,
    StaticDocument
} from 'api/documents/documents.types';

export const ID_PARAM = 'id';

const idParamString = `:${ID_PARAM}`;

export const RouterPaths = {
    Auth: '/auth',
    RegisterUser: '/signup',
    RegisterOrg: '/reg',
    Home: '/',
    FindStudents: '/students/find',
    CreateStudents: '/students/add',
    FindOrganization: '/orgs/find',
    CreateOrganization: '/orgs/add',
    CreateCourse: '/courses/create',
    FindCourse: '/courses',
    AssignCourse: '/courses/assign',
    FindComission: '/comissions/find',
    CreateComission: '/comissions/add',
    CoursesAssignments: '/assigned-courses',
    CourseAssignment: (id?: number) =>
        `/assigned-courses/${id ?? idParamString}`,
    Settings: '/settings',
    Profile: '/profile',
    SetPassword: '/password',
    CreateOperator: '/operators/create',
    Documents: '/documents/static',

    Document: (documentType: AssignmentDocumentType, assignmentId?: number) => {
        const typePath = (() => {
            switch (documentType) {
                default:
                    return documentType.toLowerCase();
            }
        })();

        return `/assigned-courses/${assignmentId ?? idParamString}/${typePath}`;
    },

    OrganizationProfile: (id?: string | number) =>
        `/orgs/${id ?? idParamString}`,
    OrganizationEdit: (id?: string | number) =>
        `/orgs/${id ?? idParamString}/edit`,
    StudentProfile: (id?: Maybe<string>) => `/students/${id ?? idParamString}`,
    CourseEdit: (id?: string | number) =>
        `/courses/${id ?? idParamString}/edit`,
    ComissionPage: (id?: number) => `/comissions/${id ?? idParamString}`,
    StudentEdit: (id?: string | number) =>
        `/students/${id ?? idParamString}/edit`,
    OperatorEdit: (id?: string | number) =>
        `/operator/${id ?? idParamString}/edit`,
    StaticDocument: (documentType: StaticDocument) =>
        `/documents/${documentType}`
};

// export const StudentRouterPaths = {
//     Home: '/lk'
// };
