import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Form } from 'ui/FormContainer/FormContainer';

import { createCourse } from '../../api/courses/courses';
import {
    assignedQualificationOptions,
    trainingTypeOptions,
    issuedDocumentsOptions
} from 'messages/options';
import Button from '../../ui/Button/Button';
import { getCommitteesOptions } from '../../api/committees/committees';
import { makeCreateFormFunc } from 'utils/api';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import { getProfessionsOptions } from 'api/professions/professions';
import { Field, Select } from './components/fields';
import { DisciplineField } from './components/DisciplineField';
import { Headline } from 'ui/Styled/Styled';
import {
    AssignedQualificationType,
    OrganizationType,
    TrainingType
} from 'api/api';

const handleFormSubmit = makeCreateFormFunc(createCourse, 'Курс создан!');

const CreateCourse = () => {
    return (
        <>
            <Headline>Создать курс</Headline>
            <Form onSubmit={handleFormSubmit}>
                {({
                    submitting,
                    values: { trainingType, assignedQualification }
                }) => (
                    <>
                        <Select
                            name="trainingType"
                            label="Вид обучения"
                            options={trainingTypeOptions}
                            required
                        />
                        <Field
                            name="title"
                            label="Название курса"
                            placeholder="Название курса"
                            required
                        />

                        {(trainingType === TrainingType.ProfessionalTraining ||
                            trainingType === TrainingType.AdvancedTraining) && (
                            <>
                                <Select
                                    name="professionId"
                                    label="Название профессии (из справочника)"
                                    placeholder="Выберите профессию"
                                    loader={getProfessionsOptions}
                                    onSearch={getProfessionsOptions}
                                />
                                <Field
                                    name="rank"
                                    label="Разраяд"
                                    placeholder="Введите число"
                                    type="number"
                                />
                            </>
                        )}

                        {trainingType ===
                            TrainingType.ProfessionalRetraining && (
                            <Select
                                name="assignedQualification"
                                label="Присваемая квалификация"
                                options={assignedQualificationOptions}
                            />
                        )}
                        {assignedQualification ===
                            AssignedQualificationType.MayBeAdmitted && (
                            <Field
                                name="subTitle"
                                label="Содержание курса"
                                placeholder="Содержание курса"
                                required
                            />
                        )}
                        <DisciplineField />
                        {trainingType === TrainingType.AdvancedTraining && (
                            <Field
                                name="validityPeriodDocument"
                                label="Срок действия документа (кол-во лет)"
                                placeholder="Введите число"
                                type="number"
                            />
                        )}
                        <Select
                            name="issuedDocuments"
                            required
                            multiple
                            label="Выдаваемый документ"
                            options={issuedDocumentsOptions}
                        />
                        <Select // выпадашка
                            name="committeeId"
                            required
                            label="Комиссия"
                            loader={getCommitteesOptions}
                        />

                        <Field
                            name="price"
                            label="Стоимость обучения"
                            placeholder="Введите стоимость"
                            required
                            min="0"
                            type="number"
                        />
                        <ButtonsContainer>
                            <Button type="submit" disabled={submitting}>
                                Создать
                            </Button>
                        </ButtonsContainer>
                    </>
                )}
            </Form>
        </>
    );
};

export default withAuth(
    withContainer(CreateCourse),
    undefined,
    undefined,
    OrganizationType.EducationCenter
);
