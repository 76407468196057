import React, { FC } from 'react';
import { useUserType } from '../../context/Auth';
import { anonymousMenu, operatorMenu, studentMenu } from './constants';
import MenuItem from './Menuitem/MenuItem';
import { SContainer } from './Menu.styled';
import { useLocation } from 'react-router-dom';
import { ContainerMenuProps } from './Menu.types';
import { UserType } from 'api/api';
import { useOrganizationType } from 'context/User';

const Menu: FC<ContainerMenuProps> = ({
    parentPath,
    additionalItem,
    activeItem
}) => {
    const userType = useUserType();
    const organizationType = useOrganizationType();

    const { items } =
        userType === UserType.Operator
            ? operatorMenu
            : userType === UserType.Student
              ? studentMenu
              : anonymousMenu;

    const { pathname } = useLocation();

    return (
        <SContainer>
            {items.map((item) => {
                if (
                    item.organizationType &&
                    organizationType !== item.organizationType
                ) {
                    return;
                }

                return (
                    <MenuItem
                        key={item.text}
                        pathname={pathname}
                        additionalItem={
                            parentPath === item.path
                                ? additionalItem
                                : undefined
                        }
                        activeItem={activeItem}
                        {...item}
                    />
                );
            })}
        </SContainer>
    );
};

export default Menu;
