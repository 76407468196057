import React, {
    createContext,
    FC,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { RouterPaths } from 'helpers/router-paths';
import { useNavigate } from 'react-router-dom';
import { checkAuth } from '../../api/auth/auth';
import { UserContext } from 'context/User';
import { OrganizationType, UserType } from 'api/api';

type AuthContextProps = {
    userType?: Maybe<UserType>;
    logined?: boolean;
    refresh(): Promise<boolean>;
};

type AuthProviderProps = {
    children: React.ReactNode;
};

const AUTH_LOCAL_STORAGE_KEY = 'auth';

export const AuthContext = createContext<AuthContextProps>({
    refresh: () => Promise.resolve(false)
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [userType, setUserType] = useState<UserType | undefined | null>(
        localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) as
            | UserType
            | undefined
            | null
    );

    const [logined, setLogined] = useState<boolean>();

    const check = async () => {
        try {
            const userType = await checkAuth();

            setUserType(userType);
            localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, userType);

            setLogined(true);

            return true;
        } catch {
            setUserType(null);
            localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);

            setLogined(false);

            return false;
        }
    };

    useEffectOnce(check);

    const providerProps = useMemo(
        () => ({ userType, refresh: check, logined }),
        [logined, userType]
    );

    return (
        <AuthContext.Provider value={providerProps}>
            {children}
        </AuthContext.Provider>
    );
};

export const useUserType = () => {
    const { userType } = useContext(AuthContext);
    return userType;
};

export const useLogined = (type: UserType) => {
    const { userType, logined } = useContext(AuthContext);

    return logined !== false && (userType ? userType === type : undefined);
};

export const useAuth = (
    type: UserType = UserType.Operator,
    organizationType?: OrganizationType
) => {
    const logined = useLogined(type);
    const { user } = useContext(UserContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (
            logined === false ||
            (organizationType &&
                user &&
                user.operator?.organization?.organizationType !==
                    organizationType)
        ) {
            navigate(RouterPaths.Auth);
        }
    }, [logined, navigate, organizationType, user]);

    return { logined: (logined && !!user) || false, user };
};

export const useCheckAuth = () => {
    const { logined, userType } = useContext(AuthContext);
    const { user } = useContext(UserContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (logined === false || (logined && !userType)) {
            navigate(RouterPaths.Auth);
        }
    }, [logined, navigate, userType]);

    return { logined: (logined && !!user) || false, userType, user };
};
