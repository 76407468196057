import { EditForm } from 'ui/EditForm';
import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { TicketBModel } from 'api/api';
import { generateTicketB, getTicketBInfo } from 'api/documents/documents';
import { useWithNumberId } from 'hooks/useId';
import { DOCUMENTS_PROPS, GenerateWordField } from '../constants';
import { FormRow } from 'ui/Form/Form.styled';
import { SelectField } from 'ui/Select';
import { getProfessionsTitleOptions } from 'api/professions/professions';

type Data = TicketBModel;

const Field = FormField<Data>;
const Select = SelectField<Data>;

const TicketBForm = () => {
    const getter = useWithNumberId(getTicketBInfo);

    return (
        <EditForm
            getter={getter} // Получаем данные организации
            saver={generateTicketB} // Сохраняем изменения
            successMessage="Удостоверение сформировано"
        >
            {() => (
                <>
                    <Headline>Удостоверение Б</Headline>
                    <FormRow>
                        <Field name="surname" label="Фамилия" />
                        <Field name="name" label="Имя" />
                        <Field name="lastname" label="Отчество" />
                    </FormRow>
                    <Select
                        name="profession"
                        label="Название профессии (из справочника)"
                        placeholder="Выберите профессию"
                        onSearch={getProfessionsTitleOptions}
                        required
                    />
                    <FormRow>
                        <Field
                            name="dateStart"
                            label="Дата начала прохождения курса"
                            type="date"
                        />
                        <Field
                            name="dateEnd"
                            label="Дата окончания прохождения курса"
                            type="date"
                        />
                    </FormRow>
                    <FormRow>
                        <Field name="hoursCount" label="Объём часов" />
                        <Field name="number" label="Номер удостоверения" />
                    </FormRow>

                    <GenerateWordField />
                </>
            )}
        </EditForm>
    );
};

export default withAuth(withContainer(TicketBForm, DOCUMENTS_PROPS));
