import { Field } from 'react-final-form';
import { RadioGroup } from './RadioGroup';
import { RadioGroupFormFieldProps } from './RadioGroup.types';
import { useValidators } from 'hooks/useValidators';
import { SubmissionError } from 'typings/fetch';
import * as S from 'ui/Input/Input.styled';

export function RadioGroupField<FormData, OptionValue = unknown>({
    name,
    validators: rawValidators,
    ...radioGroupProps
}: RadioGroupFormFieldProps<FormData, OptionValue>) {
    const validators = useValidators(rawValidators, { ...radioGroupProps });

    return (
        <Field<OptionValue>
            validate={validators}
            name={name}
            render={({ input, meta }) => (
                <div>
                    <RadioGroup<OptionValue> {...input} {...radioGroupProps} />

                    <S.MetaContainer>
                        {meta.touched && !meta.dirtySinceLastSubmit && (
                            <>
                                {meta.error && <S.Meta>{meta.error}</S.Meta>}
                                {meta.submitError?.map(
                                    ({ code, message }: SubmissionError) => (
                                        <S.Meta key={code}>{message}</S.Meta>
                                    )
                                )}
                            </>
                        )}
                    </S.MetaContainer>
                </div>
            )}
        />
    );
}
