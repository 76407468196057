import { withAuth } from '../../context/Auth';
import * as S from './Profile.styled';
import Button from 'ui/Button/Button';
import * as GlobalStyle from 'ui/Styled/Styled';

import { useCallback, useEffect, useState } from 'react';
import withContainer from 'components/Container/withContainer';
import { getOrganizationById } from 'api/organizations/organizations';
import { OrganizationFullInfo } from 'api/organizations/organizations.types';
import { PowerTable } from 'ui/PowerTable';

import { ColumnProps, ParseTableColumns } from 'ui/Table';
import { getStudents } from 'api/students/students';
import { StudentInfo } from 'api/students/students.types';
import { RouterPaths } from 'helpers/router-paths';
import { formatDate } from 'utils/date';
import { useNumberId } from 'hooks/useId';
import { ORGANIZATION_EDIT_PROPS } from './constants';
import { HeaderDataRow } from './DataRow';
import Skeleton from 'components/Skeleton/Skeleton';

const STUDENTS_COLUMNS: ColumnProps<StudentInfo> = {
    fullName: 'ФИО',
    phone: 'Номер телефона',
    birthday: 'Дата рождения',
    email: 'Почта'
};

const parseColumns: ParseTableColumns<StudentInfo> = {
    birthday: formatDate
};

const ProfileOrganization = () => {
    const id = useNumberId();

    const [organization, setOrganization] =
        useState<OrganizationFullInfo | null>(null); // Типизируем состояние

    useEffect(() => {
        getOrganizationById(id).then(setOrganization);
    }, [id]);

    const getOrgStudents = useCallback(
        (search?: string) => {
            if (!Number(id)) {
                throw new Error("No id value or it's not number");
            }

            return getStudents(search, Number(id));
        },
        [id]
    );

    if (!organization) {
        return <Skeleton />;
    }

    return (
        <>
            <S.ContainerTopContent>
                <S.ContainerLeftContent>
                    <S.ContainerMainInformation>
                        <GlobalStyle.TextL>
                            {organization?.title}
                        </GlobalStyle.TextL>
                        <HeaderDataRow
                            label="Телефон"
                            value={organization.phone}
                        />

                        <HeaderDataRow
                            label="Банковский счет"
                            value={organization.bankAccountNumber}
                        />

                        {organization.address && (
                            <HeaderDataRow
                                label="Почтовый адрес"
                                value={`${organization.address.postalCode}, ${organization.address.region || 'Не найдено'}, ${organization.address.city}, ${organization.address.street}, ${organization.address.house}`}
                            />
                        )}

                        <HeaderDataRow
                            label="Эл. почта"
                            value={organization.email}
                        />
                    </S.ContainerMainInformation>
                </S.ContainerLeftContent>
                <S.ContainerRightContent>
                    <Button href={RouterPaths.OrganizationEdit(id)} size={'s'}>
                        Редактировать
                    </Button>
                </S.ContainerRightContent>
            </S.ContainerTopContent>
            <S.ContainerField>
                <PowerTable
                    columns={STUDENTS_COLUMNS}
                    getter={getOrgStudents}
                    noRowsMsg="Студенты не найдены"
                    search={true}
                    linkGetter={RouterPaths.StudentProfile}
                    parseColumns={parseColumns}
                />
            </S.ContainerField>
        </>
    );
};

export default withAuth(
    withContainer(ProfileOrganization, ORGANIZATION_EDIT_PROPS)
);
