import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { getCourses } from '../../api/courses/courses';
import { Headline } from '../../ui/Styled/Styled';
import { CourseInfo } from '../../api/courses/courses.types';
import { ColumnProps } from '../../ui/Table';
import { PowerTable } from 'ui/PowerTable';
import { RouterPaths } from 'helpers/router-paths';
import { OrganizationType } from 'api/api';

const COURSES_COLUMNS: ColumnProps<CourseInfo> = {
    title: 'Название курса',
    trainingType: 'Вид обучения',
    assignedQualification: 'Присваиваемая квалификация',
    hoursCount: 'Объём часов',
    issuedDocuments: 'Выдаваемый документ',
    price: 'Стоимость'
};

const CoursesList = () => {
    return (
        <>
            <Headline $disableMargin>Список курсов</Headline>

            <PowerTable
                columns={COURSES_COLUMNS}
                translateColumns={{
                    assignedQualification: 'qualificationTypes',
                    issuedDocuments: 'issuedDocuments',
                    trainingType: 'trainingTypes'
                }}
                getter={getCourses}
                linkGetter={RouterPaths.CourseEdit}
                noRowsMsg="Курсы не найдены"
            />
        </>
    );
};

export default withAuth(
    withContainer(CoursesList),
    undefined,
    undefined,
    OrganizationType.EducationCenter
);
