import styled, { css } from 'styled-components';
import { Input } from '../Input/Input.styled';

import deleteIcon from 'assets/trash.png';
import { TextL } from 'ui/Styled/Styled';
import Button from 'ui/Button/Button';

export const SearchField = styled(Input)`
    width: 100%;
    z-index: 10;
    margin-top: 24px;
    position: sticky;
    top: 0;
    left: 0;
`;

export const Container = styled.div`
    flex: 1;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-flow: column;

    margin: 0 -24px;
    padding: 0 24px;
`;

export const TableHeader = styled.header<{ $hasMargin?: boolean }>`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 10px;

    ${({ $hasMargin }) =>
        $hasMargin &&
        css`
            margin-bottom: 24px;
        `}
`;

export const TableBefore = styled.div`
    flex: 1;
`;

export const TableButtons = styled.div`
    flex: 1;

    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    justify-content: right;
`;

export const DeleteActionButton = styled(Button)`
    width: max-content;

    &::before {
        content: ' ';
        background: url(${deleteIcon}) no-repeat;
        background-size: contain;
        aspect-ratio: 1;
        height: 100%;
        background-size: 32px;
        background-position: center;
    }
`;

export const DeleteButton = styled.img`
    width: 100%;
    height: auto;
    margin: -2px;
    cursor: pointer;
    content: url(${deleteIcon});

    transform: translateX(150%);
    transition: transform 100ms;
    position: relative;
`;

export const DeleteHoverArea = styled.div`
    position: absolute;
    left: -30px;
    right: -20px;
    top: 0;
    bottom: 0;
`;

export const DeleteButtonContainer = styled.div`
    margin-right: -38px;
    transition: margin 100ms;

    width: 24px;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;

    &:has(${DeleteHoverArea}:hover),
    &:has(${DeleteButton}:hover) {
        margin-right: -10px;
    }

    &:hover
        ${DeleteButton},
        &
        ${DeleteHoverArea}:hover+${DeleteButton},
        &
        ${DeleteButton}:hover {
        transform: none;
    }
`;

export const DeletePopupTitle = styled(TextL)`
    margin-bottom: 24px;
    text-align: center;
`;
