import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import { Link } from 'react-router-dom';
import * as S from './Documents.styled';
import { S as TableS } from 'ui/Table';
import Button from 'ui/Button/Button';
import { RouterPaths } from 'helpers/router-paths';
import { getTranslateGroup } from 'messages';
import { StaticDocument } from 'api/documents/documents.types';

const translateGroup = getTranslateGroup('staticDocuments');

const DocumentsList = () => {
    return (
        <>
            <Headline>Генератор документов</Headline>
            <TableS.Contaner>
                <TableS.Body>
                    {Object.entries(translateGroup).map(([key, value]) => (
                        <TableS.RowContainer key={key}>
                            <Link
                                to={RouterPaths.StaticDocument(
                                    key as StaticDocument
                                )}
                                key={value}
                            >
                                <TableS.Row>
                                    <S.DocumentRowLabel>
                                        {value}
                                    </S.DocumentRowLabel>
                                    <S.DocumentRowButton>
                                        <Button>Подробнее</Button>
                                    </S.DocumentRowButton>
                                </TableS.Row>
                            </Link>
                        </TableS.RowContainer>
                    ))}
                </TableS.Body>
            </TableS.Contaner>
        </>
    );
};

export default withAuth(withContainer(DocumentsList));
