import { FC } from 'react';
import styled from 'styled-components';
import { ButtonProps } from './Button.types';
import { buttonBackground } from './constants';

const size = {
    s: {
        padding: '8px 12px',
        width: 'initial',
        height: 'initial',
        fontSize: '12px'
    },
    m: {
        width: 'initial',
        height: 'initial',
        padding: 'initial',
        fontSize: 'initial'
    },
    base: {
        width: '190px',
        height: '50px',
        padding: 'initial',
        fontSize: '18px'
    }
};

export const StyledButton = styled.button<ButtonProps>`
    // Изменяем с <button> на <a>
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    width: ${(props) => size[props.size || 'base'].width};
    height: ${(props) => size[props.size || 'base'].height};
    padding: ${(props) => size[props.size || 'base'].padding};
    border-radius: 8px;
    background-color: ${({ view: theme }) => buttonBackground(theme)};
    color: #fff;
    font-size: ${(props) => size[props.size || 'base'].fontSize};
    font-weight: 500;
    border-width: 0;
    cursor: pointer;

    &:disabled {
        background: gray;
        cursor: default;
    }

    &:not(:disabled):hover {
        background-color: #60779e;
    }

    &:not(:disabled):active {
        background-color: #495b79;
    }
`;

const Button: FC<ButtonProps> = ({
    disabled,
    children,
    href,
    type,
    ...buttonProps
}) => {
    return (
        <StyledButton
            as={href ? 'a' : undefined}
            disabled={disabled}
            href={disabled ? undefined : href}
            type={type || 'button'}
            {...buttonProps}
        >
            {children}
        </StyledButton>
    );
};

export default Button;
